import { useState, useRef } from "react";

import { Col, Layout, Row, Typography, FloatButton, Tooltip } from "antd";
import { UserOutlined } from "@ant-design/icons";

import { FaPlus } from "react-icons/fa";

import _service from "@netuno/service-client";

import RepresentativeFilter from "../../components/Representative/Filter";
import RepresentativeList from "../../components/Representative/List";
import RepresentativeModalWithForm from "../../components/Representative/Modal/WithForm";

function Representatives() {
    const [openRepresentativeModal, setOpenRepresentativeModal] = useState(false);
    const [filters, setFilters] = useState({});
    const representativeListRef = useRef(null);
    const representativeFilterRef = useRef(null);

    const onFilter = (values) => {
        setFilters((prev) => ({
            ...prev,
            ...values,
            active: values.filter_active ? values.active : undefined
        }));
    };

    return (
        <Layout.Content className="page-content">
            <Row justify="space-between">
                <Col style={{ marginRight: "20px" }} >
                    <Typography.Title level={2}>
                        <UserOutlined /> Representantes
                    </Typography.Title>
                </Col>
                <Col style={{ width: "500px" }} >
                    <RepresentativeFilter
                        ref={representativeFilterRef}
                        onFilter={onFilter}
                    />
                </Col>
            </Row>
            <RepresentativeList
                ref={representativeListRef}
                filters={filters}
                columnConfig={{
                    xs: {
                        span: 24
                    },
                    sm: {
                        span: 24
                    },
                    md: {
                        span: 12
                    },
                    lg: {
                        span: 8
                    }
                }}
            />
            <Tooltip
                title="Adicionar"
            >
                <FloatButton
                    style={{ border: '2px solid #00B298' }}
                    onClick={() => {
                        setOpenRepresentativeModal(true);
                    }}
                    icon={<FaPlus color="#00B298" />}
                />
            </Tooltip>
            <RepresentativeModalWithForm
                open={openRepresentativeModal}
                onCancel={() => {
                    setOpenRepresentativeModal(false);
                }}
                onSubmitFinishSuccess={() => {
                    setOpenRepresentativeModal(false);

                    representativeListRef.current && representativeListRef.current.loadRepresentatives();
                    representativeFilterRef.current && representativeFilterRef.current.loadRepresentatives();
                }}
                formConfig={{ valuesDefault: {} }}
            />
        </Layout.Content>
    );
}

export default Representatives;
