import {
    useState
} from "react";

import {
    Button,
    Col,
    Form,
    Row
} from "antd";

import SectorModal from "..";
import SectorForm from "../../Form";

function SectorModalWithForm({
    open,
    sectorUID,
    formConfig,
    onCancel,
    onSubmitFinishSuccess
}) {
    const [sector, setSector] = useState(null);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const title = sectorUID ?
        "Edição de Setor" :
        "Cadastro de Setor";

    const onClickSave = async () => {
        try {
            await form.validateFields();
            form.submit();
            setLoading(true);
        } catch (_err) {
            setLoading(false);
        }
    };

    const onLoadSector = (sectorData) => {
        setSector(sectorData);
    };

    return (
        <SectorModal
            open={open}
            sectorUID={sectorUID}
            onLoadSector={onLoadSector}
            onCancel={() => {
                onCancel();
                form.resetFields();
            }}
            title={title}
            footer={(
                <Row
                    align="middle"
                    justify="end"
                >
                    <Col>
                        <Button
                            type="link"
                            onClick={() => {
                                onCancel();
                                form.resetFields();
                            }}
                        >
                            Cancelar
                        </Button>
                    </Col>

                    <Col>
                        <Button
                            type="primary"
                            loading={loading}
                            onClick={onClickSave}
                        >
                            Salvar
                        </Button>
                    </Col>
                </Row>
            )}
        >
            <SectorForm
                form={form}
                sectorUID={sectorUID}
                onSubmitFinishSuccess={() => {
                    setLoading(false);
                    onSubmitFinishSuccess()
                }}
                onSubmitFinishFail={() => {
                    setLoading(false);
                }}
                {...({
                    ...formConfig,
                    valuesDefault: {
                        ...formConfig?.valuesDefault,
                        ...sector
                    }
                })}
            />
        </SectorModal>
    );
}

export default SectorModalWithForm;