import CandidateModal from "..";
import { useState } from "react";
import { Row, Col, Button, Form } from "antd";

import CandidateForm from "../../Form";

function CandidateModalWithForm({
    open,
    onCancel,
    onSubmitFormSuccess,
    candidateUUID
}) {

    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [candidate, setCandidate] = useState(null);

    const onSubmit = async () => {
        try {
            await form.validateFields();
            form.submit();
            setLoading(true);
        } catch (_err) {
            setLoading(false);
        }
    };
    
    return (
        <CandidateModal
            open={open}
            candidateUID={candidateUUID}
            onLoadCandidate={(candidate)=>{setCandidate(candidate)}}
            title={candidate ? "Edição de candidato": "Cadastro de candidato"}
            style={{
                maxWidth: "600px"
            }}
            onCancel={() => {
                onCancel();
                form.resetFields();
            }}
            width="90%"
            footer={
                <Row justify="end">
                <Col>
                    <Button
                        type="link"
                        onClick={() => {
                            onCancel();
                            form.resetFields()
                        }}
                    >
                        Cancelar
                    </Button>
                </Col>
                <Col>
                    <Button
                        type="primary"
                        loading={loading}
                        onClick={onSubmit}
                    >
                        Salvar
                    </Button>
                </Col>
            </Row>
            }
        >
            <CandidateForm
                form={form}
                onSubmitFormSuccess={() => {
                    setLoading(false);
                    onSubmitFormSuccess();
                }}
                onSubmitFormFail={()=> {
                    setLoading(false);
                }}
                candidate={candidate}
            />
        </CandidateModal>
    )
}

export default CandidateModalWithForm;