import { Form, Input } from "antd";

import _service from "@netuno/service-client";

import { INSERT_NAME } from "../../../consts";
import { failToRegister } from "../../../utils";

function StatusForm({
    form,
    statusUID,
    onSubmitFinishSuccess,
    onSubmitFinishFail,
    ...formConfig
}) {
    console.log(formConfig)
    const onFinish = (values) => {
        const data = {
            ...values
        };

        if (!statusUID) {
            _service({
                url: "status",
                method: "POST",
                data,
                success: () => {
                    onSubmitFinishSuccess();
                },
                fail: () => {
                    failToRegister("Status");
                    onSubmitFinishFail();
                }
            });
        }

        if (statusUID) {
            data.uid = statusUID;

            _service({
                url: "status",
                method: "PUT",
                data,
                success: () => {
                    onSubmitFinishSuccess();
                },
                fail: () => {
                    failToRegister("Status");
                    onSubmitFinishFail();
                }
            });
        }
    };

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={formConfig?.valuesDefault}
        >
            <Form.Item
                name="name"
                label="Nome"
                rules={[
                    {
                        required: true,
                        message: INSERT_NAME
                    },
                ]}
            >
                <Input type="text" />
            </Form.Item>
        </Form>
    );
}

export default StatusForm;