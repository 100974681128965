import {
    Row,
    Col,
    Typography
} from "antd";
import {
    UserOutlined
} from "@ant-design/icons";
import {
    FaRegBuilding,
    FaRegIdBadge
} from "react-icons/fa";
import {
    AiOutlineMail,
    AiOutlineLinkedin,
    AiOutlinePhone
} from "react-icons/ai"
import { MdNotes } from "react-icons/md";
import { NOT_REGISTERED } from "../../../../../../consts";
import ParagraphyWithLabel from "../../../../../Paragraph/WithLabel"

function RepresentativeModalInfoTabGeneral({
    representative
}) {

    return (
        <Row 
            style={{ width: "100%" }} 
            wrap={true} 
            className="info_tab_general" 
            gutter={[24, 8]}
        >

            <Col span="12">
                <Row>
                    <Col span="24">
                        <ParagraphyWithLabel
                            label="Nome: "
                            labelIcon={<UserOutlined size={20} />}
                            text={representative.name}
                        />
                    </Col>
                    <Col span="24">
                        <ParagraphyWithLabel
                            label="Empresa: "
                            labelIcon={<FaRegBuilding size={20} />}
                            text={representative.company.name}
                        />
                    </Col>
                    <Col span="24">
                        <Typography.Paragraph type="secondary" style={{ marginBottom: "20px" }}>
                            <span><AiOutlineLinkedin size={20} /> Linkedin :</span> {
                                <span>
                                    {
                                        representative.linkedin ? (
                                            <Typography.Link target="_blank" href={representative.linkedin}>
                                                {representative.linkedin}
                                            </Typography.Link>
                                        ) : (
                                            <Typography.Paragraph>
                                                {NOT_REGISTERED}
                                            </Typography.Paragraph>
                                        )
                                    }
                                </span>
                            }
                        </Typography.Paragraph>
                    </Col>
                </Row>
            </Col>
            <Col span="12">
                <Row>
                    <Col span="24">
                        <ParagraphyWithLabel
                            label="Cargo"
                            labelIcon={<FaRegIdBadge size={20} />}
                            text={representative.position.name}
                        />
                    </Col>
                    <Col span="24">
                        <Typography.Paragraph type="secondary" style={{ marginBottom: "20px" }}>
                            <span><AiOutlinePhone size={20} /> Telefone :</span> {
                                <span>
                                    {
                                        representative.phone ? (
                                            <Typography.Link href={`tel://${representative.phone}`}>
                                                {representative.phone}
                                            </Typography.Link>
                                        ) : (
                                            <Typography.Paragraph>
                                                {NOT_REGISTERED}
                                            </Typography.Paragraph>
                                        )
                                    }
                                </span>
                            }
                        </Typography.Paragraph>
                    </Col>
                    <Col span="24">
                        <Typography.Paragraph type="secondary" style={{ marginBottom: "20px" }}>
                            <span><AiOutlineMail size={20} /> E-mail :</span> {
                                <span>
                                    {
                                        representative.email ? (
                                            <Typography.Link target="_blank"
                                                href={`mailto:${representative.email}`}>
                                                {representative.email}
                                            </Typography.Link>
                                        ) : (
                                            <Typography.Paragraph>
                                                {NOT_REGISTERED}
                                            </Typography.Paragraph>
                                        )
                                    }
                                </span>
                            }
                        </Typography.Paragraph>
                    </Col>
                  
                </Row>
            </Col>
                            
              <Col span="24">
                <Typography.Paragraph type="secondary">
                    <MdNotes size={20} /> Anotações:
                </Typography.Paragraph>

                <Typography.Paragraph>
                    <div dangerouslySetInnerHTML={{ __html: representative.notes }} />
                </Typography.Paragraph>
            </Col>
        </Row>
    )
}

export default RepresentativeModalInfoTabGeneral;