import _service from "@netuno/service-client";
import { 
    Form, 
    Input, 
    notification} from "antd";
import { useEffect } from "react";

function ExperienceTimeForm ({
    experienceTime,
    form,
    onSubmitSuccess,
    onSubmitFail
}) {

    const onFinish = (values) => {

        const data = {
            ...values
        }

        if (!experienceTime) {
            _service({
                url:"candidate/experience_time",
                method:"POST",
                data:data,
                success: (res) => {
                    notification.success({
                        message:"Cadastrar experiência",
                        description:"Experiência cadastrada com sucesso"
                    })
                    onSubmitSuccess();
                },
                fail: (err) => {
                    onSubmitFail();
                    if (err.json.error == "experience already exists") {
                        notification.error({
                            message:"Cadastrar experiência",
                            description:"Experiência já existente"
                        })
                    } else {
                        notification.error({
                            message:"Cadastrar experiência",
                            description:"Falha ao cadastrar experiência"
                        })
                    }
                }
            })
        }

        if (experienceTime) {
            data.uid = experienceTime.uid;
            _service({
                url:"candidate/experience_time",
                method:"PUT",
                data:data,
                success: (res) => {
                    notification.success({
                        message:"Edição de experiência",
                        description:"Experiência editada com sucesso"
                    })
                    onSubmitSuccess();
                },
                fail: (err) => {
                    onSubmitFail();
                    if (err.json.error == "experience already exists") {
                        notification.error({
                            message:"Edição de experiência",
                            description:"Experiência já existente"
                        })
                    } else {
                        notification.error({
                            message:"Edição de experiência",
                            description:"Falha ao editar experiência"
                        })
                    }
                }
            })
        }
    }

    useEffect(()=>{
        if (experienceTime) {
            form.setFieldsValue({
                name:experienceTime.name
            })
        }
    },[experienceTime])

    return(
        <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
        >
            <Form.Item
                label="Nome"
                name="name"
                rules={[{required:true, message:"Insira o nome"}]}
            >
                <Input/>
            </Form.Item>
        </Form>
    )
}

export default ExperienceTimeForm;