import { useState } from "react";

import {connect} from "react-redux";

import { Button, notification, Col, Row, Tabs, Switch, Tooltip } from "antd";
import { EditOutlined, CommentOutlined } from "@ant-design/icons";

import _service from "@netuno/service-client";

import InteractionModal from "..";
import InteractionModalInfoTabInfo from "./TabInfo";
import InteractionModalInfoTabComments from "./TabComments";
import InteractionModalWithFrom from "../WithForm";

function InteractionModalInfo({
  open,
  interactionUID,
  loggedUserInfo,
  onToggleActive,
  onEditInteraction,
  onCancel,
  ...modalProps
}) {
  const [interaction, setInteraction] = useState(null);
  const [loadingToggleActive, setLoadingToggleActive] = useState(false);
  const [openInteractionModalWithForm, setOpenInteractionModalWithForm] = useState(false);

  const onLoadInteraction = (interactionData) => {
    setInteraction(interactionData);
  };

  const toggleActive = () => {
    setLoadingToggleActive(true);

    _service({
      url: "interaction",
      method: "PUT",
      data: {
        ...interaction,
        representative_uid: interaction.representative.uid,
        means_of_contact_uid: interaction.contact_mean.uid,
        type_of_contact_uid: interaction.contact_type.uid,
        company_uid: interaction.company.uid,
        active: !interaction.active
      },
      success: () => {
        setLoadingToggleActive(false);
        setInteraction((prev) => ({
          ...prev,
          active: !prev.active
        }));
        onToggleActive(!interaction.active, interaction.uid);
        notification.success({
          message: `${interaction.active ? "Desativar" : "Ativar"} Interação`,
          description: `Interação ${interaction.active ? "desativada" : "ativada"} com sucesso.`
        });
      },
      fail: (err) => {
        setLoadingToggleActive(false);
        notification.error({
          message: `${interaction.active ? "Desativar" : "Ativar"} Interação`,
          description: `Falha ao tentar ${interaction.active ? "desativar" : "ativar"} a interação.`
        });
      }
    })
  };

  return (
    <InteractionModal
      open={open}
      interactionUID={interactionUID}
      onLoadInteraction={onLoadInteraction}
      footer={false}
      width="90%"
      onCancel={() => {
        setInteraction(null);

        onCancel();
      }}
      title={interaction && (
        <Row justify="space-between" align="middle">
          <Col span={17}>
            {interaction.title}
          </Col>

          <Col span={6} className="btns_modal">
            <Row align="middle" gutter={[12, 0]}>
              {
                loggedUserInfo?.admin && (
                  <Col style={{marginBottom:"2px"}}>
                    <Tooltip
                      title={`${interaction.active ? "Desativar" : "Ativar"} interação`}
                    >
                      <Switch
                        size="small"
                        loading={loadingToggleActive}
                        checked={interaction.active}
                        onClick={toggleActive}
                      />
                    </Tooltip>
                  </Col>
                )
              }

              <Col>
                <Button
                  type="link"
                  disabled={!interaction.active}
                  icon={<EditOutlined />}
                  title="Editar"
                  onClick={() => {
                    setOpenInteractionModalWithForm(true);
                  }}
                />
              </Col>
            </Row>

            <InteractionModalWithFrom
              open={openInteractionModalWithForm}
              formConfig={{
                readOnly: {
                  company_uid: true,
                  representative_uid: true
                },
                valuesDefault: {
                  ...interaction,
                  company_uid: {
                    label: interaction.company.name,
                    value: interaction.company.uid
                  },
                  representative_uid: {
                    label: interaction.representative.name,
                    value: interaction.representative.uid
                  },
                  means_of_contact: interaction.contact_mean,
                  type_of_contact: interaction.contact_type
                }
              }}
              onCancel={() => {
                setOpenInteractionModalWithForm(false);
              }}
              onSubmitFinishSuccess={() => {
                onEditInteraction();
              }}
            />
          </Col>
        </Row>
      )}
      style={{
        maxWidth: "900px",
      }}
      bodyStyle={{
        height: "72vh"
      }}
      {...modalProps}
    >
      <Tabs
        items={[
          {
            key: "general",
            label: "Informações Gerais",
            children: <InteractionModalInfoTabInfo interaction={interaction} />
          },
          {
            key: "comments",
            label: (
              <span>
                <CommentOutlined /> Comentários
              </span>
            ),
            children: <InteractionModalInfoTabComments interaction={interaction} />,
          }
        ]}
      />
    </InteractionModal>
  );
}

const mapStateToProps = (store) => {
  const { loggedUserInfo } = store.loggedUserInfoState;

  return {
    loggedUserInfo
  };
};

export default connect(mapStateToProps, {})(InteractionModalInfo);
