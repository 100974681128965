import { useState } from "react";
import { Col, Form, Layout, Row, Typography, FloatButton, Tooltip, Button } from "antd";
import { FaRegBuilding, FaPlus } from "react-icons/fa";
import { PlusOutlined } from "@ant-design/icons";
import StatusCardList from "../../../components/StatusCardList";
import StatusModalWithForm from "../../../components/Status/Modal/WithForm";
import StatusSelectMultiple from "../../../components/Status/Select/Multiple";
import _service from "@netuno/service-client";
import "./styles.less";

function Status() {
    const [openStatusModalWithForm, setOpenStatusModalWithForm] = useState(false);
    const [filters, setFilters] = useState({});
    const [formSearch] = Form.useForm();
    const [formFilters] = Form.useForm();

    const onFiltersValuesChange = (values) => {
        formSearch.setFieldsValue(values);
        formFilters.setFieldsValue(values);
    };

    const onSubmitFilters = (values) => {
        setFilters({
            ...filters,
            ...values
        });
    };

    return (
        <Layout.Content className="page-content sec-management-responsive">
            <Row justify="space-between">
                <Col span="12">
                    <Typography.Title level={2}>
                        <FaRegBuilding /> Status
                    </Typography.Title>
                </Col>
                <Col span="12" className="sec-management-responsive__container">
                    <Row justify="end">
                        <Col className="sec-management-responsive__btn-new">
                            <Button
                                type="primary"
                                onClick={() => {
                                    setOpenStatusModalWithForm(true);
                                }}
                                icon={<PlusOutlined />}
                            >
                                Novo Status
                            </Button>
                        </Col>
                        <Col className="sec-management-responsive__filter">
                            <Form
                                form={formSearch}
                                onValuesChange={onFiltersValuesChange}
                                onFinish={onSubmitFilters}
                            >
                                <Form.Item name="status_uids">
                                    <StatusSelectMultiple
                                        placeholder="Pesquisar por status"
                                        onChange={() => {
                                            formSearch.submit()
                                        }}
                                    />
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <StatusCardList
                filters={filters}
                pageSize={21}
                maxColumnNumber={3}
                formSearch={formSearch}
            />
            <Tooltip
                title="Adicionar"
            >
                <FloatButton
                    style={{ border: '2px solid #00B298' }}
                    onClick={() => {
                        setOpenStatusModalWithForm(true);
                    }}
                    icon={<FaPlus color="#00B298" />}
                />
            </Tooltip>
            <StatusModalWithForm
                open={openStatusModalWithForm}
                onCancel={() => {
                    setOpenStatusModalWithForm(false);
                }}
                onSubmitFinishSuccess={() => {
                    setOpenStatusModalWithForm(false);
                    formSearch.submit();
                }}
            />
        </Layout.Content>
    );
}

export default Status;
