import {
    Col,
    Row,
    Layout,
    Typography,
    FloatButton,
    Button
} from "antd";
import { FaPlus, FaRegBuilding } from "react-icons/fa";
import { useRef, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";

import LanguageModalWithForm from "../../../components/Language/Modal/WithForm";
import LanguageList from "../../../components/Language/List";
import LanguageSelectMult from "../../../components/Language/Select/Mult";

function Languages ({

}) {

    const [openLanguageModalWithForm, setOpenLanguageModalWithForm] = useState(false);
    const languageListRef = useRef(null);
    const [filters, setFilters] = useState({});
    
    return (
        <Layout.Content className="page-content sec-management-responsive">
            <Row justify={"space-between"} align={"middle"}>
                <Col>
                    <Typography.Title
                        level={2}
                    >
                        <FaRegBuilding /> Idiomas
                    </Typography.Title>
                </Col>
                <Col span="12" className="sec-management-responsive__container">
                    <Row justify="end">
                        <Col className="sec-management-responsive__btn-new">
                            <Button
                                type="primary"
                                onClick={() => {
                                    setOpenLanguageModalWithForm(true);
                                }}
                                icon={<PlusOutlined />}
                            >
                                Novo Idioma
                            </Button>
                        </Col>
                        <Col style={{ width: "500px" }} className="sec-management-responsive__filter">
                            <LanguageSelectMult
                                placement={"bottomRight"}
                                placeholder={"Buscar por idioma"}
                                onChange={(values)=>{
                                    setFilters((prev)=>({
                                        ...prev,
                                        uids:values
                                    }))
                                }}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>

            <LanguageList
                maxColumnNumber={3}
                ref={languageListRef}
                filters={filters}
            />

            <FloatButton
                style={{ border: '2px solid #00B298' }}
                onClick={() => {
                    setOpenLanguageModalWithForm(true);
                }}
                icon={<FaPlus color="#00B298" />}
            />

           <LanguageModalWithForm
                open={openLanguageModalWithForm}
                onCancel={()=>{
                    setOpenLanguageModalWithForm(false);
                }}
                onSubmitFormSuccess={()=> {
                    setOpenLanguageModalWithForm(false);
                    languageListRef.current && languageListRef.current.onLoadLanguages();
                }}
           />

        </Layout.Content>
    )
}

export default Languages;