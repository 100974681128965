import { useRef, useState } from "react";

import { Col, Layout, Row, Typography, FloatButton, Tooltip } from "antd";

import { FaRegBuilding, FaPlus } from "react-icons/fa";

import _service from "@netuno/service-client";

import CompanyList from "../../components/Company/List";
import CompanyModalWithForm from "../../components/Company/Modal/WithForm";
import CompanyFilter from "../../components/Company/Filter";

import "./styles.less";

function Companies() {
    const [modalFormCompanyIsOpen, setModalFormCompanyIsOpen] = useState(false);
    const [filters, setFilters] = useState({});
    const companiesListRef = useRef(null);
    const companyFilterRef = useRef(null);

    const onFilter = (values) => {
        setFilters((prev) => ({
            ...prev,
            ...values,
            active: values.filter_active ? values.active : undefined
        }));
    };

    return (
        <Layout.Content className="page-content">
            <Row justify="space-between">
                <Col style={{ marginRight: "20px" }} >
                    <Typography.Title level={2}>
                        <FaRegBuilding /> Empresas
                    </Typography.Title>
                </Col>
                <Col style={{ width: "500px" }}>
                    <CompanyFilter
                        ref={companyFilterRef}
                        onFilter={onFilter}
                    />
                </Col>
            </Row>
            <CompanyList
                ref={companiesListRef}
                filters={filters}
                columnConfig={{
                    sm: { span: 24 },
                    md: { span: 12 },
                    lg: { span: 8 },
                }}
            />
            <Tooltip
                title="Adicionar"
            >
                <FloatButton
                    style={{ border: '2px solid #00B298' }}
                    onClick={() => {
                        setModalFormCompanyIsOpen(true);
                    }}
                    icon={<FaPlus color="#00B298" />}
                />
            </Tooltip>
            <CompanyModalWithForm
                open={modalFormCompanyIsOpen}
                onCancel={() => {
                    setModalFormCompanyIsOpen(false);
                }}
                onSubmitFinishSuccess={() => {
                    setModalFormCompanyIsOpen(false);

                    companiesListRef.current && companiesListRef.current.loadCompanies();
                    companyFilterRef.current && companyFilterRef.current.loadCompanies();
                }}
            />
        </Layout.Content>
    );
}

export default Companies;
