import { useEffect, useState } from "react";
import { Col, Pagination, Row, Spin, Empty, notification } from "antd";
import ServiceCard from "./ServiceCard";
import _service from "@netuno/service-client";
import "./styles.less";

function ServiceCardList({
    filters,
    maxColumnNumber,
    formSearch
}) {
    const [services, setServices] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [servicesTotal, setServicesTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [emptyImage, setEmptyImage] = useState(false);
    const pageSize = 10;
    const columnOptions = [
        {
            span: 24
        },
        {
            sm: { span: 24 },
            md: { span: 12 }
        },
        {
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 12 },
            lg: { span: 8 }
        },
    ];

    const onPageChange = (page) => {
        setCurrentPage(page);
    }

    const loadServices = () => {
        setLoading(true);
        _service({
            url: "service/list",
            method: "POST",
            data: {
                filters,
                pagination: {
                    page: currentPage,
                    size: pageSize
                }
            },
            success: (res) => {
                setLoading(false);
                if (res.json) {
                    setServices(res.json.services);
                    setServicesTotal(res.json.services_total);
                    setEmptyImage(res.json.services_total > 0 ? false : true);
                }
            },
            fail: (err) => {
                setLoading(false);
                setEmptyImage(true)
            }
        });

    };

    useEffect(() => {
        if (currentPage !== 1) {
            setCurrentPage(1);

            return;
        }

        loadServices();
    }, [filters]);

    useEffect(() => {
        loadServices();
    }, [currentPage]);

    if (loading) {
        return (
            <Row
                className="loading"
            >

                <Spin
                    tip="Aguarde..."
                    size="large"
                >
                    <div className="content" />
                </Spin>
            </Row>
        )
    }

    if (emptyImage) {
        return (
            <Row
                span={24}
                align="middle"
                className="div_empty_result"
            >
                <Empty
                    description={
                        <span>
                            Pesquisa não encontrada
                        </span>
                    }
                />
            </Row>
        )
    }

    return (
        <>
            {
                servicesTotal > 0 && (
                    <Row
                        align="middle"
                        justify="center"
                        className="service-card-list-pagination-top">
                        <Col>
                            <Pagination
                                total={servicesTotal}
                                onChange={onPageChange}
                                current={currentPage}
                                pageSize={pageSize}
                            />
                        </Col>
                    </Row>
                )
            }
            <Row
                className="service-card-list"
                gutter={[12, 12]}
                justify="start"
                align="top"
            >
                {services.length > 0 && services.map((service) => (
                    <Col key={service.uid} {...columnOptions[maxColumnNumber - 1]}>
                        <ServiceCard
                            service={service}
                            formSearch={formSearch}
                            onToggleActive={(active, uid) => {
                                setServices((prev) => {
                                    return prev.map((service) => {
                                        if (service.uid === uid) {
                                            return ({
                                                ...service,
                                                active
                                            });
                                        }

                                        return service;
                                    });
                                });
                            }}
                        />
                    </Col>
                ))}
            </Row>
            {
                servicesTotal > 0 && (
                    <Row
                        align="middle"
                        justify="center"
                        className="service-card-list-pagination-bottom">
                        <Col>
                            <Pagination
                                total={servicesTotal}
                                onChange={onPageChange}
                                current={currentPage}
                                pageSize={pageSize}
                            />
                        </Col>
                    </Row>
                )
            }
        </>
    );
}

export default ServiceCardList;
