import { Select } from "antd";
import { useEffect, useState } from "react";
import _service from "@netuno/service-client";

let timerSearch = null;

function TechnologySelectMult ({
    disabled,
    onChange,
    placeholder,
    placement,
    ...selectOptions
}) {
    
    const [technologies, setTechnologies] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({});

    const onLoadTechnologies = () => {
        setLoading(true);
        _service({
            url:"candidate/technology/list",
            method:"POST",
            data:{
                filters:{
                    ...filters,
                    active:true
                }
            },
            success: (res) => {
                setLoading(false);
                if (res.json) {
                    setTechnologies(res.json.technologies);
                }
            },
            fail: () => {
                setLoading(false);
            }
        })
    }

    useEffect(() => {
            onLoadTechnologies();
    },[filters]);

    return (
        <Select
            mode="multiple"
            optionFilterProp="label"
            placeholder={placeholder}
            placement={placement}
            onChange={onChange}
            loading={loading}
            allowClear={!loading}
            style={{width:"100%"}}
            disabled={disabled}
            onSearch={(value) => {
                if (timerSearch) {
                    clearTimeout(timerSearch);
                }

                timerSearch = setTimeout(() => {
                    setFilters((prev) => ({
                        ...prev,
                        name: value
                    }))
                }, 500)
            }}
            onDropdownVisibleChange={(isOpen)=>{
                if (!isOpen && filters.name) {
                    setFilters((prev) => ({
                        ...prev,
                        name: ""
                    }))
                }
                if (isOpen) {
                    onLoadTechnologies();
                } 
            }}
            options={technologies.map((technology)=>({
                label: technology.name,
                value: technology.uid
            }))}
            {...selectOptions}
        />
    )
}

export default TechnologySelectMult;