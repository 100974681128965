import Modal from "antd/es/modal/Modal";

function ModalCentered({
    children,
    bodyStyle,
    ...props
}) {
    return (
        <Modal
            bodyStyle={{
                maxHeight: "72vh",
                overflowY: "auto",
                ...bodyStyle
            }}
            className="modal-centered"
            maskClosable={false}
            centered
            destroyOnClose
            {...props}
        >
            {children}
        </Modal>
    );
}

export default ModalCentered;