import { useRef, useState } from "react";

import { Button, Row, Col } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import InteractionCommentList from "../../../Comment/List";
import InteractionCommentModalWithForm from "../../../Comment/Modal/WithForm";

function InteractionModalInfoTabComments({
  interaction
}) {
  const [openInteractionCommentModalWithForm, setOpenInteractionCommentModalWithForm] = useState(false);
  const commentListRef = useRef(null);

  return (
    <Row gutter={[0, 12]}>
      <Col span={24}>
        <Button
          type="primary"
          disabled={!interaction.active}
          icon={<PlusOutlined />}
          onClick={() => {
            setOpenInteractionCommentModalWithForm(true);
          }}
        >
          Comentar
        </Button>
        <InteractionCommentModalWithForm
          open={openInteractionCommentModalWithForm}
          formConfig={{
            valuesDefault: {
              interaction_uid: interaction.uid
            }
          }}
          onCancel={() => {
            setOpenInteractionCommentModalWithForm(false);
          }}
          onSubmitFinishSuccess={() => {
            setOpenInteractionCommentModalWithForm(false);

            commentListRef?.current?.loadComments({
              page: 1
            });
          }}
        />
      </Col>

      <Col span={24}>
        <InteractionCommentList
          ref={commentListRef}
          interaction={interaction}
        />
      </Col>
    </Row>
  );
}

export default InteractionModalInfoTabComments;