import {
    useState
} from "react";

import {
    Col,
    Form,
    Layout,
    Row,
    Typography,
    FloatButton,
    Tooltip,
    Button
} from

    "antd";
import {
    FaRegBuilding,
    FaPlus
} from "react-icons/fa";
import { PlusOutlined } from "@ant-design/icons";

import _service from "@netuno/service-client";

import MeansOfContactCardList from "../../../components/MeansOfContactCardList";
import ContactMeanModalWithForm from "../../../components/ContactMean/Modal/WithForm";
import ContactMeanSelectMultiple from "../../../components/ContactMean/Select/Multiple";

import "./styles.less";

function MeansOfContact() {
    const [openContactMeanModalWithForm, setOpenContactMeanModalWithForm] = useState(false);
    const [filters, setFilters] = useState({});
    const [formSearch] = Form.useForm();
    const [formFilters] = Form.useForm();

    const onFiltersValuesChange = (values) => {
        formSearch.setFieldsValue(values);
        formFilters.setFieldsValue(values);
    }

    const onSubmitFilters = (values) => {
        setFilters({
            ...filters,
            ...values
        });
    }

    return (
        <Layout.Content className="page-content sec-management-responsive">
            <Row justify="space-between">
                <Col
                    span="12"
                >
                    <Typography.Title level={2}>
                        <FaRegBuilding /> Meio de Contato
                    </Typography.Title>
                </Col>
                <Col span="12" className="sec-management-responsive__container">
                    <Row justify="end">
                        <Col className="sec-management-responsive__btn-new">
                            <Button
                                type="primary"
                                onClick={() => {
                                    setOpenContactMeanModalWithForm(true);
                                }}
                                icon={<PlusOutlined />}
                            >
                                Novo Meio de Contato
                            </Button>
                        </Col>
                        <Col className="sec-management-responsive__filter">
                            <Form
                                form={formSearch}
                                onValuesChange={onFiltersValuesChange}
                                onFinish={onSubmitFilters}
                            >
                                <Form.Item name="contact_means_uids">
                                    <ContactMeanSelectMultiple
                                        placeholder="Pesquisar por meio de contato"
                                        onChange={() => {
                                            formSearch.submit();
                                        }}
                                    />
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <MeansOfContactCardList
                filters={filters}
                pageSize={21}
                maxColumnNumber={3}
                formSearch={formSearch}
            />
            <Tooltip
                title="Adicionar"
            >
                <FloatButton
                    style={{ border: '2px solid #00B298' }}
                    onClick={() => {
                        setOpenContactMeanModalWithForm(true);
                    }}
                    icon={<FaPlus color="#00B298" />}
                />
            </Tooltip>
            <ContactMeanModalWithForm
                open={openContactMeanModalWithForm}
                onCancel={() => {
                    setOpenContactMeanModalWithForm(false);
                }}
                onSubmitFinishSuccess={() => {
                    setOpenContactMeanModalWithForm(false);

                    formSearch.submit();
                }}
            />
        </Layout.Content >
    );
}

export default MeansOfContact;