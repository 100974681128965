import {
    Card,
    Col,
    Row,
    Typography,
    Switch,
    Tooltip,
    Button,
    notification
} from "antd";

import { EditOutlined } from "@ant-design/icons";
import "./styles.less"
import { useState } from "react";
import _service from "@netuno/service-client";
import LanguageModalWithForm from "../../Modal/WithForm";

function LanguageCardList ({
    language,
    onLoadLanguages,
    onActive
}) {

    const [openLanguageModalWithForm, setOpenLanguageModalWithForm] = useState(false);
    const [loading, setLoading] = useState(false);

    const onActiveLanguage = () => {
        setLoading(true);
        _service({
            url:"candidate/language",
            method:"PUT",
            data:{
                ...language,
                active:!language.active
            },
            success: (res) => {
                setLoading(false);
                notification.success({
                    message:`${language.active ? "Desativar" : "Ativar"} idioma`,
                    description:`Idioma ${language.active ? "desativado" : "ativado"} com sucesso`
                });
                onActive(language.uid, !language.active);
            },
            fail: (err) => {
                setLoading(false);
                notification.error({
                    message:`${language.active ? "Desativar" : "Ativar"} idioma`,
                    description:`Falha ao ${language.active ? "desativar" : "ativar"} idioma`
                });
            }
        })        
    }

    return(
        <Card
        size="small"
        className="language-card"
    >
        <Row justify={"space-between"} align={"middle"}>
            <Col>
                <Typography.Title
                    level={5}
                    style={{
                        margin: 0
                    }}
                >
                    {language.name}
                </Typography.Title>
            </Col>
            <Col className="col__btns">
                <Tooltip
                    title={`${language?.active ? "Desativar" : "Ativar"} idioma`}
                >
                    <Switch
                        loading={loading}
                        defaultChecked={language.active}
                        size='small'
                        onClick={onActiveLanguage}
                    />
                </Tooltip>
                
                <Button type="link" icon={<EditOutlined />}
                    title="Editar"
                    disabled={!language.active}
                    onClick={()=>{
                        setOpenLanguageModalWithForm(true)
                    }}
                />
            </Col>
        </Row>

        <LanguageModalWithForm
            languageUID={language.uid}
            open={openLanguageModalWithForm}
            onCancel={()=>{
                setOpenLanguageModalWithForm(false);
            }}
            onSubmitFormSuccess={()=>{
                setOpenLanguageModalWithForm(false);
                onLoadLanguages();
            }}           
        />
    </Card>
    )   
}

export default LanguageCardList;