import {
    Row,
    notification,
    Empty,
    Spin,
    Pagination,
    Col
} from "antd";
import _service from "@netuno/service-client";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import CardSchedule from "./CardList";

function ScheduleList ({
    maxColumnNumber,
    filters
},ref) {

    const columnOptions = [
        {
            span: 24
        },
        {
            sm: { span: 24 },
            md: { span: 12 }
        },
        {
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 12 },
            lg: { span: 8 }
        },
    ];

    const [schedules, setSchedules] = useState([]);
    const [schedulesTotal, setSchedulesTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [emptyImage, setEmptyImage] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10;

    const onChangePagination = (page) => {
        setCurrentPage(page);
    }

    const SchedulePagination = () => {
        return (
            <Row justify="center"
            >
                <Col>
                    <Pagination
                        current={currentPage}
                        pageSize={pageSize}
                        total={schedulesTotal}
                        onChange={onChangePagination}
                        style={{
                            margin: "12px 0px"
                        }}
                    />
                </Col>
            </Row>
        )
    }

    const onLoadSchedules = () => {
        setLoading(true);
        _service({
            url: "interview/schedule/list",
            method: "POST",
            success: (res) => {
                setLoading(false);
               if (res) {
                setEmptyImage(res.json.schedule_total > 0 ? false : true);
                setSchedules(res.json.schedule);
                setSchedulesTotal(res.json.schedule_total);
            }
            },
            fail: (err) => {
                setLoading(false);
                setEmptyImage(true);
                notification.error({
                    message: "Carregar horários",
                    description: "Falha ao carregar horários"
                });
            }
        })
    }

    const onDeleteSchedule = (uid) => {
        setLoading(true);
        _service({
            url:"interview/schedule",
            method:"DELETE",
            data:{
                uid:uid
            },
            success: (res) => {
                notification.success({
                    message:"Eliminar horário",
                    description:"Horário eliminado com sucesso"
                });
                setSchedules((prev) => prev.filter((schedule) => (schedule.uid !== uid)));
                setSchedulesTotal((prev) => {
                    if (prev === 1) {
                        setEmptyImage(true);
                    }
                    return prev - 1 
                });
                setLoading(false);
            },
            fail: (err) => {
                notification.error({
                    message:"Eliminar horário",
                    description:"Falha ao eliminar horário."
                })
                setLoading(false);
            }
        })
    }

    useImperativeHandle(ref, () =>({
        onLoadSchedules
    }))

    useEffect(() => {
        onLoadSchedules();
    }, [currentPage])

    useEffect(()=>{
        if (currentPage === 1) {
            onLoadSchedules();
            return;
        }
        setCurrentPage(1);
    },[filters])


    if (loading) {
        return (
            <Row
                className="loading"
            >

                <Spin
                    tip="Aguarde..."
                    size="large"
                >
                    <div className="content" />
                </Spin>
            </Row>
        )
    }

    if (emptyImage) {
        return (
            <Row
                span={24}
                align="middle"
                className="div_empty_result"
            >
                <Empty
                    description={
                        <span>
                            Nenhum horário encontrado
                        </span>
                    }
                />
            </Row>
        )
    }


    return (
        <>
            {
                schedulesTotal > 0 && (
                    <Row justify={"center"} align={"middle"}>
                        <Col span={24}>
                            <SchedulePagination />
                        </Col>
                    </Row>
                )
            }
            <Row
                gutter={[12, 12]}
                justify="start"
                align="top"
            >
                {
                    schedules.length > 0 && schedules.map((schedule, index) => (
                        <Col {...columnOptions[maxColumnNumber - 1]} key={index}>
                            <CardSchedule
                                schedule={schedule}
                                onLoadSchedule={onLoadSchedules}
                                onClickDelete={onDeleteSchedule}
                            />
                        </Col>
                    ))
                }
            </Row>
            {
                schedulesTotal > 0 && (
                    <Row style={{ marginTop: "auto", paddingTop: "12px" }}>
                        <Col span={24}>
                            <SchedulePagination />
                        </Col>
                    </Row>
                )
            }

        </>
    )
}

export default forwardRef(ScheduleList);