import {
    Form,
    Input,
    notification
} from "antd";
import _service from "@netuno/service-client";
import { useEffect } from "react";

function CandidateStatusForm ({
    form,
    status,
    onSubmitSuccess,
    onSubmitFail
}) {

    const onFinish = (values) => {
        const data = {
            ...values
        }

        if (!status) {
            _service({
                url:"candidate/status",
                method:"POST",
                data:data,
                success: (res) => {
                    notification.success({
                        message:"Cadastrar status",
                        description:"Status cadastrado com sucesso"
                    })
                    onSubmitSuccess();
                },
                fail: (err) => {
                    onSubmitFail();
                    if (err.json.error == "status already exists") {
                        notification.error({
                            message:"Cadastrar status",
                            description:"Status já existente"
                        })
                    } else {
                        notification.error({
                            message:"Cadastrar status",
                            description:"Falha ao cadastrar status"
                        })
                    }
                }
            })
        }

        if (status) {
            data.uid = status.uid;
            _service({
                url:"candidate/status",
                method:"PUT",
                data:data,
                success: (res) => {
                    notification.success({
                        message:"Edição de status",
                        description:"Status editado com sucesso"
                    })
                    onSubmitSuccess();
                },
                fail: (err) => {
                    onSubmitFail();
                    if (err.json.error == "status already exists") {
                        notification.error({
                            message:"Edição de status",
                            description:"Status já existente"
                        })
                    } else {
                        notification.error({
                            message:"Edição de status",
                            description:"Falha ao editar status"
                        })
                    }
                }
            })
        }
    }

    useEffect(()=>{
        if (status) {
            form.setFieldsValue({
                name:status.name
            })
        }
    },[status]);
    
    return(
        <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
        >
            <Form.Item
                label="Nome"
                name="name"
                rules={[{required:true, message:"Insira o nome"}]}
            >
                <Input/>
            </Form.Item>
        </Form>
    )
}

export default CandidateStatusForm;