import { useEffect, useState } from "react";

import { Col, notification, Row, Spin } from "antd";

import _service from "@netuno/service-client";

import ModalCentered from "../../Modal/Centered";

function CompanyModal({
    open,
    title,
    footer,
    companyUID,
    children,
    onLoadCompany,
    ...modalProps
}) {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (companyUID && open) {
            setLoading(true);

            _service({
                url: "company",
                data: {
                    uid: companyUID
                },
                success: (res) => {
                    onLoadCompany(res.json.company);
                    setTimeout(() => setLoading(false), 250);
                },
                fail: () => {
                    notification.error({
                        message: "Empresa",
                        description: "Falha ao carregar dados da empresa."
                    });
                }
            })
        }
    }, [open]);

    return (
        <ModalCentered
            open={open}
            footer={!loading && footer}
            title={!loading && title}
            {...modalProps}
        >
            {
                loading ? (
                    <Row
                        align="middle"
                        justify="center"
                        className="loading"
                    >
                        <Col span="24">
                            <Spin
                                tip="Aguarde..."
                                size="large"
                            >
                                <div className="content" />
                            </Spin>
                        </Col>
                    </Row>
                ) : children
            }
        </ModalCentered>
    );
}

export default CompanyModal;