import {
    Form,
    Input,
    notification
} from "antd";
import _service from "@netuno/service-client";
import { useEffect } from "react";

function FileTypeForm ({
    form,
    fileType,
    onSubmitSuccess,
    onSubmitFail
}) {

    const onFinish = (values) => {
        const data = {
            ...values
        }

        if (!fileType) {
            _service({
                url:"candidate/file_type",
                method:"POST",
                data:data,
                success: (res) => {
                    notification.success({
                        message:"Cadastrar tipo de arquivo",
                        description:"Tipo de arquivo cadastrado com sucesso"
                    })
                    onSubmitSuccess();
                },
                fail: (err) => {
                    onSubmitFail();
                    if (err.json.error == "file_type already exists") {
                        notification.error({
                            message:"Cadastrar tipo de arquivo",
                            description:"Tipo de arquivo já existente"
                        })
                    } else {
                        notification.error({
                            message:"Cadastrar tipo de arquivo",
                            description:"Falha ao cadastrar tipo de arquivo"
                        })
                    }
                }
            })
        }

        if (fileType) {
            data.uid = fileType.uid;
            _service({
                url:"candidate/file_type",
                method:"PUT",
                data:data,
                success: (res) => {
                    notification.success({
                        message:"Edição de tipo de arquivo",
                        description:"Tipo de arquivo editado com sucesso"
                    })
                    onSubmitSuccess();
                },
                fail: (err) => {
                    onSubmitFail();
                    if (err.json.error == "file_type already exists") {
                        notification.error({
                            message:"Edição de tipo de arquivo",
                            description:"Tipo de arquivo já existente"
                        })
                    } else {
                        notification.error({
                            message:"Edição de tipo de arquivo",
                            description:"Falha ao editar tipo de arquivo"
                        })
                    }
                }
            })
        }
    }

    useEffect(()=>{
        if (fileType) {
            form.setFieldsValue({
                name:fileType.name
            })
        }
    },[fileType]);
    
    return(
        <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
        >
            <Form.Item
                label="Nome"
                name="name"
                rules={[{required:true, message:"Insira o nome"}]}
            >
                <Input/>
            </Form.Item>
        </Form>
    )
}

export default FileTypeForm;