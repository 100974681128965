import { useEffect, useState } from "react";
import _service from "@netuno/service-client";
import { 
    Row, 
    Col,
    Spin,
    notification 
} from "antd";

import ModalCentered from "../../Modal/Centered";

function RepresentativeModal ({
    open,
    title,
    footer,
    representativeUID,
    children,
    onLoadRepresentative,
    ...modalProps
}) {
    const [loading, setLoading] = useState(false);

    useEffect(()=>{
        if (representativeUID && open) {
            setLoading(true);

            _service({
                url: "representative",
                data: {
                    uid: representativeUID
                },
                success: (res) => {
                    onLoadRepresentative(res.json);
                    setTimeout(() => setLoading(false), 250);
                },
                fail: () => {
                    notification.error({
                        message: "Representante",
                        description: "Falha ao carregar dados do representante."
                    });
                    setTimeout(() => setLoading(false), 250);
                }
            })
        }
    },[open]);
    
    return (
        <ModalCentered
           open={open}
           title={!loading && title}
           footer={footer}
           {...modalProps}
        >
            {
                loading ? (
                    <Row
                        align="middle"
                        justify="center"
                        className="loading"
                    >
                        <Col span="24">
                            <Spin
                                tip="Aguarde..."
                                size="large"
                            >
                                <div className="content" />
                            </Spin>
                        </Col>
                    </Row>
                ) : children
            }
        </ModalCentered>
    )
}

export default RepresentativeModal;