import {
    Row,
    Col,
    Spin,
    notification
} from "antd";
import ModalCentered from "../../Modal/Centered";
import { useState, useEffect } from "react";
import _service from "@netuno/service-client";

function CandidateStatusModal({
    open,
    title,
    footer,
    statusUID,
    onLoadStatus,
    children,
    ...modalProps
}) {

    const [loading, setLoading] = useState(false);

    const loadStatus = () => {
        setLoading(true);
        _service({
            url:"candidate/status",
            method:"GET",
            data:{uid:statusUID},
            success: (res) =>{
                setLoading(false);
                onLoadStatus(res.json);
            },
            fail: (err) => {
                setLoading(false);
                notification.error({
                    message:"Carregar status",
                    description:"Falha ao carregar status"
                })
            }
        })
    }

    useEffect(()=>{
        if (open && statusUID) {
            loadStatus();
        }
    },[open])

    return (
        <ModalCentered
            open={open}
            title={!loading && title}
            footer={!loading && footer}
            {...modalProps}
        >

            {
                loading ? (
                    <Row
                        align="middle"
                        justify="center"
                        className="loading"
                    >
                        <Col span="24">
                            <Spin
                                tip="Aguarde..."
                                size="large"
                            >
                                <div className="content" />
                            </Spin>
                        </Col>
                    </Row>
                ) : children
            }

        </ModalCentered>
    )
}
export default CandidateStatusModal;