import {
    Col,
    Row,
    Layout,
    Typography,
    FloatButton,
    Button
} from "antd";
import { FaPlus, FaRegBuilding } from "react-icons/fa";
import { useRef, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import FileTypeModalWithForm from "../../../components/FileType/Modal/WithForm";
import FileTypeList from "../../../components/FileType/List";
import FileTypeSelectMult from "../../../components/FileType/Select/Mult";

function FileTypes ({

}) {

    const [openFileTypeModalWithForm, setOpenFileTypeModalWithForm] = useState(false);
    const fileTypeListRef = useRef(null);
    const [filters, setFilters] = useState({});
    
    return (
        <Layout.Content className="page-content sec-management-responsive">
            <Row justify={"space-between"} align={"middle"}>
                <Col>
                    <Typography.Title
                        level={2}
                    >
                        <FaRegBuilding /> Tipos de Arquivo
                    </Typography.Title>
                </Col>
                <Col span="12" className="sec-management-responsive__container">
                    <Row justify="end">
                        <Col className="sec-management-responsive__btn-new">
                            <Button
                                type="primary"
                                onClick={() => {
                                    setOpenFileTypeModalWithForm(true);
                                }}
                                icon={<PlusOutlined />}
                            >
                                Novo Tipo de Arquivo
                            </Button>
                        </Col>
                        <Col style={{ width: "500px" }} className="sec-management-responsive__filter">
                            <FileTypeSelectMult
                                onChange={(values)=>{
                                    setFilters((prev)=>({
                                        ...prev,
                                        uids:values
                                    }))
                                }}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>

            <FileTypeList
                maxColumnNumber={3}
                ref={fileTypeListRef}
                filters={filters}
            />

            <FloatButton
                style={{ border: '2px solid #00B298' }}
                onClick={() => {
                    setOpenFileTypeModalWithForm(true);
                }}
                icon={<FaPlus color="#00B298" />}
            />

           <FileTypeModalWithForm
               open={openFileTypeModalWithForm}
               onCancel={()=>{
                    setOpenFileTypeModalWithForm(false);
               }}
               onSubmitSuccess={()=>{
                    setOpenFileTypeModalWithForm(false);
                    fileTypeListRef.current && fileTypeListRef.current.onLoadFileTypes();
               }}
           />

        </Layout.Content>
    )
}

export default FileTypes;