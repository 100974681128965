import {
    Col,
    Form,
    Row,
    Input,
    DatePicker,
    InputNumber,
    notification,
    Select
} from "antd";

import {
    INSERT_NAME,
    INSERT_LINKEDIN,
    INSERT_STATUS,
    INSERT_STATUS_COLOR
} from "../../../consts";

import moment from 'moment';
import dayjs from 'dayjs';

import ExperienceTimeSelectOne from "../../ExperienceTime/Select/One";
import LanguageSelectMult from "../../Language/Select/Mult";
import TechnologySelectMult from "../../Technology/Select/Mult";
import StatusColorSelectOne from "../../Status/Color/Select/One";
import CandidateStatusSelectOne from "../../CandidateStatus/Select/One";
import ReferenceValueSelectOne from "../../RefenceValue/Select/One";

import { useState, useEffect } from "react";
import _service from "@netuno/service-client";

function CandidateForm({
    form,
    onSubmitFormSuccess,
    onSubmitFormFail,
    candidate
}) {

    const columnOptions = {
        xs: { span: 24 },
        sm: { span: 11 }
    }

    const [sexs, setSexs] = useState([]);
    const [stateFields, setStateFields] = useState({
        sex: { loading: false }
    })
    
    const maxTechnologiesValidator = (rule, value) => {
        if (value.length > 5) {
            return Promise.reject("Limite maximo de 5 tecnologias")
        }
        return Promise.resolve();
    }

    const onLoadSexs = () => {
        setStateFields((prev) => ({
            ...prev,
            sex: { loading: true }
        }))
        _service({
            url: "candidate/sex/list",
            method: "POST",
            success: (res) => {
                setStateFields((prev) => ({
                    ...prev,
                    sex: { loading: false }
                }))
                if (res.json) {
                    setSexs(res.json);
                }
            },
            fail: (err) => {
                setStateFields((prev) => ({
                    ...prev,
                    sex: { loading: false }
                }))
            }
        })
    }

    const onFinish = (values) => {

        if (values.technology_uids.length > 5) {
            notification.error({
                message:"Cadastrar Candidato",
                description:"Permitido somente 5 tecnologias por candidato."
            });
            return;
        }
     
        const selects = [
            "experience_time_uid",
            "sex_uid",
            "status_uid",
            "status_color_uid",
            "reference_value_cost_uid",
            "reference_value_sell_uid"
        ];

        const selectsMulti = [
            "technology_uids",
            "language_uids"
        ]

        const data = {
            ...values,
            cost_value: Number(values.cost_value),
            current_salary:Number(values.current_salary),
            sell_value:Number(values.sell_value),
            salary_expectation:Number(values.salary_expectation),
            date_of_birth: moment(values.date_of_birth.toString()).format("YYYY-MM-DD"),
            project_start_date: moment(values.project_start_date.toString()).format("YYYY-MM-DD"),
        };

        selects.forEach((select) => {
            if (typeof (values[select]) === "object") {
                data[select] = values[select].value;
            }
        });

        selectsMulti.forEach((select) => {
            if (values[select].length > 0) {
                if(typeof(values[select][0]) == "object") {
                    data[select] = values[select].map(value => value.value);
                 }
            }
        });

        if (candidate) {
            data.uid = candidate.uid
            _service({
                url: "candidate",
                method: "PUT",
                data: {
                    ...data,
                },
                success: (res) => {
                    notification.success({
                        message: "Edição de Candidato.",
                        description: "Candidato editada com sucesso"
                    })
                    form.resetFields();
                    onSubmitFormSuccess();

                }, fail: (err) => {
                    notification.error({
                        message: "Edição de Candidato.",
                        description: "Falha ao editar candidato!"
                    })
                    onSubmitFormFail();
                }
            })
        }
        if (!candidate) {
            _service({
                url: "candidate",
                method: "POST",
                data: data,
                success: (res) => {
                    notification.success({
                        message: "Cadastro de Candidato.",
                        description: "Candidato cadastrado com sucesso"
                    })
                    form.resetFields();
                    onSubmitFormSuccess();

                }, fail: (err) => {
                    notification.error({
                        message: "Cadastro de Candidato.",
                        description: "Falha ao cadastrar candidato!"
                    })
                    onSubmitFormFail();
                }
            })
        }
    };

    useEffect(() => {
        onLoadSexs();
    }, [])

    useEffect(() => {
        if (candidate) {
            form.setFieldsValue({
                ...candidate,
                date_of_birth: candidate.date_of_birth ? dayjs(candidate.date_of_birth) : "",
                project_start_date: candidate.project_start_date ? dayjs(candidate.project_start_date) : "",
                sex_uid: {
                    label: candidate.sex.name,
                    value: candidate.sex.uid
                },
                experience_time_uid: {
                    label: candidate.experience.name,
                    value: candidate.experience.uid
                },
                salary_expectation: candidate.expectative_salary ? Number(candidate.expectative_salary).toFixed(2) : 0.0,
                current_salary: candidate.current_salary ? Number(candidate.current_salary).toFixed(2) : 0.0,
                cost_value: candidate.cost_value ? Number(candidate.cost_value).toFixed(2) : 0.0,
                sell_value: candidate.sell_value ? Number(candidate.sell_value).toFixed(2) : 0.0,
                technology_uids: candidate.technologies.map(tec => ({ value: tec.uid, label: tec.name })),
                language_uids: candidate.languages.map(lang => ({ value: lang.uid, label: lang.name })),
                status_uid: {
                    label: candidate.status.name,
                    value: candidate.status.uid
                },
                status_color_uid: {
                    value: candidate.status_color.uid,
                    label: candidate.status_color.name
                },
                reference_value_cost_uid: {
                    value: candidate.reference_value_cost.uid,
                    label: candidate.reference_value_cost.name
                },
                reference_value_sell_uid: {
                    value: candidate.reference_value_sell.uid,
                    label: candidate.reference_value_sell.name
                }
            })
        }
    }, [candidate])

    return (
        <Form
            form={form}
            layout="vertical"
            style={{ padding: "0px 13px" }}
            onFinish={onFinish}
        >
            <Row
                justify="space-between"
                style={{ width: "100%" }}
            >
                <Col {...columnOptions}>
                    <Form.Item
                        label="Nome"
                        name="name"
                        rules={[{ required: true, message: INSERT_NAME }]}
                    >
                        <Input type="text" />
                    </Form.Item>
                </Col>

                <Col {...columnOptions}>
                    <Form.Item
                        label="Data de nascimento"
                        name="date_of_birth"
                    >
                        <DatePicker
                            placeholder=""
                            style={{
                                width: "100%"
                            }}
                        />
                    </Form.Item>
                </Col>

                <Col {...columnOptions}>
                    <Form.Item
                        label="Sexo"
                        name="sex_uid"
                        rules={[{ required: true, message: "Selecione o sexo." }]}
                    >
                        <Select
                            loading={stateFields.sex.loading}
                            placement="topLeft"
                            style={{ width: "100%" }}
                            options={sexs.map((gender) => ({
                                label: gender.name,
                                value: gender.uid
                            }))}
                        />
                    </Form.Item>
                </Col>

                <Col {...columnOptions}>
                    <Form.Item
                        label="Tempo de experiência"
                        name="experience_time_uid"
                        rules={[{ required: true, message: "Insira o tempo de experiência" }]}
                    >
                        <ExperienceTimeSelectOne />
                    </Form.Item>
                </Col>



                <Col {...columnOptions}>
                    <Form.Item
                        label="Telefone"
                        name="phone"
                        rules={[{
                            type: 'string', pattern: new RegExp("^(?!.*[./*&%$#!',\"])[\\0-9+-]+$"),
                            message: 'Telefone inválido!',

                        }]}
                    >
                        <Input type="text" />
                    </Form.Item>
                </Col>

                <Col {...columnOptions}>
                    <Form.Item
                        label="E-mail"
                        name="mail"
                        rules={[{ type: "email", message: "E-mail inválido!" }]}
                    >
                        <Input type="email" />
                    </Form.Item>
                </Col>

                <Col {...columnOptions}>
                    <Form.Item
                        label="Salário atual"
                        name="current_salary"
                    >
                        <InputNumber
                            defaultValue={0.0}
                            controls={false}
                            prefix="€"
                            style={{ width: "100%" }}
                            formatter={(value) => value.replace(/\B(?=(\d{3})+(?!\d))/g, '')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        />
                    </Form.Item>
                </Col>

                <Col {...columnOptions}>
                    <Form.Item
                        label="Pretensão salarial"
                        name="salary_expectation"
                    >
                        <InputNumber
                            defaultValue={0.0}
                            controls={false}
                            prefix="€"
                            style={{ width: "100%" }}
                            formatter={(value) => value.replace(/\B(?=(\d{3})+(?!\d))/g, '')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        />
                    </Form.Item>
                </Col>

                <Col {...columnOptions}>
                    <Form.Item
                        label="Referência (Custo)"
                        name="reference_value_cost_uid"
                        rules={[{ required: true, message: "Selecione a refêrencia de custo." }]}
                    >
                        <ReferenceValueSelectOne/>
                    </Form.Item>
                </Col>


                <Col {...columnOptions}>
                    <Form.Item
                        label="Custo"
                        name="cost_value"
                        rules={[{required:true, message:"Insira o valor de custo"}]}
                    >
                        <InputNumber
                            defaultValue={0.0}
                            controls={false}
                            prefix="€"
                            style={{ width: "100%" }}
                            formatter={(value) => value.replace(/\B(?=(\d{3})+(?!\d))/g, '')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        />
                    </Form.Item>
                </Col>

                <Col {...columnOptions}>
                    <Form.Item
                        label="Referência (Venda)"
                        name="reference_value_sell_uid"
                        rules={[{ required: true, message: "Selecione a referência de venda." }]}
                    >
                       <ReferenceValueSelectOne/>
                    </Form.Item>
                </Col>

                <Col {...columnOptions}>
                    <Form.Item
                        label="Venda"
                        name="sell_value"
                        rules={[{required:true, message:"Insira o valor de venda"}]}
                    >
                        <InputNumber
                            defaultValue={0.0}
                            controls={false}
                            prefix="€"
                            style={{ width: "100%" }}
                            formatter={(value) => value.replace(/\B(?=(\d{3})+(?!\d))/g, '')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label="Linkedin"
                        name="linkedin"
                        rules={[{ required: true, message: INSERT_LINKEDIN }]}
                    >
                        <Input type="text" />
                    </Form.Item>
                </Col>

                <Col {...columnOptions}>
                    <Form.Item
                        label="Data de inicio do projeto"
                        name="project_start_date"
                    >
                        <DatePicker
                            placeholder=""
                            style={{
                                width: "100%"
                            }}
                        />
                    </Form.Item>
                </Col>

                <Col {...columnOptions}>
                    <Form.Item
                        label="Github"
                        name="github"
                    >
                        <Input type="text" />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label="Tecnologias"
                        name="technology_uids"
                        rules={[
                            { required: true, message: "Selecione as tacnologias" },
                            { validator:maxTechnologiesValidator}
                            
                        ]}
                    >
                        <TechnologySelectMult 
                             placement="topLeft"
                        />
                    </Form.Item>
                </Col>

                <Col {...columnOptions}>
                    <Form.Item
                        label="Idiomas"
                        name="language_uids"
                        rules={[{ required: true, message: "Selecione os idiomas" }]}
                    >

                        <LanguageSelectMult 
                            placement={"topRight"}
                        />

                    </Form.Item>
                </Col>

                <Col {...columnOptions}>
                    <Form.Item
                        label="Status"
                        name="status_uid"
                        rules={[{ required: true, message: INSERT_STATUS }]}
                    >
                        <CandidateStatusSelectOne />
                    </Form.Item>
                </Col>


                <Col {...columnOptions}>
                    <Form.Item
                        label="Status Cor Sinalizadora"
                        name="status_color_uid"
                        rules={[{ required: true, message: INSERT_STATUS_COLOR }]}
                    >
                        <StatusColorSelectOne />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label="Anotações"
                        name="notes"
                    >

                        <Input.TextArea />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
}

export default CandidateForm;