import { useEffect, useState } from "react";
import ModalCentered from "../../Modal/Centered"
import _service from "@netuno/service-client";
import { Row, Col, Spin, notification } from "antd";

function CandidateModal({
    open,
    title,
    footer,
    children,
    candidateUID,
    onLoadCandidate,
    ...modalProps
}) {

    const [loading, setLoading] = useState(false);

    useEffect(()=>{
        if (open && candidateUID) {
            setLoading(true);
            _service({
                url:"candidate",
                method:"GET",
                data:{uid:candidateUID},
                success:(res) => {
                    onLoadCandidate(res.json);
                    setLoading(false);
                },
                fail: (err) => {
                    setLoading(false);
                    notification.error({
                        message:"Carregar candidato",
                        description:"Falha ao tentar carregar os dados do candidato"
                    });
                }
            })
        }
    },[open])

    return (
        <ModalCentered
            open={open}
            footer={!loading && footer}
            title={!loading && title}
            {...modalProps}
        >
              {
                loading ? (
                    <Row
                        align="middle"
                        justify="center"
                        className="loading"
                    >
                        <Col span="24">
                            <Spin
                                tip="Aguarde..."
                                size="large"
                            >
                                <div className="content" />
                            </Spin>
                        </Col>
                    </Row>
                ) : children
            }

        </ModalCentered>
   )
}

export default CandidateModal;