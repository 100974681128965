import {
    Row,
    notification,
    Spin,
    Empty,
    Pagination,
    Col
} from "antd";
import _service from "@netuno/service-client";

import CardCandidate from "./CardCandidate";
import { useEffect, useState, forwardRef, useImperativeHandle } from "react";

function CandidateList({
    columnConfig,
    filters
}, ref) {

    const [loading, setLoading] = useState(false);
    const [emptyImage, setEmptyImage] = useState(false);
    const [candidates, setCandidates] = useState([]);
    const [candidatesTotal, setCandidatesTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10;

    const onChangePagination = (page) => {
        setCurrentPage(page);
    }

    const CandidateListPagination = () => (
        <Row justify="center">
            <Col>
                <Pagination
                    current={currentPage}
                    total={candidatesTotal}
                    pageSize={pageSize}
                    onChange={onChangePagination}
                    style={{
                        margin: "12px 0px"
                    }}
                />
            </Col>
        </Row>
    )

    const onClickActived = (uid, active) => {
       setCandidates((prev) => prev.map((candidate)=>{
            if (candidate.uid === uid) {
                return({
                    ...candidate,
                    active:active
                })
            }
            return candidate;
       }))
    }

    const onLoadCandidates = () => {
        setLoading(true);
        _service({
            url: "candidate/list",
            method: "POST",
            data: {
                pagination:{
                    page:currentPage,
                    size:pageSize
                },
                filters:{
                    ...filters
                },
            },
            success: (res) => {
                setLoading(false);
                if (res.json) {
                    setCandidates(res.json.candidates);
                    setCandidatesTotal(res.json.candidates_total);
                    setEmptyImage(res.json.candidates_total > 0 ? false : true);
                }
            },
            fail: (err) => {
                setLoading(false);
                setEmptyImage(true);
                notification.error({
                    message: "Carregar candidatos",
                    description: "falha ao carregar candidatos"
                })
            }
        })

    }

    useImperativeHandle(ref, () => ({
        onLoadCandidates
    }))

    useEffect(() => {
        if (currentPage === 1) {
            onLoadCandidates();
            return;
        }
        setCurrentPage(1);
    },[filters])
    
    useEffect(() => {
        onLoadCandidates();  
    }, [currentPage])


    if (loading) {
        return (
            <Row
                className="loading"
            >
                <Spin
                    tip="Aguarde..."
                    size="large"
                >
                    <div className="content" />
                </Spin>
            </Row>
        )
    }

    if (emptyImage) {
        return (
            <Row
                span={24}
                align="middle"
                className="div_empty_result"
            >
                <Empty
                    description={
                        <span>
                            Pesquisa não encontrada
                        </span>
                    }
                />
            </Row>
        )
    }

    return (
        <>
            {candidatesTotal > 0 && (
                <Row>
                    <Col span={24}>
                        <CandidateListPagination />
                    </Col>
                </Row>
            )}

            <Row
                align="top"
                justify="start"
                style={{ width: "100%" }}
                gutter={[12, 12]}
            >
                {
                    candidates.length > 0 && candidates.map((candidate) => (
                        <CardCandidate
                            columnConfig={columnConfig}
                            candidate={candidate}
                            onLoadCandidate={onLoadCandidates}
                            onClickActived={onClickActived}
                        />
                    ))
                }
            </Row>

            {candidatesTotal > 0 && (
                <Row style={{ marginTop: "auto", paddingTop: "12px" }}>
                    <Col span={24}>
                        <CandidateListPagination />
                    </Col>
                </Row>
            )}
        </>
    )
}

export default forwardRef(CandidateList);