import { Card, Col, Row, Tooltip, Typography } from "antd";
import { ClockCircleOutlined, UserOutlined, CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

import { FaRegBuilding } from "react-icons/fa";

import moment from "moment";

import "./styles.less";

function InteractionPreviewListItem({
  interaction,
  onClick
}) {
  const rows = [
    {
      key: "moment",
      icon: <ClockCircleOutlined />,
      label: "Data e Hora",
      value: moment(interaction.moment).format("DD/MM/YYYY hh:mm")
    },
    {
      key: "author",
      icon: <UserOutlined />,
      label: "Autor",
      value: interaction.people.name
    },
    {
      key: "representative",
      icon: <UserOutlined />,
      label: "Representante",
      value: interaction.representative.name
    },
    {
      key: "company",
      icon: <FaRegBuilding />,
      label: "Empresa",
      value: interaction.company.name
    }
  ];

  return (
    <Card
      rootClassName="interaction-preview-item"
      size="small"
      onClick={onClick}
      title={(
        <Row wrap={false} justify="space-between" align="middle">
          <Col span={22}>
            <Typography.Text ellipsis>
              {interaction.title}
            </Typography.Text>
          </Col>
          <Col>
            <Tooltip
              title={`Interação ${interaction.active ? "ativa" : "desativada"}`}
            >
              <Typography.Text type={interaction.active ? "success" : "danger"}>
                {interaction.active ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
              </Typography.Text>
            </Tooltip>
          </Col>
        </Row>
      )}
    >
      <Row>
        {
          rows.map((row) => (
            <Col span="24" key={row.key}>
              <Row align="middle" justify="space-between">
                <Col span={12}>
                  <Typography.Paragraph type="secondary">
                    {row.icon} {row.label}
                  </Typography.Paragraph>
                </Col>
                <Col span={12}>
                  <Typography.Paragraph className="interaction-preview-item__value" ellipsis>
                    {row.value}
                  </Typography.Paragraph>
                </Col>
              </Row>
            </Col>
          ))
        }
      </Row>
    </Card>
  );
}

export default InteractionPreviewListItem;