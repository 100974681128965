import { 
    Row,
    Col,
    Button,
    Form
 } from "antd";
import { useState } from "react";
import ExperienceTimeModal from "..";
import ExperienceTimeForm from "../../Form";

function ExperienceTimeModalWithForm ({
    open,
    onCancel,
    experienceTimeUID,
    onSubmitSuccess
}) {

    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [experienceTime, setExperienceTime] = useState(null);

    const onLoadExperienceTime = (data) => {
        setExperienceTime(data);
    }

    const onClickSubmit = async () => {
        try {
            setLoading(true);
            await form.validateFields();
            form.submit();
        } catch (error) {
            setLoading(false);
        }
    }
    
    return (
        <ExperienceTimeModal
            open={open}
            onLoadExperience={onLoadExperienceTime}
            experienceTimeUID={experienceTimeUID}
            onCancel={() => {
                onCancel();
                form.resetFields();
            }}
            title={`${experienceTimeUID ? "Edição" : "Cadastro"} de experiência`}
            footer={(
                <Row
                    align="middle"
                    justify="end"
                >
                    <Col>
                        <Button
                            type="link"
                            onClick={() => {
                                onCancel();
                                form.resetFields();
                            }}
                        >
                            Cancelar
                        </Button>
                    </Col>

                    <Col>
                        <Button
                            type="primary"
                            loading={loading}
                            onClick={onClickSubmit}
                        >
                            Salvar
                        </Button>
                    </Col>
                </Row>
            )}
        >

            <ExperienceTimeForm
                experienceTime={experienceTime}
                onSubmitSuccess={()=>{
                    setLoading(false);
                    onSubmitSuccess();
                    form.resetFields();
                }}
                onSubmitFail={()=>{
                    setLoading(false);
                }}
                form={form}
            />                

        </ExperienceTimeModal>
    )
}

export default ExperienceTimeModalWithForm;