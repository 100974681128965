import { useEffect, useState } from "react";
import { Col, Pagination, Row, Spin, Empty, notification } from "antd";
import TypeOfContactCard from "./TypeOfContactCard";
import _service from "@netuno/service-client";
import "./styles.less";

function TypeOfContactCardList({
    filters,
    maxColumnNumber,
    formSearch
}) {
    const [typeOfContacts, setTypeOfContacts] = useState([]);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [emptyImage, setEmptyImage] = useState(false);
    const pageSize = 10;
    const columnOptions = [
        {
            span: 24
        },
        {
            sm: { span: 24 },
            md: { span: 12 }
        },
        {   
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 12 },
            lg: { span: 8 }
        },
    ];

    const onPageChange = (page) => {
        setPage(page);
    }

    const onActive = (active,uid) => {
        setTypeOfContacts((prev) => prev.map((contactType)=>{
            if (contactType.uid === uid) {
                return ({
                    ...contactType,
                    active
                })
            }
            return contactType;
        }))
    }

    const loadContactTypes = () => {
        setLoading(true);
        _service({
            url: "interaction/contact_type/list",
            method: "POST",
            data: {
                filters,
                pagination:{
                    page,
                    size: pageSize
                }
            },
            success: (res) => {
                setLoading(false);
                if (res.json) {
                    setTypeOfContacts(res.json.contact_types);
                    setTotal(res.json.contact_types_total);
                    setEmptyImage(res.json.contact_types_total > 0 ? false : true);
                }
            },
            fail: (err) => {
                setLoading(false);
                setEmptyImage(true);
                console.error(err);
            }
        });

    };

    useEffect(() => {
        if (page !== 1) {
            setPage(1);

            return;
        }

        loadContactTypes();
    }, [filters]);

    useEffect(() => {
        loadContactTypes();
    }, [page]);

    if (loading) {
        return (
            <Row
                className="loading"
            >

                <Spin
                    tip="Aguarde..."
                    size="large"
                >
                    <div className="content" />
                </Spin>
            </Row>
        )
    }

    if (emptyImage) {
        return (
            <Row
                span={24}
                align="middle"
                className="div_empty_result"
            >
                <Empty
                    description={
                        <span>
                            Pesquisa não encontrada
                        </span>
                    }
                />
            </Row>
        )
    }

    return (
        <>
            {
                total > 0 && (
                    <Row
                        align="middle"
                        justify="center"
                        className="typeOfContact-card-list-pagination-top">
                        <Col>
                            <Pagination
                                total={total}
                                onChange={onPageChange}
                                current={page}
                                pageSize={pageSize}
                            />
                        </Col>
                    </Row>
                )
            }
            <Row
                gutter={[12, 12]}
                justify="start"
                align="top"
            >
                {typeOfContacts.length > 0 && typeOfContacts.map((type_of_contacts) => (
                    <Col key={type_of_contacts.uid} {...columnOptions[maxColumnNumber - 1]}>
                        <TypeOfContactCard
                            contactType={type_of_contacts}
                            formSearch={formSearch}
                            onActive={onActive} 
                            />
                    </Col>
                ))}
            </Row>
            {
                total > 0 && (
                    <Row
                        align="middle"
                        justify="center"
                        className="typeOfContact-card-list-pagination-bottom">
                        <Col>
                            <Pagination
                                total={total}
                                onChange={onPageChange}
                                current={page}
                                pageSize={pageSize}
                            />
                        </Col>
                    </Row>
                )
            }
        </>
    );
}

export default TypeOfContactCardList;