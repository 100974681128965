import { useState } from "react";

import { connect } from "react-redux";

import { Button, Col, Row, Switch, Tabs, Tooltip, Typography, notification } from "antd";
import { EditOutlined, UserOutlined } from "@ant-design/icons";

import { MdOutlineConnectWithoutContact } from "react-icons/md";

import CompanyModal from "..";
import CompanyModalInfoTabGeneral from "./Tab/General";
import CompanyModalInfoTabInteractions from "./Tab/Interactions";
import CompanyModalInfoTabRepresentatives from "./Tab/Representatives";
import _service from "@netuno/service-client";

function CompanyModalInfo({
  open,
  companyUID,
  loggedUserInfo,
  onCancel,
  onClickEdit,
  onToggleActive
}) {
  const [company, setCompany] = useState(null);
  const [loadingToggleActive, setLoadingToggleActive] = useState(false);

  const onLoadCompany = (companyData) => {
    setCompany(companyData);
  };

  const toggleActive = () => {
    setLoadingToggleActive(true);

    _service({
      url: "company",
      method: "PUT",
      data: {
        ...company,
        service_uid: company.service.uid,
        status_uid: company.status.uid,
        sector_uid: company.sector.uid,
        color_status_uid: company.color_status.uid,
        active: !company.active
      },
      success: () => {
        setLoadingToggleActive(false);
        setCompany((prev) => ({
          ...prev,
          active: !company.active
        }));
        onToggleActive(!company.active, company.uid);
        notification.success({
          message: `${company.active ? "Desativar" : "Ativar"} Empresa`,
          description: `Empresa ${company.active ? "desativada" : "ativada"} com sucesso.`
        });
      },
      fail: () => {
        setLoadingToggleActive(false);
        notification.error({
          message: `${company.active ? "Desativar" : "Ativar"} Empresa`,
          description: `Falha ao tentar ${company.active ? "desativar" : "ativar"} a empresa.`
        });
      }
    });
  };

  return (
    <CompanyModal
      open={open}
      companyUID={companyUID}
      footer={false}
      onCancel={onCancel}
      onLoadCompany={onLoadCompany}
      width="90%"
      title={company && (
        <Row align="middle" justify="space-between">
          <Col>
            {company.name}
          </Col>

          <Col className="btns_modal">
            <Row align="middle" gutter={[12, 0]}>
              {
                loggedUserInfo?.admin && (
                  <Col style={{marginBottom:"2px"}}>
                    <Tooltip
                      title={`${company.active ? "Desativar" : "Ativar"} empresa`}
                    >
                      <Switch
                        size="small"
                        loading={loadingToggleActive}
                        checked={company.active}
                        onClick={toggleActive}
                      />
                    </Tooltip>
                  </Col>
                )
              }

              <Col>
                <Button
                  type="link"
                  onClick={onClickEdit}
                  disabled={!company.active}
                  icon={<EditOutlined />}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      style={{
        maxWidth: "900px",
      }}
      bodyStyle={{
        height: "72vh"
      }}
    >
      {
        company && (
          <Tabs
            tabBarStyle={{
              position: "sticky",
              top: 0,
              zIndex: 1,
              background: "#FFF"
            }}
            items={[
              {
                key: "general",
                label: (
                  <Typography>
                    <span>
                      Informações Gerais
                    </span>
                  </Typography>
                ),
                children: <CompanyModalInfoTabGeneral company={company} />
              },
              {
                key: "representatives",
                label: (
                  <Typography>
                    <span>
                      <UserOutlined size={16} /> Representantes
                    </span>
                  </Typography>
                ),
                children: <CompanyModalInfoTabRepresentatives company={company} />
              },
              {
                key: "interactions",
                label: (
                  <Typography>
                    <span>
                      <MdOutlineConnectWithoutContact size={16} /> Interações
                    </span>
                  </Typography>
                ),
                children: <CompanyModalInfoTabInteractions company={company} />
              }
            ]}
          />
        )
      }
    </CompanyModal>
  );
}

const mapStateToProps = (store) => {
  const { loggedUserInfo } = store.loggedUserInfoState;

  return {
    loggedUserInfo
  };
};

export default connect(mapStateToProps, {})(CompanyModalInfo);
