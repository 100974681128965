import {
    useState
} from "react";

import {
    Col,
    Form,
    Input,
    Layout,
    Row,
    Typography,
    FloatButton,
    Tooltip,
    Button
} from "antd";

import {
    FaRegBuilding,
    FaPlus
} from "react-icons/fa";
import { PlusOutlined } from "@ant-design/icons";

import _service from "@netuno/service-client";

import SectorCardList from "../../../components/SectorCardList";
import SectorModalWithForm from "../../../components/Sector/Modal/WithForm";
import SectorSelectMultiple from "../../../components/Sector/Select/Multiple";

import "./styles.less";

function Sector() {
    const [openModalWithForm, setOpenSectorModalWithForm] = useState(false);
    const [filters, setFilters] = useState({});
    const [formSearch] = Form.useForm();
    const [formFilters] = Form.useForm();

    const onFiltersValuesChange = (values) => {
        formSearch.setFieldsValue(values);
        formFilters.setFieldsValue(values);
    }

    const onSubmitFilters = (values) => {
        setFilters({
            ...filters,
            ...values
        });
    }

    return (
        <Layout.Content className="page-content sec-management-responsive">
            <Row justify="space-between">
                <Col
                    span="12"
                >
                    <Typography.Title level={2}>
                        <FaRegBuilding /> Setor
                    </Typography.Title>
                </Col>
                <Col span="12" className="sec-management-responsive__container">
                    <Row justify="end">
                        <Col className="sec-management-responsive__btn-new">
                            <Button
                                type="primary"
                                onClick={() => {
                                    setOpenSectorModalWithForm(true);
                                }}
                                icon={<PlusOutlined />}
                            >
                                Novo Setor
                            </Button>
                        </Col>
                        <Col className="sec-management-responsive__filter">
                            <Form
                                form={formSearch}
                                onValuesChange={onFiltersValuesChange}
                                onFinish={onSubmitFilters}
                            >
                                <Form.Item name="sectors_uids">
                                    <SectorSelectMultiple
                                        placeholder="Pesquisar por setor"
                                        onChange={() => {
                                            formSearch.submit();
                                        }}
                                    />
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <SectorCardList
                filters={filters}
                pageSize={21}
                maxColumnNumber={3}
                formSearch={formSearch}
            />
            <Tooltip
                title="Adicionar"
            >
                <FloatButton
                    style={{ border: '2px solid #00B298' }}
                    onClick={() => {
                        setOpenSectorModalWithForm(true);
                    }}
                    icon={<FaPlus color="#00B298" />}
                />
            </Tooltip>
            <SectorModalWithForm
                open={openModalWithForm}
                onCancel={() => {
                    setOpenSectorModalWithForm(false);
                }}
                onSubmitFinishSuccess={() => {
                    setOpenSectorModalWithForm(false);

                    formSearch.submit();
                }}
            />
        </Layout.Content>
    );
}

export default Sector;
