import { Col, Row, Typography } from "antd";
import { UserOutlined, ClockCircleOutlined, AlignLeftOutlined } from "@ant-design/icons";

import { FaBuilding } from "react-icons/fa";

import moment from "moment";

import ParagraphWithLabel from "../../../../Paragraph/WithLabel";
import { MdConnectWithoutContact } from "react-icons/md";

function InteractionModalInfoTabInfo({
  interaction
}) {
  const infos = [
    {
      key: "moment",
      icon: <ClockCircleOutlined />,
      label: "Data e Hora: ",
      value: moment(interaction.moment).format("DD/MM/YYYY hh:mm")
    },
    {
      key: "author",
      icon: <UserOutlined />,
      label: "Autor: ",
      value: interaction.people.name
    },
    {
      key: "representative",
      icon: <UserOutlined />,
      label: "Representante: ",
      value: interaction.representative.name
    },
    {
      key: "company",
      icon: <FaBuilding />,
      label: "Empresa: ",
      value: interaction.company.name
    },
    {
      key: "contact_type",
      icon: <MdConnectWithoutContact />,
      label: "Tipo de Contato: ",
      value: interaction.contact_type.name
    },
    {
      key: "contact_mean",
      icon: <MdConnectWithoutContact />,
      label: "Meio de Contato: ",
      value: interaction.contact_mean.name
    }
  ]
  return (
    <Row justify="space-between">
      {
        infos.map((info) => (
          <Col
            key={info.key}
            md={{
              span: 12
            }}
            sm={{
              span: 24
            }}
            xs={{
              span: 24
            }}
          >
            <ParagraphWithLabel
              label={info.label}
              labelIcon={info.icon}
              text={info.value}
            />
          </Col>
        ))
      }

      <Col span={24}>
        <Typography.Paragraph type="secondary">
          <AlignLeftOutlined /> Anotações
        </Typography.Paragraph>
        <Typography.Paragraph>
          {interaction.content}
        </Typography.Paragraph>
      </Col>
    </Row>
  );
}

export default InteractionModalInfoTabInfo;