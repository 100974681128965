import { Card, Col, Row, Typography, Tag, Tooltip } from "antd";
import { TagOutlined, CheckCircleOutlined, CloseCircleOutlined, EnvironmentOutlined } from '@ant-design/icons';

import { GrStatusGoodSmall } from "react-icons/gr";

import "./styles.less";

function CompanyListCard({
  company,
  onClick
}) {
  const fields = [
    {
      key: "status",
      icon: <GrStatusGoodSmall />,
      label: "Status",
      value: (
        <Tag
          color={company.color_status.code_hex}
          style={{
            margin: "0px 0px 0px auto",
            width: "fit-content"
          }}
        >
          {company.status.name}
        </Tag>
      )
    },
    {
      key: "service",
      icon: <TagOutlined />,
      label: "Serviço",
      value: company.service.name
    },
    {
      key: "country",
      icon: <EnvironmentOutlined />,
      label: "País",
      value: company.country
    },
    {
      key: "city",
      icon: <EnvironmentOutlined />,
      label: "Cidade",
      value: company.city
    }
  ];

  return (
    <>
      <Card
        className="company-card"
        title={
          <Row align="middle" justify="space-between">
            <Col span={22}>
              <Typography.Paragraph ellipsis style={{ margin: 0 }}>
                {company.name}
              </Typography.Paragraph>
            </Col>
            <Col>
              <Tooltip
                title={`Empresa ${company.active ? "ativa" : "desativada"}`}
              >
                <Typography.Text type={company.active ? "success" : "danger"}>
                  {company.active ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
                </Typography.Text>
              </Tooltip>
            </Col>
          </Row>
        }
        size="small"
        onClick={() => {
          onClick(company);
        }}
      >
        <Row>
          {
            fields.map((field) => (
              <Col key={field.key} span={24}>
                <Row justify="space-between">
                  <Col span={12}>
                    <Typography.Paragraph type="secondary">
                      <span>
                        {field.icon} {field.label}
                      </span>
                    </Typography.Paragraph>
                  </Col>
                  <Col span={12}>
                    <Typography.Paragraph ellipsis className="company-card__value">
                      {field.value}
                    </Typography.Paragraph>
                  </Col>
                </Row>
              </Col>
            ))
          }
        </Row>
      </Card>
    </>
  );
}

export default CompanyListCard;
