import {
    Row,
    Col,
    Button,
    Typography,
    Form,
    Input,
    DatePicker,
    TimePicker,
    notification
} from "antd";
import _auth from '@netuno/auth-client';
import { Navigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import isBetween from 'dayjs/plugin/isBetween';
import { useEffect, useState } from "react";
import _service from "@netuno/service-client";
import "./styles.less"

dayjs.extend(isBetween)

function Appointment() {

    const { code } = useParams();

    const [schedules, setSchedules] = useState([]);
    const [exceptionDays, setExceptionDays] = useState([]);
    const [schedullings, setSchedullings] = useState([]);
    const [selectedSchedule, setSelectedSchedule] = useState(null);
    const [people, setPeople] = useState(null);
    const [loading, setLoading] = useState(false);

    const [form] = Form.useForm();
    const formDate = Form.useWatch('date', form);

    const weekDays = [
        'sunday',
        'monday',
        'tuesday',
        'wendnesday',
        'thursday',
        'friday',
        'saturday'
    ];


    const columnConfig = {
        xs: {
            span: 24
        },
        sm: {
            span: 24
        },
        md: {
            span: 12
        },
        lg: {
            span: 12
        }
    }

    const createInterview = (data) => {
        data.moment = data.date
            .set('hour', data.time.get('hour'))
            .set('minute', data.time.get('minute'))
            .set('second', 0)
            .format('YYYY-MM-DD HH:mm:ss');

        setLoading(true);
        _service({
            url: "interview",
            method: "POST",
            data: {
                ...data,
                people_uid: people.uid
            },
            success: (res) => {
                notification.success({
                    message: "Agendamento de entrevista",
                    description: "Entrevista agendada com sucesso"
                })
                setLoading(false);
                form.resetFields();
            },
            fail: (err) => {
                notification.error({
                    message: "Agendamento de entrevista",
                    description: "Falha ao agendar entrevista, tente novamente mais tarde."
                });
                setLoading(false);
            }
        })
    }

    const loadSchedule = () => {
        _service({
            url: `interview/schedule/list`,
            method: "POST",
            data: {
                people_uid: people.uid
            },
            success: ({ json }) => {
                setSchedules(json.schedule);
            },
            fail: (err) => {
                notification.error({
                    message: "Carregar agenda",
                    description: "Falha ao carregar agenda"
                })
            }
        })
    }

    useEffect(() => {
        if (people) {
            loadSchedule();
        }
    }, [people])

    useEffect(() => {
        _service({
            url: `interview/code?interview_code=${code}`,
            method: "GET",
            success: ({ json }) => {
                setPeople(json.people);
            },
            fail: (err) => {
                notification.error({
                    message: "Carregar utilizador",
                    description: "Falha ao carregar utilizador"
                })
            }
        })
    }, [])

    if (_auth.isLogged()) {
        return <Navigate to={"/companies"} />
    }

    return (
        <>
            <Row className="appointment__logo" justify={"center"} align={"middle"} >
                <Col {...columnConfig}>
                    <Typography.Title level={2} style={{ marginBottom: "20px", color: "#007A51" }}>
                        Desperte todo o potencial com nossa ferramenta.
                    </Typography.Title>
                    <Typography.Text style={{ fontSize: "15px" }}>
                        Aqui você irá converter encontros em oportunidades e oportunidades em conquistas. <a href="#form_schedulling" style={{color:"rgb(0, 122, 81)", textDecoration:"underline"}}>
                            Agende
                            </a> sua reunião agora e simplifique seu caminho rumo ao sucesso.
                    </Typography.Text>
                </Col>
                <Col className="appointment__banner--logo" {...columnConfig}>
                    <img src="/images/sider-login.png" alt="img_schedulling" />
                </Col>
            </Row>
            <Row className="appointment__form" justify={"center"} align={"middle"} id="form_schedulling">
                <Col span={24}>
                    <Row justify={"center"} align={"middle"}>
                        <Typography.Title level={2}>Agendar entrevista</Typography.Title>
                    </Row>
                    <Row justify={"center"} align={"middle"}>
                        <Form
                            form={form}
                            layout="vertical"
                            style={{ maxWidth: 1000, width: "100%" }}
                            onFinish={createInterview}
                        >

                            <Form.Item
                                label="Nome"
                                name="name"
                                rules={[{ required: true, message: 'Please input your username!' }]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="E-mail"
                                name="email"
                                rules={[{ required: true, message: 'Please input your username!' }]}
                            >
                                <Input />
                            </Form.Item>


                            <Form.Item
                                label="Telefone"
                                name="phone"
                                rules={[{ required: true, message: 'Please input your username!' }]}
                            >
                                <Input />
                            </Form.Item>

                            <Row gutter={[12, 0]}>
                                <Col
                                    sm={{
                                        span: 24
                                    }}
                                    xs={{
                                        span: 24
                                    }}
                                    md={{
                                        span: 12
                                    }}

                                >
                                    <Form.Item
                                        label="Data"
                                        name="date"
                                        rules={[{ required: true, message: 'Please input your username!' }]}
                                        style={{ minWidth: "100%" }}
                                    >
                                        <DatePicker
                                            style={{ minWidth: "100%" }}
                                            format="DD/MM/YYYY"
                                            disabledDate={(current) => {
                                                const now = dayjs(new Date());
                                                const todaySchedules = schedules.filter((schedule) => {
                                                    return schedule[weekDays[current.get('day')]];
                                                });
                                                const scheduleStart = todaySchedules[0];
                                                const scheduleEnd = todaySchedules[todaySchedules.length - 1];
                                                const isToday = current.isSame(now, 'D');

                                                const isNotException = exceptionDays.every((exceptionDay) => {
                                                    const day = dayjs(exceptionDay.date, 'YYYY-MM-DD');

                                                    return day.isSame(current, 'D') === false;
                                                });

                                                const isValid = [
                                                    current.startOf('D').isBefore(now.startOf('D'), 'D'),
                                                    todaySchedules.length === 0,
                                                    isToday && now.isBetween(scheduleStart, scheduleEnd, 'minutes', '[]'),
                                                    !isNotException
                                                ].every((result) => result === false);

                                                return !isValid;
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col
                                    sm={{
                                        span: 24
                                    }}
                                    xs={{
                                        span: 24
                                    }}
                                    md={{
                                        span: 12
                                    }}

                                >
                                    <Form.Item
                                        label="Horario"
                                        name="time"
                                        rules={[{ required: true, message: 'Please input your username!' }]}
                                        style={{ minWidth: "100%" }}
                                    >
                                        <TimePicker
                                            style={{ minWidth: "100%" }}
                                            hideDisabledOptions
                                            inputReadOnly
                                            showNow={false}
                                            showSecond={false}
                                            disabled={!formDate}
                                            placeholder=""
                                            format="HH:mm"
                                            minuteStep={selectedSchedule?.interval_min || 1}
                                            showMinute={!!selectedSchedule}
                                            onSelect={(value) => {
                                                for (const schedule of schedules) {
                                                    let { start, end } = schedule;

                                                    start = dayjs(start, 'HH:mm:ss');
                                                    end = dayjs(end, 'HH:mm:ss');

                                                    if (value.isBetween(start, end, 'minute', '[]')) {
                                                        setSelectedSchedule(schedule);
                                                    }
                                                }
                                            }}
                                            disabledTime={(now) => {
                                                const isToday = formDate.isSame(now, 'D')

                                                return ({
                                                    disabledHours: () => {
                                                        const disabledHours = [];

                                                        for (let i = 0; i < 24; i++) {
                                                            disabledHours.push(i);
                                                        }

                                                        for (const schedule of schedules) {
                                                            if (schedule[weekDays[formDate.get('day')]]) {
                                                                const startHour = dayjs(schedule.start, 'HH:mm:ss').get('hour');
                                                                const endHour = dayjs(schedule.end, 'HH:mm:ss').get('hour');

                                                                for (let i = startHour; i <= endHour; i++) {
                                                                    if (now.get('hour') > i && isToday) {
                                                                        continue;
                                                                    }

                                                                    disabledHours[i] = null;
                                                                }
                                                            }
                                                        }

                                                        return disabledHours;
                                                    },
                                                    disabledMinutes: (selectedHour) => {
                                                        const end = dayjs(selectedSchedule?.end, 'HH:mm:ss');
                                                        const disabledMinutes = [];

                                                        for (const schedulling of schedullings) {
                                                            const moment = dayjs(schedulling.moment);

                                                            if (selectedHour === moment.get('hour')) {
                                                                disabledMinutes.push(moment.get('minute'));
                                                            }
                                                        }

                                                        for (let i = 0; i < 60; i += selectedSchedule?.interval_min || 1) {
                                                            if (selectedHour === end.get('hour')) {
                                                                if (i > end.get('minute')) {
                                                                    disabledMinutes.push(i);
                                                                }
                                                            }

                                                            if (
                                                                selectedHour === now.get('hour')
                                                                && now.get('minute') > i
                                                                && isToday
                                                            ) {
                                                                disabledMinutes.push(i);
                                                            }
                                                        }

                                                        return disabledMinutes;
                                                    }
                                                });
                                            }}

                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Button
                                style={{ height: "40px" }}
                                block
                                title="Agendar Horário"
                                type="primary"
                                htmlType="submit"
                                loading={loading}
                            >
                                Agendar
                            </Button>


                        </Form>
                    </Row>
                </Col>
            </Row>
        </>
    )

}

export default Appointment;