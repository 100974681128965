import {
    useEffect,
    useState
} from "react";

import {
    Col,
    Form,
    Input,
    InputNumber,
    notification,
    Row,
    Select
} from "antd";

import _service from "@netuno/service-client";

import {
    failToLoadOptionsNotification
} from "../../../utils";
import {
    INSERT_EMAIL,
    INSERT_LINKEDIN,
    INSERT_NAME,
    INSERT_NOTES,
    INSERT_PHONE,
    SELECT_COMPANY,
    SELECT_POSITION
} from "../../../consts";
import CompanySelectOne from "../../Company/Select/One";

function RepresentativeForm({
    form,
    onSubmitFinishSuccess,
    onSubmitFinishFail,
    ...formConfig
}) {
    const [positions, setPositions] = useState([]);
    const [fieldsStates, setFieldsStates] = useState({
        position_uid: {
            loading: true
        }
    });

    const columnOptions = {
        xs: { span: 24 },
        sm: { span: 11 }
    }

    const loadPositions = () => {
        setFieldsStates((prev) => ({
            ...prev,
            position_uid: {
                loading: true
            }
        }));

        _service({
            url: "representative/position/list",
            method: "POST",
            data:{
                filters:{active:true}
            },
            success: (res) => {
                setPositions(res.json.positions);
                setFieldsStates((prev) => ({
                    ...prev,
                    position_uid: {
                        loading: false
                    }
                }));
            },
            fail: () => {
                failToLoadOptionsNotification("Cargos");
                setFieldsStates((prev) => ({
                    ...prev,
                    position_uid: {
                        loading: false
                    }
                }));
            }
        });
    };

    useEffect(() => {
        if (!formConfig?.readOnly?.position_uid) {
            loadPositions();
        }
    }, []);

    const onFinish = (values) => {
        const selects = [
            "company_uid",
            "position_uid"
        ];
        const data = {
            ...values,
            phone:`${values.phone}`
        };

        selects.forEach((select) => {
            if (typeof (data[select]) === "object") {
                data[select] = data[select].value;
            }
        });

        if (!formConfig.valuesDefault.uid) {
            _service({
                url: "representative",
                method: "POST",
                data,
                success: () => {
                    notification.success({
                        message: "Cadastro de Representante",
                        description: "Representante cadastrado com sucesso."
                    });
                    form.resetFields();
                    onSubmitFinishSuccess();
                },
                fail: () => {
                    notification.error({
                        message: "Cadastro de Representante",
                        description: "Falaha ao tentar cadastrar um novo representante."
                    });
                    onSubmitFinishFail();
                }
            });
        }

        if (formConfig.valuesDefault.uid) {
            data.uid = formConfig.valuesDefault.uid;

            _service({
                url: "representative",
                method: "PUT",
                data,
                fail: (err) => {
                    console.error("FormRepresentative - create representative", err);
                    notification.error({
                        message: 'Falha ao Editar',
                        description: 'Ocorreu um erro ao editar representante, por favor tente novamente mais tarde ou entre em contato com o suporte.'
                    });
                    onSubmitFinishFail();
                },
                success: () => {
                    form.resetFields();
                    onSubmitFinishSuccess();
                    notification.success({
                        message: "Editado com Sucesso",
                    });
                }
            });
        }
    };

    return (
        <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            initialValues={formConfig.valuesDefault}
        >
            <Row 
                justify="space-between"
               
            >
                <Col {...columnOptions}>
                    <Form.Item
                        name="name"
                        label="Nome"
                        rules={[
                            {
                                required: true,
                                message: INSERT_NAME
                            },
                        ]}
                    >
                        <Input type="text" />
                    </Form.Item>
                </Col>

                <Col {...columnOptions}>
                    <Form.Item
                        name="company_uid"
                        label="Empresa"
                        rules={[
                            {
                                required: true,
                                message: SELECT_COMPANY
                            },
                        ]}
                    >
                        <CompanySelectOne
                            disabled={formConfig.readOnly?.company_uid}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row 
                justify="space-between"
            >
                <Col {...columnOptions}>
                    <Form.Item
                        name="position_uid"
                        label="Cargo"
                        rules={[
                            {
                                required: true,
                                message: SELECT_POSITION
                            }
                        ]}
                    >
                        <Select
                            optionFilterProp="label"
                            loading={fieldsStates.position_uid.loading}
                            open={formConfig.readOnly?.position_uid ? true : undefined}
                            options={positions.map((position) => ({
                                label: position.name,
                                value: position.uid
                            }))}
                        />
                    </Form.Item>
                </Col>

                <Col {...columnOptions}>
                    <Form.Item
                        name="linkedin"
                        label="Linkedin"
                        rules={[
                            {
                                required: true,
                                message: INSERT_LINKEDIN
                            }
                        ]}
                    >
                        <Input type="text" />
                    </Form.Item>
                </Col>
            </Row>

            <Row 
                justify="space-between"
              
            >
                <Col {...columnOptions}>
                    <Form.Item
                        name="email"
                        label="E-mail"
                        rules={[
                            {
                                required: true,
                                message: INSERT_EMAIL
                            }
                        ]}
                    >
                        <Input type="email" />
                    </Form.Item>
                </Col>

                <Col {...columnOptions}>
                    <Form.Item
                        name="phone"
                        label="Telefone"
                        rules={[
                            {
                                required: true,
                                message: INSERT_PHONE
                            }
                        ]}
                    >
                        <InputNumber
                            controls={false}
                            onKeyPress={(event) => {
                                if (/[a-zA-Z]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            style={{
                                width: "100%"
                            }}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={[4, 4]}>
                <Col span="24">
                    <Form.Item
                        name="notes"
                        label="Anotações"
                        rules={[
                            {
                                required: true,
                                message: INSERT_NOTES
                            },
                        ]}
                    >
                        <Input.TextArea
                            resource={5}
                        ></Input.TextArea>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
}

export default RepresentativeForm;