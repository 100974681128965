import { useState } from "react";

import { Button, Col, Form, Row } from "antd";

import InteractionCommentForm from "../../Form";
import InteractionCommentModal from "..";

function InteractionCommentModalWithForm({
    open,
    commentUID,
    formConfig,
    onCancel,
    onSubmitFinishSuccess
}) {
    const [comment, setComment] = useState(null);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const title = comment ?
        "Edição de Comentário" :
        "Cadastro de Comentário"

    const onClickSave = async () => {
        try {
            await form.validateFields();
            form.submit();
            setLoading(true);
        } catch (_err) {
            setLoading(false);
        }
    };

    const onLoadComment = (commentData) => {
        setComment(commentData);
    };

    return (
        <InteractionCommentModal
            open={open}
            commentUID={commentUID}
            onLoadComment={onLoadComment}
            onCancel={() => {
                onCancel();

                form.resetFields();
            }}
            title={title}
            footer={(
                <Row justify="end">
                    <Col>
                        <Button
                            type="link"
                            onClick={() => {
                                onCancel();

                                form.resetFields();
                            }}
                        >
                            Cancelar
                        </Button>
                    </Col>

                    <Col>
                        <Button
                            type="primary"
                            loading={loading}
                            onClick={onClickSave}
                        >
                            Salvar
                        </Button>
                    </Col>
                </Row>
            )}
        >
            <InteractionCommentForm
                form={form}
                commentUID={commentUID}
                onSubmitFinishSuccess={() => {
                    setLoading(false);
                    onSubmitFinishSuccess();

                    form.resetFields();
                }}
                onSubmitFinishFail={() => {
                    setLoading(false);
                }}
                {...({
                    ...formConfig,
                    valuesDefault: {
                        ...formConfig?.valuesDefault,
                        ...comment
                    }
                })}
            />
        </InteractionCommentModal>
    );
}

export default InteractionCommentModalWithForm;