import {
    Col,
    Row,
    Layout,
    Typography,
    FloatButton,
    Button
} from "antd";
import { FaPlus, FaRegBuilding } from "react-icons/fa";
import { useRef, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import TechnologyList from "../../../components/Technology/List";
import TechnologyModalWithForm from "../../../components/Technology/Modal/WithForm";
import TechnologySelectMult from "../../../components/Technology/Select/Mult";

function Technologies({

}) {

    const [openTechnologyModalWithForm, setOpenTechnologyModalWithForm] = useState(false);
    const technologyListref = useRef(null);
    const [filters, setFilters] = useState({});

    return (
        <Layout.Content className="page-content sec-management-responsive">
            <Row justify={"space-between"} align={"middle"}>
                <Col>
                    <Typography.Title
                        level={2}
                    >
                        <FaRegBuilding /> Tecnologias
                    </Typography.Title>
                </Col>
                <Col span="12" className="sec-management-responsive__container">
                    <Row justify="end">
                        <Col className="sec-management-responsive__btn-new">
                            <Button
                                type="primary"
                                onClick={() => {
                                    setOpenTechnologyModalWithForm(true);
                                }}
                                icon={<PlusOutlined />}
                            >
                                Nova Tecnologia
                            </Button>
                        </Col>
                        <Col style={{ width: "500px" }} className="sec-management-responsive__filter">
                            <TechnologySelectMult
                                placeholder={"Buscar por tecnologia"}
                                placement="bottomRight"
                                onChange={(values) => {
                                    setFilters((prev) => ({
                                        ...prev,
                                        uids: values
                                    }))
                                }}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>

            <TechnologyList
                maxColumnNumber={3}
                ref={technologyListref}
                filters={filters}
            />

            <FloatButton
                style={{ border: '2px solid #00B298' }}
                onClick={() => {
                    setOpenTechnologyModalWithForm(true);
                }}
                icon={<FaPlus color="#00B298" />}
            />

            <TechnologyModalWithForm
                open={openTechnologyModalWithForm}
                onCancel={() => {
                    setOpenTechnologyModalWithForm(false);
                }}
                onSubmitSuccess={() => {
                    setOpenTechnologyModalWithForm(false);
                    technologyListref.current && technologyListref.current.onLoadTechnologies();
                }}
            />

        </Layout.Content>
    )

}
export default Technologies;