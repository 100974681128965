import { useEffect, useState } from "react";
import { Col, Pagination, Row, Spin, Empty, notification } from "antd";
import StatusCard from "./StatusCard";
import _service from "@netuno/service-client";
import "./styles.less";

function StatusCardList({
    filters,
    maxColumnNumber,
    formSearch
}) {
    const [status, setStatus] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [statusTotal, setStatusTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [emptyImage, setEmptyImage] = useState(false);
    const pageSize = 10;
    const columnOptions = [
        {
            span: 24
        },
        {
            sm: { span: 24 },
            md: { span: 12 }
        },
        {
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 12 },
            lg: { span: 8 }
        },
    ];

    const onPageChange = (page) => {
        setCurrentPage(page);
    }

    const loadStatus = () => {
        setLoading(true);
        _service({
            url: "status/list",
            method: "POST",
            data: {
                filters,
                pagination: {
                    page: currentPage,
                    size: pageSize
                }
            },
            success: (res) => {
                setLoading(false);
                if (res.json) {
                    setStatus(res.json.status);
                    setStatusTotal(res.json.status_total);
                    setEmptyImage(res.json.status_total > 0 ? false : true);
                }
            },
            fail: (err) => {
                setLoading(false);
                setEmptyImage(true);
            }
        });

    };

    useEffect(() => {
        if (currentPage !== 1) {
            setCurrentPage(1);

            return;
        }

        loadStatus();
    }, [filters]);

    useEffect(() => {
        loadStatus();
    }, [currentPage]);

    if (loading) {
        return (
            <Row
                className="loading"
            >

                <Spin
                    tip="Aguarde..."
                    size="large"
                >
                    <div className="content" />
                </Spin>
            </Row>
        )
    }

    if (emptyImage) {
        return (
            <Row
                span={24}
                align="middle"
                className="div_empty_result"
            >
                <Empty
                    description={
                        <span>
                            Pesquisa não encontrada
                        </span>
                    }
                />
            </Row>
        )
    }

    return (
        <>
            {
                statusTotal > 0 && (
                    <Row
                        align="middle"
                        justify="center"
                        className="status-card-list-pagination-top">
                        <Col>
                            <Pagination
                                total={statusTotal}
                                onChange={onPageChange}
                                current={currentPage}
                                pageSize={pageSize}
                            />
                        </Col>
                    </Row>
                )
            }
            <Row
                className="status-card-list"
                gutter={[12, 12]}
                justify="start"
                align="top"
            >
                {status.length > 0 && status.map((status) => (
                    <Col key={status.uid} {...columnOptions[maxColumnNumber - 1]}>
                        <StatusCard
                            status={status}
                            formSearch={formSearch}
                            onToggleActive={(active, uid) => {
                                setStatus((prev) => {
                                    return prev.map((stt) => {
                                        if (stt.uid === uid) {
                                            return ({
                                                ...stt,
                                                active
                                            });
                                        }

                                        return stt;
                                    });
                                });
                            }}
                        />
                    </Col>
                ))}
            </Row>
            {
                statusTotal > 0 && (
                    <Row
                        align="middle"
                        justify="center"
                        className="status-card-list-pagination-bottom">
                        <Col>
                            <Pagination
                                total={statusTotal}
                                onChange={onPageChange}
                                current={currentPage}
                                pageSize={pageSize}
                            />
                        </Col>
                    </Row>
                )
            }
        </>
    );
}

export default StatusCardList;
