import { useEffect, useState } from "react";

import { Select } from "antd";

import _service from "@netuno/service-client";

import { failToLoadOptionsNotification } from "../../../../utils";

let timerSearch = null;

function SectorSelectOne({
    disabled,
    value,
    onChange,
    ...selectOptions
}) {
    const [sectors, setSectors] = useState([]);
    const [filters, setFilters] = useState({
        active: true
    });
    const [loading, setLoading] = useState(false);

    const loadSectors = () => {
        setLoading(true);

        _service({
            url: "sector/list",
            method: "POST",
            data: {
                filters
            },
            success: (res) => {
                setSectors(res.json.sectors);

                setLoading(false);
            },
            fail: () => {
                failToLoadOptionsNotification("Setores");

                setLoading(true);
            }
        });
    };

    useEffect(() => {
        if (!disabled) {
            loadSectors();
        }
    }, [filters]);

    return (
        <Select
            showSearch
            disabled={disabled}
            optionFilterProp="label"
            loading={loading}
            value={value}
            options={sectors.map((sector) => ({
                label: sector.name,
                value: sector.uid
            }))}
            onChange={(newValue) => {
                onChange(newValue);

                setFilters({
                    name: ""
                });
            }}
            onSearch={(search) => {
                if (timerSearch) {
                    clearTimeout(timerSearch);
                }

                timerSearch = setTimeout(() => {
                    setFilters((prev) => ({
                        ...prev,
                        name: search
                    }));
                }, 500);
            }}
            onDropdownVisibleChange={(isOpen) => {
                if (!isOpen && filters.name) {
                    setFilters((prev) => ({
                        ...prev,
                        name: ""
                    }));
                }
            }}
            style={{
                width: "100%"
            }}
            {...selectOptions}
        />
    );
}

export default SectorSelectOne;
