import {
    useState
} from "react";

import {
    Card,
    Row,
    Col,
    Button,
    Typography,
    Switch,
    Tooltip,
    notification
} from "antd";
import {
    EditOutlined,
    DeleteOutlined
} from "@ant-design/icons";

import ContactTypeModalWithForm from "../../ContactType/Modal/WithForm";
import _service from "@netuno/service-client";

import "./styles.less";

function TypeOfContactCard({
    contactType,
    formSearch,
    onActive
}) {
    const [modalOpenEdit, setModalOpenEdit] = useState(false);
    const [loading, setLoading] = useState(false);

    const onActivecontactType = () => {
        setLoading(true);

        _service({
            url:"interaction/contact_type",
            method:"PUT",
            data:{
                ...contactType,
                active:!contactType.active
            },
            success: (res) => {
                setLoading(false);
                onActive(!contactType.active, contactType.uid);
                notification.success({
                    message:`${contactType.active ? "Desativar" : "Ativar"} tipo de contato.`,
                    description:`Tipo de contato ${contactType.active ? "desativado" : "ativado"} com sucesso!`
                })
            },
            fail: (err) => {
                setLoading(false);
                notification.error({
                    message:`${contactType.active ? "Desativar" : "Ativar"} tipo de contato.`,
                    description:`Falha ao ${contactType.active ? "desativar" : "ativar"} tipo de contato!`
                })
            }
        })
    }

    return (
        <Card
            className="typeOfContact-card"
            size="small"
        >
            <Row
                align="middle"
                justify="space-between"
            >
                <Col >
                    <Typography.Title
                        level={5}
                        style={{
                            margin: 0
                        }}
                    >
                        {contactType.name}
                    </Typography.Title>
                </Col>

                <Col className="col__btns" >
                    <Tooltip title={`${contactType.active ? "Desativar" : "Ativar"} tipo de contato.`} >
                        <Switch
                            defaultChecked={contactType.active}
                            size='small'
                            onClick={onActivecontactType}
                            loading={loading}
                        />
                    </Tooltip>

                    <Button type="link" icon={<EditOutlined />}
                        title="Editar"
                        disabled={!contactType.active}
                        onClick={() => {
                            setModalOpenEdit(true);
                        }}
                    />
                    <ContactTypeModalWithForm
                        open={modalOpenEdit}
                        contactTypeUID={contactType.uid}
                        onCancel={() => {
                            setModalOpenEdit(false);
                        }}
                        onSubmitFinishSuccess={() => {
                            setModalOpenEdit(false);

                            formSearch.submit();
                        }}
                    />
                </Col>
            </Row>
        </Card >
    );
}

export default TypeOfContactCard;