import {
    useState
} from "react";

import {
    Card,
    Row,
    Col,
    Button,
    Typography,
    Switch,
    Tooltip,
    notification
} from "antd";
import {
    EditOutlined
} from "@ant-design/icons";

import ContactMeanModalWithForm from "../../ContactMean/Modal/WithForm";
import _service from "@netuno/service-client";

import "./styles.less";

function MeansOfContactCard({
    contactMean,
    formSearch,
    onActive
}) {
    const [openContactMean, setOpenContactMeanModalWithForm] = useState(false);
    const [loading, setLoading] = useState(false);

    const onActiveContactMean = () => {
        setLoading(true);

        _service({
            url:"interaction/contact_mean",
            method:"PUT",
            data:{
                ...contactMean,
                active:!contactMean.active
            },
            success: (res) => {
                setLoading(false);
                onActive(!contactMean.active,contactMean.uid);
                notification.success({
                    message:`${contactMean.active ? "Desativar" : "Ativar"} meio de contato.`,
                    description:`Meio de contato ${contactMean.active ? "desativado" : "ativado"} com sucesso!`
                })
            },
            fail: (err) => {
                setLoading(false);
                notification.error({
                    message:`${contactMean.active ? "Desativar" : "Ativar"} meio de contato.`,
                    description:`Falha ao ${contactMean.active ? "desativar" : "ativar"} Meio de contato!`
                })
            }
        })
    }

    return (
        <Card
            className="meansOfContact-card"
            size="small"
        >
            <Row
                align="middle"
                justify="space-between"
            >
                <Col >
                    <Typography.Title
                        level={5}
                        style={{
                            margin: 0
                        }}
                    >
                        {contactMean.name}
                    </Typography.Title>
                </Col>
                <Col className="col__btns" >

                    <Tooltip title={`${contactMean.active ? "Desativar" : "Ativar"} meio de contato.`} >
                        <Switch
                            defaultChecked={contactMean.active}
                            size='small'
                            onClick={onActiveContactMean}    
                            loading={loading}
                        />
                    </Tooltip>
                    <Button type="link" icon={<EditOutlined />}
                        title="Editar"
                        disabled={!contactMean.active}
                        onClick={() => {
                            setOpenContactMeanModalWithForm(true);
                        }}
                    />
                    <ContactMeanModalWithForm
                        open={openContactMean}
                        contactMeanUID={contactMean.uid}
                        onCancel={() => {
                            setOpenContactMeanModalWithForm(false);
                        }}
                        onSubmitFinishSuccess={() => {
                            setOpenContactMeanModalWithForm(false);

                            formSearch.submit();
                        }}
                    />
                </Col>
            </Row>
        </Card >
    );
}

export default MeansOfContactCard;