import {
    Row,
    notification,
    Empty,
    Spin,
    Pagination,
    Col
} from "antd";
import _service from "@netuno/service-client";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import ExperienceTimeCardList from "./CardList";

function ExperienceTimeList({
    maxColumnNumber,
    filters
}, ref) {

    const columnOptions = [
        {
            span: 24
        },
        {
            sm: { span: 24 },
            md: { span: 12 }
        },
        {
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 12 },
            lg: { span: 8 }
        },
    ];

    const [experiencesTime, setExperiencesTime] = useState([]);
    const [experiencesTimeTotal, setExperiencesTimeTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [emptyImage, setEmptyImage] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10;

    const onChangePagination = (page) => {
        setCurrentPage(page);
    }

    const ExperienceTimePagination = () => {
        return (
            <Row justify="center"
            >
                <Col>
                    <Pagination
                        current={currentPage}
                        pageSize={pageSize}
                        total={experiencesTimeTotal}
                        onChange={onChangePagination}
                        style={{
                            margin: "12px 0px"
                        }}
                    />
                </Col>
            </Row>
        )
    }



    const onLoadExperiencesTime = () => {
        setLoading(true);
        _service({
            url: "candidate/experience_time/list",
            method: "POST",
            data: {
                pagination:{pageSize, page:currentPage},
                filters:{
                    ...filters
                }
            },
            success: (res) => {
                setLoading(false);
                if (res.json) {
                    setEmptyImage(res.json.experiences_time_total > 0 ? false : true);
                    setExperiencesTime(res.json.experiences_time);
                    console.log(res)
                    setExperiencesTimeTotal(res.json.experiences_time_total);
                }
            },
            fail: (err) => {
                setLoading(false);
                setEmptyImage(true);
                notification.error({
                    message: "Carregar Experiências",
                    description: "falha ao carregar experiências"
                });
            }
        })
    }

    const onClickActive = (uid, active) => {
         setExperiencesTime((prev) => prev.map((experience)=>{
            if (experience.uid === uid) {
                return({
                    ...experience,
                    active
                })
            }
            return experience;
         }))   
    }

    useImperativeHandle(ref, () =>({
        onLoadExperiencesTime
    }))

    useEffect(() => {
        onLoadExperiencesTime();
    }, [currentPage])

    useEffect(()=>{
        if (currentPage === 1) {
            onLoadExperiencesTime();
            return;
        }
        setCurrentPage(1);
    },[filters])

    if (loading) {
        return (
            <Row
                className="loading"
            >

                <Spin
                    tip="Aguarde..."
                    size="large"
                >
                    <div className="content" />
                </Spin>
            </Row>
        )
    }

    if (emptyImage) {
        return (
            <Row
                span={24}
                align="middle"
                className="div_empty_result"
            >
                <Empty
                    description={
                        <span>
                            Pesquisa não encontrada
                        </span>
                    }
                />
            </Row>
        )
    }

    return (
        <>
            {
                experiencesTimeTotal > 0 && (
                    <Row justify={"center"} align={"middle"}>
                        <Col span={24}>
                            <ExperienceTimePagination />
                        </Col>
                    </Row>
                )
            }
            <Row
                gutter={[12, 12]}
                justify="start"
                align="top"
            >
                {
                    experiencesTime.length > 0 && experiencesTime.map((experience, index) => (
                        <Col {...columnOptions[maxColumnNumber - 1]} key={index}>
                            <ExperienceTimeCardList
                                experienceTime={experience}
                                onClickActive={onClickActive}
                                onLoadExperienceTime={onLoadExperiencesTime}
                            />
                        </Col>
                    ))
                }
            </Row>
            {
                experiencesTimeTotal > 0 && (
                    <Row style={{ marginTop: "auto", paddingTop: "12px" }}>
                        <Col span={24}>
                            <ExperienceTimePagination />
                        </Col>
                    </Row>
                )
            }

        </>
    )

}

export default forwardRef(ExperienceTimeList);