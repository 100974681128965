import {
    useState
} from "react";

import {
    Button,
    Col,
    Form,
    Row
} from "antd";

import StatusModal from "..";
import StatusForm from "../../Form";

function StatusModalWithForm({
    open,
    statusUID,
    formConfig,
    onCancel,
    onSubmitFinishSuccess
}) {
    const [status, setStatus] = useState(null);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const title = statusUID ?
        "Edição de Status" :
        "Cadastro de Status";

    const onClickSave = async () => {
        try {
            await form.validateFields();
            form.submit();
            setLoading(true);
        } catch (_err) {
            setLoading(false);
        }
    };

    const onLoadStatus = (newStatus) => {
        setStatus(newStatus);
    };

    return (
        <StatusModal
            open={open}
            statusUID={statusUID}
            onLoadStatus={onLoadStatus}
            onCancel={() => {
                onCancel();
                form.resetFields();
            }}
            title={title}
            footer={(
                <Row
                    align="middle"
                    justify="end"
                >
                    <Col>
                        <Button
                            type="link"
                            onClick={() => {
                                onCancel();
                                form.resetFields();
                            }}
                        >
                            Cancelar
                        </Button>
                    </Col>

                    <Col>
                        <Button
                            type="primary"
                            loading={loading}
                            onClick={onClickSave}
                        >
                            Salvar
                        </Button>
                    </Col>
                </Row>
            )}
        >
            <StatusForm
                form={form}
                statusUID={statusUID}
                onSubmitFinishSuccess={() => {
                    setLoading(false);
                    onSubmitFinishSuccess()
                }}
                onSubmitFinishFail={() => {
                    setLoading(false);
                }}
                {...({
                    ...formConfig,
                    valuesDefault: {
                        ...formConfig?.valuesDefault,
                        ...status
                    }
                })}
            />
        </StatusModal>
    );
}

export default StatusModalWithForm;