import {
    useEffect,
    useState
} from "react";

import {
    Col,
    Pagination,
    Row,
    Spin,
    Empty
} from "antd";

import _service from "@netuno/service-client";

import MeansOfContactCard from "./MeansOfContactCard";

import "./styles.less";

function MeansOfContactCardList({
    filters,
    maxColumnNumber,
    formSearch
}) {
    const [meansOfContacts, setMeansOfContacts] = useState([]);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [emptyImage, setEmptyImage] = useState(false);
    const pageSize = 10;
    const columnOptions = [
        {
            span: 24
        },
        {
            sm: { span: 24 },
            md: { span: 12 }
        },
        {
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 12 },
            lg: { span: 8 }
        },
    ];

    const onPageChange = (page) => {
        setPage(page);
    }

    const onActive = (active,uid) => {
        setMeansOfContacts((prev) => prev.map((contactMean)=>{
            if(contactMean.uid === uid){
                return({
                    ...contactMean,
                    active
                })
            }
            return contactMean;
        }))
    }

    const loadContactMeans = () => {
        setLoading(true);
        _service({
            url: "interaction/contact_mean/list",
            method: "POST",
            data: {
                filters: filters,
                pagination: {
                    size: pageSize,
                    page
                }
            },
            success: (res) => {
                setLoading(false);
                if (res.json) {
                    setMeansOfContacts(res.json.contact_means);
                    setTotal(res.json.contact_means_total);
                    setEmptyImage(res.json.contact_means_total > 0 ? false : true);
                }
            },
            fail: (err) => {
                setLoading(false);
                setEmptyImage(true);
                console.error(err);
            }
        });
    };

    useEffect(() => {
        if (page !== 1) {
            setPage(1);

            return;
        }

        loadContactMeans();
    }, [filters]);

    useEffect(() => {
        loadContactMeans();
    }, [page]);

    if (loading) {
        return (
            <Row
                className="loading"
            >

                <Spin
                    tip="Aguarde..."
                    size="large"
                >
                    <div className="content" />
                </Spin>
            </Row>
        )
    }

    if (emptyImage) {
        return (
            <Row
                span={24}
                align="middle"
                className="div_empty_result"
            >
                <Empty
                    description={
                        <span>
                            Pesquisa não encontrada
                        </span>
                    }
                />
            </Row>
        )
    }

    return (
        <>
            {
                total > 0 && (
                    <Row
                        align="middle"
                        justify="center"
                        className="meansOfContact-card-list-pagination-top">
                        <Col>
                            <Pagination
                                total={total}
                                onChange={onPageChange}
                                current={page}
                                pageSize={pageSize}
                            />
                        </Col>
                    </Row>
                )
            }
            <Row
                className="meansOfContact-card-list"
                gutter={[12, 12]}
                justify="start"
                align="top"
            >
                {meansOfContacts.length > 0 && meansOfContacts.map((means_of_contacts) => (
                    <Col key={means_of_contacts.uid} {...columnOptions[maxColumnNumber - 1]}>
                        <MeansOfContactCard
                            contactMean={means_of_contacts}
                            formSearch={formSearch}
                            onActive={onActive}
                        />
                    </Col>
                ))}
            </Row>
            {
                total > 0 && (
                    <Row
                        align="middle"
                        justify="center"
                        className="meansOfContact-card-list-pagination-bottom">
                        <Col>
                            <Pagination
                                total={total}
                                onChange={onPageChange}
                                current={page}
                                pageSize={pageSize}
                            />
                        </Col>
                    </Row>
                )
            }
        </>
    );
}

export default MeansOfContactCardList;