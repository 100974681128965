import {
    useEffect,
    useState
} from "react";

import {
    Col,
    notification,
    Row,
    Spin
} from "antd";

import _service from "@netuno/service-client";

import ModalCentered from "../../Modal/Centered";

function ServiceModal({
    open,
    serviceUID,
    title,
    footer,
    onLoadservice,
    children,
    ...modalProps
}) {
    const [loading, setLoading] = useState(false);

    const loadService = () => {
        setLoading(true);

        _service({
            url: "service",
            data: {
                uid: serviceUID
            },
            success: (res) => {
                onLoadservice(res.json.service);
                setTimeout(() => setLoading(false), 250);
            },
            fail: (err) => {
                console.log(err)
                notification.error({
                    message: "Serviço",
                    description: "Falha ao carregar dados do serviço."
                });
            }
        });
    };

    useEffect(() => {
        if (open && serviceUID) {
            loadService();
        }
    }, [open]);

    return (
        <ModalCentered
            open={open}
            title={!loading && title}
            footer={!loading && footer}
            {...modalProps}
        >
            {
                loading ? (
                    <Row
                        align="middle"
                        justify="center"
                        className="loading"
                    >
                        <Col span="24">
                            <Spin
                                tip="Aguarde..."
                                size="large"
                            >
                                <div className="content" />
                            </Spin>
                        </Col>
                    </Row>
                ) : children
            }
        </ModalCentered>
    );
}

export default ServiceModal;