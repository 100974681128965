import {
    Card,
    Col,
    Row,
    Typography,
    Switch,
    Tooltip,
    Button,
    notification
} from "antd";

import { EditOutlined } from "@ant-design/icons";
import "./styles.less"
import { useState } from "react";
import _service from "@netuno/service-client";
import ExperienceTimeModalWithForm from "../../Modal/WithForm";

function ExperienceTimeCardList({
    experienceTime,
    onClickActive,
    onLoadExperienceTime
}) {

    const [loading, setLoading] = useState(false);
    const [openExperienceTimeModalWithForm, setOpenExperienceTimeModalWithForm] = useState(false);

    const onActiveExperienceTime = () => {
        setLoading(true);
        _service({
            url:"candidate/experience_time",
            method:"PUT",
            data:{
                ...experienceTime,
                active:!experienceTime.active
            },
            success: (res) =>{
                setLoading(false);
                notification.success({
                    message:`${experienceTime.active ? "Desativar" : "Ativar"} experiência`,
                    description:`Experiência ${experienceTime.active ? "desativada" : "ativada"} com sucesso`
                })
                onClickActive(experienceTime.uid, !experienceTime.active);
            }, fail: (err) => {
                setLoading(false);
                notification.error({
                    message:`${experienceTime.active ? "Desativar" : "Ativar"} experiência`,
                    description:`Falha ao ${experienceTime.active ? "desativar" : "ativar"} experiência`
                })
            }
        })
    }

    return (
        <Card
            size="small"
            className="experience-card"
        >
            <Row justify={"space-between"} align={"middle"}>
                <Col>
                    <Typography.Title
                        level={5}
                        style={{
                            margin: 0
                        }}
                    >
                        {experienceTime.name}
                    </Typography.Title>
                </Col>
                <Col className="col__btns">
                    <Tooltip
                        title={`${experienceTime.active ? "Desativar" : "Ativar"} experiência`}
                    >
                        <Switch
                            loading={loading}
                            defaultChecked={experienceTime.active}
                            size='small'
                            onClick={onActiveExperienceTime}
                        />
                    </Tooltip>
                    
                    <Button type="link" icon={<EditOutlined />}
                        title="Editar"
                        disabled={!experienceTime.active}
                        onClick={()=>{
                            setOpenExperienceTimeModalWithForm(true);
                        }}
                    />
                </Col>
            </Row>
            <ExperienceTimeModalWithForm
                open={openExperienceTimeModalWithForm}
                experienceTimeUID={experienceTime.uid}
                onCancel={()=>{
                    setOpenExperienceTimeModalWithForm(false);
                }}
                onSubmitSuccess={()=>{
                    setOpenExperienceTimeModalWithForm(false);
                    onLoadExperienceTime();
                }}
            />
        </Card>
    )
}
export default ExperienceTimeCardList;