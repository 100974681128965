import { useEffect, useState } from "react";

import { notification, Spin, Row, Col } from "antd";

import _service from "@netuno/service-client";

import ModalCentered from "../../Modal/Centered";

function InteractionModal({
  open,
  interactionUID,
  onLoadInteraction,
  children,
  ...modalProps
}) {
  const [loading, setLoading] = useState(false);

  const loadInteraction = () => {
    setLoading(true);

    _service({
      url: "interaction",
      data: {
        uid: interactionUID
      },
      success: ({ json }) => {
        if (json) {
          onLoadInteraction(json.interaction);
          setLoading(false);
        }
      },
      fail: () => {
        setLoading(false);
        notification.error({
          message: "Interação",
          description: "Falha ao tentar carregar dados da interação"
        });
      }
    });
  };

  useEffect(() => {
    if (open) {
      loadInteraction();
    }
  }, [open]);

  return (
    <ModalCentered
      open={open}
      {...modalProps}
    >
      {
        loading ? (
          <Row align="middle" justify="center" style={{ height: "100%" }}>
            <Col span="24">
              <Spin
                tip="Aguarde..."
                size="large"
              >
                <div className="content" />
              </Spin>
            </Col>
          </Row>
        ) : children
      }
    </ModalCentered>
  );
}

export default InteractionModal;