import {
    useEffect,
    useState,
    forwardRef,
    useImperativeHandle
} from "react";

import {
    Select
} from "antd";

import _service from "@netuno/service-client";

import {
    failToLoadOptionsNotification
} from "../../../../utils";

let timerSearch = null;

function RepresentativeSelectOne({
    companiesUIDs,
    disabled,
    value,
    onChange
}, ref) {
    const [representatives, setRepresentatives] = useState([]);
    const [filters, setFilters] = useState({});
    const [loading, setLoading] = useState(false);

    const loadRepresentatives = () => {
        let finalFilters = {
            ...filters
        };
        setLoading(true);

        if (companiesUIDs) {
            finalFilters.companies_uids = companiesUIDs
        }

        _service({
            url: "representative/option/list",
            method: "POST",
            data: {
                filters: finalFilters
            },
            success: (res) => {
                setRepresentatives(res.json.representatives);

                setLoading(false);
            },
            fail: () => {
                failToLoadOptionsNotification("Representantes");

                setLoading(true);
            }
        });
    };

    useImperativeHandle(ref, () => ({
        loadRepresentatives
    }));

    useEffect(() => {
        if (!disabled) {
            loadRepresentatives();
        }
    }, [filters, companiesUIDs]);

    return (
        <Select
            showSearch
            optionFilterProp="label"
            placeholder="Representantes"
            disabled={disabled || loading}
            loading={loading}
            value={value}
            options={representatives.map((representative) => ({
                label: representative.name,
                value: representative.uid
            }))}
            onChange={(newValue) => {
                onChange(newValue);

                setFilters((prev) => ({
                    ...prev,
                    name: ""
                }));
            }}
            onSearch={(search) => {
                if (timerSearch) {
                    clearTimeout(timerSearch);
                }

                timerSearch = setTimeout(() => {
                    setFilters((prev) => ({
                        ...prev,
                        name: search
                    }));
                }, 500);
            }}
            onDropdownVisibleChange={(isOpen) => {
                if (!isOpen && filters.name) {
                    setFilters((prev) => ({
                        ...prev,
                        name: ""
                    }));
                }
            }}
            style={{
                width: "100%"
            }}
        />
    );
}

export default forwardRef(RepresentativeSelectOne);
