import {
    Select,
    notification
} from "antd";
import { useEffect, useState } from "react";
import _service from "@netuno/service-client";

let timerSearch = null;

function FileTypeSelectOne ({
    value,
    ...selectOptions
}) {

    const [fileTypes, setFileTypes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({});

    const onLoadFileTypes = () => {
        setLoading(true);
        _service({
            url:"candidate/file_type/list",
            data:{
                filters:{
                    ...filters,
                    active: true
                }
            },
            method:"POST",
            success:(res) => {
                setLoading(false);
                setFileTypes(res.json.file_types);
            },
            fail:(err) => {
                setLoading(false);
                notification.error({
                    message:"Carregar tipos de arquivo.",
                    description:"Falha ao carregar tipos de arquivo!"
                })
            } 
        })
    }

    useEffect(() => {
        onLoadFileTypes();
    },[filters])
    
    return(
        <Select
            showSearch
            options={fileTypes.map((fileType)=>({
                label:fileType.name,
                value:fileType.uid
            }))}
            value={value}
            optionFilterProp="label"
            loading={loading}
            allowClear={!loading}
            onSearch={(search) => {
                if (timerSearch) {
                    clearTimeout(timerSearch);
                }

                timerSearch = setTimeout(() => {
                    setFilters((prev) => ({
                        ...prev,
                        name: search
                    }));
                }, 500);
            }}
            onDropdownVisibleChange={(isOpen) => {
                if (!isOpen && filters.name) {
                    setFilters(() => ({
                        name: ""
                    }));
                }
            }}
            {...selectOptions}
        />
    )
}

export default FileTypeSelectOne;