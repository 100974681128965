import { useState } from "react";

import { Card, Row, Col, Button, notification, Typography, Switch, Tooltip } from "antd";
import { EditOutlined } from "@ant-design/icons";

import ServiceModalWithForm from "../../Service/Modal/WithForm";

import "./styles.less";
import _service from "@netuno/service-client";

function ServiceCard({
    service,
    formSearch,
    onToggleActive
}) {
    const [modalOpenEdit, setModalOpenEdit] = useState(false);
    const [loadingToggleActive, setLoadingToggleActive] = useState(false);

    const onToggleModalEdit = () => {
        setModalOpenEdit(!modalOpenEdit);
    };

    const toggleActive = () => {
        setLoadingToggleActive(true);

        _service({
            url: "service",
            method: "PUT",
            data: {
                ...service,
                active: !service.active
            },
            success: () => {
                setLoadingToggleActive(false);
                notification.success({
                    message: `${service.active ? "Desativar" : "Ativar"} Serviço`,
                    description: `Serviço ${service.active ? "desativado" : "ativado"} com sucesso.`
                });

                onToggleActive(!service.active, service.uid);
            },
            fail: () => {
                setLoadingToggleActive(false);
                notification.error({
                    message: `${service.active ? "Desativar" : "Ativar"} Serviço`,
                    description: `Falha ao tentar ${service.active ? "desativar" : "ativar"} o serviço.`
                });
            }
        });
    };

    return (
        <Card
            className="service-card"
            size="small"
        >
            <Row
                align="middle"
                justify="space-between"
            >
                <Col>
                    <Typography.Title
                        level={5}
                        style={{
                            margin: 0
                        }}
                    >
                        {service.name}
                    </Typography.Title>
                </Col>
                <Col>
                    <Row align="middle" gutter={[12, 0]}>
                        <Col>
                            <Tooltip
                                title={`${service.active ? "Desativar" : "Ativar"} status.`}
                            >
                                <Switch
                                    size="small"
                                    loading={loadingToggleActive}
                                    checked={service.active}
                                    onClick={toggleActive}
                                />
                            </Tooltip>
                        </Col>

                        <Col>
                            <Button type="link" icon={<EditOutlined />}
                                title="Editar"
                                onClick={onToggleModalEdit}
                                disabled={!service.active}
                            />

                            <ServiceModalWithForm
                                open={modalOpenEdit}
                                serviceUID={service.uid}
                                onCancel={() => {
                                    setModalOpenEdit(false);
                                }}
                                onSubmitFinishSuccess={() => {
                                    setModalOpenEdit(false);

                                    formSearch.submit();
                                }}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Card >
    );
}

export default ServiceCard;
