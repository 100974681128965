import { useState, useRef, useEffect } from "react";

import { Col, Form, Row, Button, Input, Tooltip, Checkbox, Switch } from "antd";

import { FilterFilled } from "@ant-design/icons";

import _service from "@netuno/service-client";

import ModalCentered from "../../Modal/Centered";
import CompanySelectMultiple from "../../Company/Select/Multiple";
import RepresentativeSelectMultiple from "../../Representative/Select/Multiple";
import ContactTypeSelectMultiple from "../../ContactType/Select/Multiple";
import ContactMeanSelectMultiple from "../../ContactMean/Select/Multiple";

import "./styles.less";

function InteractionFilter({
    formConfig,
    onFilter
}) {
    const [openAdvancedFilter, setOpenAdvancedFilter] = useState(false);
    const [form] = Form.useForm();
    const companiesUIDs = Form.useWatch("companies_uids", form);
    const active = Form.useWatch("active", form);
    const filterActive = Form.useWatch("filter_active", form);
    const representativeSelectRef = useRef(null);

    const columnOptions = {
        xs: { span: 24 },
        sm: { span: 11 }
    }

    let companies = [];


    if (formConfig?.initialValues?.companies_uids) {
        companies = formConfig?.initialValues?.companies_uids.map((companyUID) => {
            if (typeof (companyUID) === "object") {
                return companyUID.value;
            }

            return companyUID;
        });
    }

    const onFinish = (values) => {
        Object.keys(values).forEach((key) => {
            if (Array.isArray(values[key])) {
                values[key] = values[key].map((value) => {
                    if (typeof (value) === "object") {
                        return value.value;
                    }

                    return value;
                });
            }
        });

        onFilter(values);
    };

    useEffect(() => {
        if (!formConfig?.disabled?.representatives_uids) {
            representativeSelectRef?.current?.loadRepresentatives();
            form.setFieldValue("representatives_uids", []);
        }
    }, [companiesUIDs]);

    return (
        <Row
            align="middle"
            justify="end"
            wrap={false}
            gutter={[12, 0]}
            style={{ width: "100%" }}
        >
            <Col>
                <Tooltip
                    title="Filtro avançado"
                >
                    <Button
                        type="link"
                        onClick={() => {
                            setOpenAdvancedFilter(true);
                        }}
                        style={{
                            textAlign: "end"
                        }}
                        icon={<FilterFilled />}
                    />
                </Tooltip>
                <ModalCentered
                    open={openAdvancedFilter}
                    title="Filtro Avançado"
                    destroyOnClose={false}
                    width="90%"
                    onCancel={() => {
                        setOpenAdvancedFilter(false);
                    }}
                    footer={(
                        <Row
                            align="middle"
                            justify="end"
                        >
                            <Col>
                                <Button
                                    type="link"
                                    onClick={() => {
                                        setOpenAdvancedFilter(false);
                                    }}
                                >
                                    Cancelar
                                </Button>
                            </Col>
                            <Col>
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        setOpenAdvancedFilter(false);

                                        form.submit();
                                    }}
                                >
                                    Filtrar
                                </Button>
                            </Col>
                        </Row>
                    )}
                    style={{
                        maxWidth: "600px"
                    }}
                >
                    <Form
                        className="interaction-advanced-filter"
                        form={form}
                        layout="vertical"
                        onFinish={onFinish}
                        initialValues={{
                            ...formConfig?.initialValues,
                            active: true
                        }}
                    >
                        <Row
                            justify="space-between"
                            style={{ width: "100%" }}
                        >
                            <Col {...columnOptions}>
                                <Form.Item
                                    label="Pesquisar Texto"
                                    name="search"
                                >
                                    <Input
                                        allowClear
                                    />
                                </Form.Item>
                            </Col>

                            <Col {...columnOptions}>
                                <Form.Item
                                    label="Empresas"
                                    name="companies_uids"
                                >
                                    <CompanySelectMultiple
                                        disabled={formConfig?.disabled?.companies_uids}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <Form.Item
                                    label="Representantes"
                                    name="representatives_uids"
                                >
                                    <RepresentativeSelectMultiple
                                        ref={representativeSelectRef}
                                        disabled={formConfig?.disabled?.representatives_uids}
                                        companiesUIDs={
                                            companiesUIDs ? companiesUIDs.map((companyUID) => {
                                                if (typeof (companyUID) === "object") {
                                                    return companyUID.value;
                                                }

                                                return companyUID;
                                            }) :
                                                companies
                                        }
                                    />
                                </Form.Item>
                            </Col>

                            <Col {...columnOptions}>
                                <Form.Item
                                    label="Tipos de Contato"
                                    name="contact_types_uids"
                                >
                                    <ContactTypeSelectMultiple
                                        placement="topLeft"
                                    />
                                </Form.Item>
                            </Col>

                            <Col {...columnOptions}>
                                <Form.Item
                                    label="Meios de Contato"
                                    name="contact_means_uids"
                                >
                                    <ContactMeanSelectMultiple
                                        placement="topLeft"
                                    />
                                </Form.Item>
                            </Col>

                            <Col {...columnOptions}>
                                <Row align="middle" gutter={[12, 12]}>
                                    <Col span={24}>
                                        <Form.Item
                                            name="filter_active"
                                            valuePropName="checked"
                                            noStyle
                                        >
                                            <Checkbox>
                                                Filtrar por ativado/desativado
                                            </Checkbox>
                                        </Form.Item>
                                    </Col>

                                    <Col>
                                        <Form.Item
                                            name="active"
                                            valuePropName="checked"
                                            noStyle
                                        >
                                            <Switch size="small" disabled={!filterActive} />
                                        </Form.Item>
                                    </Col>

                                    <Col>
                                        <label
                                            className="interaction-advanced-filter__label"
                                            htmlFor="active"
                                        >
                                            {active ? "Ativado" : "Desativado"}
                                        </label>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </ModalCentered>
            </Col>

            <Col
                style={{
                    flexGrow: 1
                }}
            >
                <Form
                    form={form}
                    onFinish={onFinish}
                    initialValues={{
                        ...formConfig?.initialValues,
                        active: true
                    }}
                >
                    <Form.Item
                        name="search"
                        style={{
                            margin: 0
                        }}
                    >
                        <Input.Search
                            allowClear
                            placeholder="Pesquisar texto"
                            onSearch={() => {
                                form.submit();
                            }}
                        />
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    );
}

export default InteractionFilter;
