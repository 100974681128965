import { useEffect, useState } from "react";

import { Col, Pagination, Row, Spin, Empty, notification } from "antd";

import _service from "@netuno/service-client";

import SectorCard from "./SectorCard";

import "./styles.less";

function SectorCardList({
    filters,
    maxColumnNumber,
    formSearch
}) {
    const [sectors, setSectors] = useState([]);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [emptyImage, setEmptyImage] = useState(false);
    const pageSize = 10;
    const columnOptions = [
        {
            span: 24
        },
        {
            sm: { span: 24 },
            md: { span: 12 }
        },
        {
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 12 },
            lg: { span: 8 }
        },
    ];

    const onPageChange = (page) => {
        setPage(page);
    }

    const loadSectors = () => {
        setLoading(true);
        _service({
            url: "sector/list",
            method: "POST",
            data: {
                filters,
                pagination: {
                    page,
                    size: pageSize,
                }
            },
            success: (res) => {
                setLoading(false);
                if (res.json) {
                    setSectors(res.json.sectors);
                    setTotal(res.json.sectors_total);
                    setEmptyImage(res.json.sectors_total > 0 ? false : true);
                }
            },
            fail: (err) => {
                setLoading(false);
                setEmptyImage(true);
            }
        });
    };

    useEffect(() => {
        if (page !== 1) {
            setPage(1);

            return;
        }

        loadSectors();
    }, [filters]);

    useEffect(() => {
        loadSectors();
    }, [page]);

    if (loading) {
        return (
            <Row
                className="loading"
            >

                <Spin
                    tip="Aguarde..."
                    size="large"
                >
                    <div className="content" />
                </Spin>
            </Row>
        )
    }

    if (emptyImage) {
        return (
            <Row
                span={24}
                align="middle"
                className="div_empty_result"
            >
                <Empty
                    description={
                        <span>
                            Pesquisa não encontrada
                        </span>
                    }
                />
            </Row>
        )
    }

    return (
        <>
            {
                total > 0 && (
                    <Row
                        align="middle"
                        justify="center"
                        className="sector-card-list-pagination-top">
                        <Col>
                            <Pagination
                                total={total}
                                onChange={onPageChange}
                                current={page}
                                pageSize={pageSize}
                            />
                        </Col>
                    </Row>
                )
            }
            <Row
                className="sector-card-list"
                gutter={[12, 12]}
                justify="start"
                align="top"
            >
                {sectors.length > 0 && sectors.map((sector) => (
                    <Col key={sector.uid} {...columnOptions[maxColumnNumber - 1]}>
                        <SectorCard
                            sector={sector}
                            formSearch={formSearch}
                            onToggleActive={(active, uid) => {
                                setSectors((prev) => {
                                    return prev.map((sector) => {
                                        if (sector.uid === uid) {
                                            return ({
                                                ...sector,
                                                active
                                            });
                                        }

                                        return sector;
                                    });
                                });
                            }}
                        />
                    </Col>
                ))}
            </Row>
            {
                total > 0 && (
                    <Row
                        align="middle"
                        justify="center"
                        className="sector-card-list-pagination-bottom">
                        <Col>
                            <Pagination
                                total={total}
                                onChange={onPageChange}
                                current={page}
                                pageSize={pageSize}
                            />
                        </Col>
                    </Row>
                )
            }
        </>
    );
}

export default SectorCardList;
