import _service from "@netuno/service-client";
import { 
    Col, 
    Pagination, 
    Row, 
    notification, 
    Spin,
    Empty 
} from "antd";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import LanguageCardList from "./CardList";

function LanguageList({
    maxColumnNumber,
    filters
}, ref) {

    const columnOptions = [
        {
            span: 24
        },
        {
            sm: { span: 24 },
            md: { span: 12 }
        },
        {
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 12 },
            lg: { span: 8 }
        },
    ];

    const [loading, setLoading] = useState(false);
    const [emptyImage, setEmptyImage] = useState(false);
    const [languages, setLanguages] = useState([]);
    const [languagesTotal, setLanguagesTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10;

    const onChangePagination = (page) => {
        setCurrentPage(page);
    }

    const LanguageListPagination = () => {
        return(
            <Row justify={"center"}>
                <Col>
                    <Pagination
                        pageSize={pageSize}
                        current={currentPage}
                        total={languagesTotal}
                        onChange={onChangePagination}
                        style={{
                            margin: "12px 0px"
                        }}
                    />
                </Col>
            </Row>
        )
    }

    const onLoadLanguages = () => {
        setLoading(true);
        _service({
            url:"candidate/language/list",
            method:"POST",
            data:{
                pagination:{pageSize:pageSize,page:currentPage},
                filters:{
                    ...filters
                }
            },
            success: (res) => {
                setLoading(false);
                setLanguages(res.json.languages);
                setLanguagesTotal(res.json.languages_total);
                setEmptyImage(res.json.languages_total > 0 ? false : true);
            },
            fail:(err) => {
                setEmptyImage(true);
                setLoading(false);
                notification.error({
                    message:"Carregar idiomas",
                    description:"Falha ao carregar idiomas"
                })
            } 
        })
    }

    const onActive = (uid, active) => {
        setLanguages((prev) => prev.map((language)=>{
            if (language.uid === uid) {
                return({
                    ...language,
                    active
                })
            }
            return language;
        }))
    }

    useImperativeHandle(ref, () =>({
        onLoadLanguages
    }))


    useEffect(()=> {
        onLoadLanguages();
    },[currentPage]);

    useEffect(()=> {
        if (currentPage === 1) {
            onLoadLanguages();
            return;
        }
        setCurrentPage(1);
    },[filters]);

    if (loading) {
        return (
            <Row
                className="loading"
            >

                <Spin
                    tip="Aguarde..."
                    size="large"
                >
                    <div className="content" />
                </Spin>
            </Row>
        )
    }

    if (emptyImage) {
        return (
            <Row
                span={24}
                align="middle"
                className="div_empty_result"
            >
                <Empty
                    description={
                        <span>
                            Pesquisa não encontrada
                        </span>
                    }
                />
            </Row>
        )
    }

    return (
        <>
          {
                languagesTotal > 0 && (
                    <Row justify={"center"} align={"middle"}>
                        <Col span={24}>
                            <LanguageListPagination />
                        </Col>
                    </Row>
                )
            }

            <Row
                gutter={[12, 12]}
                justify="start"
                align="top"
            >   
                  {
                    languages.length > 0 && languages.map((language, index)=> (
                        <Col {...columnOptions[maxColumnNumber - 1]} key={index}>
                        < LanguageCardList
                            language={language}
                            onLoadLanguages={onLoadLanguages}
                            onActive={onActive}
                        />
                    </Col>
                    ))
                  }  
            </Row>

            {
                languagesTotal > 0 && (
                    <Row style={{ marginTop: "auto", paddingTop: "12px" }}>
                        <Col span={24}>
                            <LanguageListPagination />
                        </Col>
                    </Row>
                )
            }

        </>
    )
}

export default forwardRef(LanguageList);