import { useEffect, useState } from "react";

import { Select } from "antd";

import _service from "@netuno/service-client";

let timerSearch = null;

function CandidateStatusSelectOne({
    disabled,
    value,
    onChange,
    ...selectOptions
}) {
    const [status, setStatus] = useState([]);
    const [filters, setFilters] = useState({});
    const [loading, setLoading] = useState(false);

    const loadStatus = () => {
        setLoading(true);

        _service({
            url: "candidate/status/list",
            method: "POST",
            data: {
                filters:{
                    ...filters,
                    active: true
                },
            },
            success: (res) => {
                setStatus(res.json.status);

                setLoading(false);
            },
            fail: () => {
                setLoading(false);
            }
        });
    };

    useEffect(() => {
        if (!disabled) {
            loadStatus();
        }
    }, [filters]);

    return (
        <Select
            showSearch
            disabled={disabled}
            optionFilterProp="label"
            loading={loading}
            allowClear={!loading}
            value={value}
            options={status.map((stt) => ({
                label: stt.name,
                value: stt.uid
            }))}
            onChange={(newValue) => {
                onChange(newValue);
            }}
            onSearch={(search) => {
                if (timerSearch) {
                    clearTimeout(timerSearch);
                }

                timerSearch = setTimeout(() => {
                    setFilters((prev) => ({
                        ...prev,
                        name: search
                    }));
                }, 500);
            }}
            onDropdownVisibleChange={(isOpen) => {
                if (!isOpen && filters.name) {
                    setFilters(() => ({
                        name: ""
                    }));
                }
            }}
            style={{
                width: "100%"
            }}
            {...selectOptions}
        />
    );
}

export default CandidateStatusSelectOne;
