import {
    Row,
    Col,
    Card,
    Button,
    Popconfirm,
    Typography
} from "antd";
import { 
    ClockCircleOutlined, 
    CalendarOutlined, 
    FieldTimeOutlined,
    DeleteOutlined,
    EditOutlined
} from '@ant-design/icons';
import dayjs from "dayjs";
import ScheduleModalWithForm from "../../Modal/WthForm";

import { useState } from "react";

function CardSchedule({
    schedule,
    columnConfig,
    onLoadSchedule,
    onClickDelete
}) {

    const [openScheduleModalForm, setOpenScheduleModalForm] = useState(false);

    let daysOfWek = [];

    for (const key of Object.keys(schedule)) {
        if (key.endsWith("day")) {
            if (schedule[key] === true) {
                if (key === "sunday") {
                    daysOfWek.push("Domingo");
                }else if (key === "monday") {
                    daysOfWek.push("Segunda")
                } else if (key === "tuesday") {    
                    daysOfWek.push("Terça");
                } else if (key === "wednesday") {
                    daysOfWek.push("Quarta");
                } else if (key === "thursday") {
                    daysOfWek.push("Quinta");
                } else if (key === "friday") {
                    daysOfWek.push("Sexta");
                } else if (key === "saturday") {
                    daysOfWek.push("Sábado");
                }
            }
        }
    }

    const rows = [
        {
            key: "start",
            icon: <ClockCircleOutlined/>,
            label: "Hora inicial",
            value: `${dayjs(schedule.start, "HH:mm:ss").format("HH:mm")} h`
        },
        {
            key: "end",
            icon: <ClockCircleOutlined/>,
            label: "Hora final",
            value: `${dayjs(schedule.end, "HH:mm:ss").format("HH:mm")} h`
        },
        {
            key: "interval_min",
            icon: <FieldTimeOutlined/>,
            label: "Duração",
            value: `${schedule.interval_min} min`
        },
        {
            key: "days_of_week",
            icon: <CalendarOutlined/>,
            label: "Dias da semana",
            value: daysOfWek.join(", ")
        }
    ]

    return (
        <Col
            {...columnConfig}
        >
            <Card
                className="schedule-card"
                size="small"
                title={
                    <Col>
                        <CalendarOutlined />
                    </Col>
                }
                extra={
                    <Row justify={"end"} wrap={false}>
                                    <Col>
                                        <Button
                                            type="link"
                                            target="_blank"
                                            icon={<EditOutlined />}
                                            title="Editar"
                                            onClick={() => {
                                                setOpenScheduleModalForm(true);
                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        <Popconfirm
                                            title="Eliminar horário"
                                            description="Confirma a ação de eliminar ?"
                                            onConfirm={() => {
                                                onClickDelete(schedule.uid)
                                            }}
                                            okText="Sim"
                                            cancelText="Não"
                                        >
                                            <Button
                                                type="link"
                                                icon={<DeleteOutlined />}
                                                title="Eliminar horário"
                                            />
                                        </Popconfirm>
                                    </Col>
                                </Row>
                }
            >
                <Row>
                    {
                        rows.map((row) => (
                            <Col span={24}>
                                <Row justify="space-between">
                                    <Col span={12}>
                                        <Typography.Paragraph type="secondary">
                                            <span>
                                                {row.icon} {row.label}
                                            </span>
                                        </Typography.Paragraph>
                                    </Col>
                                    <Col span={12}>
                                        <Typography.Paragraph ellipsis type="secondary" style={{textAlign:"end", color:"rgba(0, 0, 0, 0.88)"}}>
                                            {row.value}
                                        </Typography.Paragraph>
                                    </Col>
                                </Row>
                            </Col>
                        ))
                    }
                </Row>
            </Card>

            

            <ScheduleModalWithForm
                open={openScheduleModalForm}
                onCancel={()=>{
                    setOpenScheduleModalForm(false);
                }}
                onSubmitSuccess={() => {
                    onLoadSchedule();
                    setOpenScheduleModalForm(false);
                }}
                scheduleUID={schedule.uid}
            />
        </Col>
    )
}
export default CardSchedule;