import {
    Col,
    Row,
    Layout,
    Typography,
    FloatButton,
    Button
} from "antd";
import { FaPlus, FaRegBuilding } from "react-icons/fa";
import { useRef, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import ExperienceTimeModalWithForm from "../../../components/ExperienceTime/Modal/WithForm";
import ExperienceTimeList from "../../../components/ExperienceTime/List";
import ExperienceTimeSelectMult from "../../../components/ExperienceTime/Select/Mult";

function ExperienceTime({

}) {

    const [openExperienceTimeModalWithForm, setOpenExperienceTimeModalWithForm] = useState(false);
    const experienceTimeListref = useRef(null);
    const [filters, setFilters] = useState({});

    return (
        <Layout.Content className="page-content sec-management-responsive">
            <Row justify={"space-between"} align={"middle"}>
                <Col>
                    <Typography.Title
                        level={2}
                    >
                        <FaRegBuilding /> Experiência
                    </Typography.Title>
                </Col>
                <Col span="12" className="sec-management-responsive__container">
                    <Row justify="end">
                        <Col className="sec-management-responsive__btn-new">
                            <Button
                                type="primary"
                                onClick={() => {
                                    setOpenExperienceTimeModalWithForm(true);
                                }}
                                icon={<PlusOutlined />}
                            >
                                Nova Experiência
                            </Button>
                        </Col>
                        <Col style={{ width: "500px" }} className="sec-management-responsive__filter">
                            <ExperienceTimeSelectMult
                                onChange={(values) => {
                                    setFilters((prev) => ({
                                        ...prev,
                                        uids: values
                                    }))
                                }}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>

            <ExperienceTimeList
                maxColumnNumber={3}
                ref={experienceTimeListref}
                filters={filters}
            />

            <FloatButton
                style={{ border: '2px solid #00B298' }}
                onClick={() => {
                    setOpenExperienceTimeModalWithForm(true);
                }}
                icon={<FaPlus color="#00B298" />}
            />

            <ExperienceTimeModalWithForm
                open={openExperienceTimeModalWithForm}
                onCancel={() => {
                    setOpenExperienceTimeModalWithForm(false);
                }}
                onSubmitSuccess={() => {
                    setOpenExperienceTimeModalWithForm(false);
                    experienceTimeListref.current && experienceTimeListref.current.onLoadExperiencesTime();
                }}
            />

        </Layout.Content>
    )

}
export default ExperienceTime;