import {
    useState
} from "react";

import {
    Button,
    Col,
    Form,
    Row
} from "antd";

import PositionModal from "..";
import PositionForm from "../../Form";

function PositionModalWithForm({
    open,
    positionUID,
    formConfig,
    onCancel,
    onSubmitFinishSuccess
}) {
    const [position, setPosition] = useState(null);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const title = positionUID ?
        "Edição de Cargo" :
        "Cadastro de Cargo";

    const onClickSave = async () => {
        try {
            await form.validateFields();
            form.submit();
            setLoading(true);
        } catch (_err) {
            setLoading(false);
        }
    };

    const onLoadPosition = (positionData) => {
        setPosition(positionData);
    };

    return (
        <PositionModal
            open={open}
            positionUID={positionUID}
            onLoadPosition={onLoadPosition}
            onCancel={() => {
                onCancel();
                form.resetFields();
            }}
            title={title}
            footer={(
                <Row
                    align="middle"
                    justify="end"
                >
                    <Col>
                        <Button
                            type="link"
                            onClick={() => {
                                onCancel();
                                form.resetFields();
                            }}
                        >
                            Cancelar
                        </Button>
                    </Col>

                    <Col>
                        <Button
                            type="primary"
                            loading={loading}
                            onClick={onClickSave}
                        >
                            Salvar
                        </Button>
                    </Col>
                </Row>
            )}
        >
            <PositionForm
                form={form}
                positionUID={positionUID}
                onSubmitFinishSuccess={() => {
                    setLoading(false);
                    onSubmitFinishSuccess()
                }}
                onSubmitFinishFail={() => {
                    setLoading(false);
                }}
                {...({
                    ...formConfig,
                    valuesDefault: {
                        ...formConfig?.valuesDefault,
                        ...position
                    }
                })}
            />
        </PositionModal>
    );
}

export default PositionModalWithForm;