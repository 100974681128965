import { useEffect, useState, useImperativeHandle, forwardRef } from 'react';

import { Spin, Col, Row, notification, Pagination, Empty } from "antd";

import _service from '@netuno/service-client';

import CompanyListCard from "./Card";

import "./styles.less";
import CompanyModalInfo from '../Modal/Info';
import CompanyModalWithForm from '../Modal/WithForm';

function CompanyList({
    filters,
    columnConfig
}, ref) {
    const [companies, setCompanies] = useState([]);
    const [companiesTotal, setCompaniesTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [emptyImage, setEmptyImage] = useState(false);
    const [openCompanyModalWithForm, setOpenCompanyModalWithForm] = useState(false);
    const [companyUID, setCompanyUID] = useState("");
    const pageSize = 12;

    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    const CompanyListPagination = () => (
        <Row justify="center" align="middle">
            <Col>
                <Pagination
                    current={currentPage}
                    total={companiesTotal}
                    pageSize={pageSize}
                    onChange={onPageChange}
                    style={{
                        margin: "12px 0px"
                    }}
                />
            </Col>
        </Row>
    );

    const loadCompanies = () => {
        setLoading(true);

        _service({
            url: 'company/list',
            method: "POST",
            data: {
                filters,
                pagination: {
                    page: currentPage,
                    size: pageSize,
                }
            },
            success: (res) => {
                setLoading(false);
                if (res.json) {
                    setCompanies(res.json.companies);
                    setCompaniesTotal(res.json.companies_total);
                    setEmptyImage(res.json.companies_total > 0 ? false : true);
                }
            },
            fail: () => {
                setLoading(false);
                setEmptyImage(true);
                notification["error"]({
                    message: 'Detalhes das Empresas',
                    description: 'Erro ao carregar dados',
                });
            }
        })
    };

    useImperativeHandle(ref, () => ({
        loadCompanies
    }));

    useEffect(() => {
        if (currentPage === 1) {
            loadCompanies();

            return;
        }

        setCurrentPage(1);
    }, [filters]);

    useEffect(() => {
        loadCompanies();
    }, [currentPage]);

    if (loading) {
        return (
            <Row
                className="loading"
            >
                <Spin
                    tip="Aguarde..."
                    size="large"
                >
                    <div className="content" />
                </Spin>
            </Row>
        );
    }

    if (emptyImage) {
        return (
            <Row
                span={24}
                align="middle"
                className="div_empty_result"
            >
                <Empty
                    description={
                        <span>
                            Pesquisa não encontrada
                        </span>
                    }
                />
            </Row>
        );
    }

    return (
        <>
            <Row>
                <Col span={24}>
                    <CompanyListPagination />
                    <CompanyModalInfo
                        open={!!companyUID}
                        companyUID={companyUID}
                        onCancel={() => {
                            setCompanyUID("");
                        }}
                        onClickEdit={() => {
                            setOpenCompanyModalWithForm(true);
                        }}
                        onToggleActive={(active, uid) => {
                            setCompanies((prev) => prev.map((company) => {
                                if (company.uid === uid) {
                                    return ({
                                        ...company,
                                        active
                                    });
                                }

                                return company;
                            }));
                        }}
                    />
                    <CompanyModalWithForm
                        open={openCompanyModalWithForm}
                        companyUID={companyUID}
                        onCancel={() => {
                            setOpenCompanyModalWithForm(false);
                        }}
                        onSubmitFinishSuccess={() => {
                            setOpenCompanyModalWithForm(false);
                            loadCompanies();
                        }}
                    />
                </Col>
            </Row>

            <Row
                justify="start"
                gutter={[4, 4]}
                style={{
                    flexGrow: 1,
                    alignContent: "baseline"
                }}
            >
                {
                    companies.map((company) => (
                        <Col {...columnConfig}>
                            <CompanyListCard
                                company={company}
                                onClick={(companyData) => {
                                    setCompanyUID(companyData.uid);
                                }}
                            />
                        </Col>
                    ))
                }
            </Row>

            <Row>
                <Col span={24}>
                    <CompanyListPagination />
                </Col>
            </Row>
        </>
    );
}

export default forwardRef(CompanyList);
