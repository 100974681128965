import {
    Form,
    Input
} from "antd";

import _service from "@netuno/service-client";

import {
    INSERT_NAME
} from "../../../consts";
import {
    failToEdit,
    failToRegister
} from "../../../utils";

function ContactMeanForm({
    form,
    contactMeanUID,
    onSubmitFinishSuccess,
    onSubmitFinishFail,
    ...formConfig
}) {
    const onFinish = (values) => {
        const data = {
            ...values
        };

        if (!contactMeanUID) {
            _service({
                url: "interaction/contact_mean",
                method: "POST",
                data,
                success: () => {
                    onSubmitFinishSuccess();
                },
                fail: () => {
                    failToRegister("Meio de Contato");
                    onSubmitFinishFail();
                }
            });
        }

        if (contactMeanUID) {
            data.uid = contactMeanUID;

            _service({
                url: "interaction/contact_mean",
                method: "PUT",
                data,
                success: () => {
                    onSubmitFinishSuccess();
                },
                fail: () => {
                    failToEdit("Meio de Contato");
                    onSubmitFinishFail();
                }
            });
        }
    };

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={formConfig?.valuesDefault}
        >
            <Form.Item
                name="name"
                label="Nome"
                rules={[
                    {
                        required: true,
                        message: INSERT_NAME
                    },
                ]}
            >
                <Input type="text" />
            </Form.Item>
        </Form>
    );
}

export default ContactMeanForm;