import {
    Row,
    Col,
    Spin,
    notification
} from "antd";
import ModalCentered from "../../Modal/Centered";
import { useEffect, useState } from "react";
import _service from "@netuno/service-client";

function LanguageModal ({
    open, 
    title,
    languageUID,
    onLoadLanguage,
    footer,
    children,
    ...modalProps
}) {

    const [loading, setLoading] = useState(false);

    const loadLanguage = () => {
        setLoading(true);
        _service({
            url:"candidate/language",
            method:"GET",
            data:{uid:languageUID},
            success:(res) => {
                setLoading(false);
                onLoadLanguage(res.json);
            },
            fail:(err) =>{
                setLoading(false);
                notification.error({
                    message:"Carregar idioma",
                    description:"Falha ao carregar idioma"
                });
            }
        })
    }

    useEffect(()=>{
        if (open && languageUID) {
            loadLanguage();
        }
    },[open]);
    
    return(
        <ModalCentered
            open={open}
            title={!loading && title}
            footer={!loading && footer}
            {...modalProps}
        >
            {
                loading ? (
                    <Row
                        align="middle"
                        justify="center"
                        className="loading"
                    >
                        <Col span="24">
                            <Spin
                                tip="Aguarde..."
                                size="large"
                            >
                                <div className="content" />
                            </Spin>
                        </Col>
                    </Row>
                ) : children
            }

        </ModalCentered>
    )

}

export default LanguageModal;