import { useEffect, useState, forwardRef, useImperativeHandle } from "react";

import { Select } from "antd";

import _service from "@netuno/service-client";

import { failToLoadOptionsNotification } from "../../../../../utils";

let timerSearch = null;

function StatusColorSelectMultiple({
    value,
    onChange,
    ...selectOptions
}, ref) {
    const [statusColors, setStatusColors] = useState([]);
    const [filters, setFilters] = useState({});
    const [loading, setLoading] = useState(false);

    const loadStatusColors = () => {
        setLoading(true);

        _service({
            url: "company/color_status/list",
            method: "POST",
            data: {
                filters
            },
            success: (res) => {
                setStatusColors(res.json.color_status);

                setLoading(false);
            },
            fail: () => {
                failToLoadOptionsNotification("Cores");

                setLoading(true);
            }
        });
    };

    useImperativeHandle(ref, () => ({
        loadStatusColors
    }));

    useEffect(() => {
        loadStatusColors();
    }, [filters]);

    return (
        <Select
            allowClear={!loading}
            optionFilterProp="label"
            mode="multiple"
            loading={loading}
            value={value}
            options={statusColors.map((sttColor) => ({
                label: sttColor.name,
                value: sttColor.uid
            }))}
            onChange={(newValue) => {
                onChange(newValue);

                setFilters({
                    name: ""
                });
            }}
            onSearch={(search) => {
                if (timerSearch) {
                    clearTimeout(timerSearch);
                }

                timerSearch = setTimeout(() => {
                    setFilters(() => ({
                        name: search
                    }));
                }, 500);
            }}
            onDropdownVisibleChange={(isOpen) => {
                if (!isOpen && filters.name) {
                    setFilters(() => ({
                        name: ""
                    }));
                }
            }}
            style={{
                width: "100%"
            }}
            {...selectOptions}
        />
    );
}

export default forwardRef(StatusColorSelectMultiple);