import {
    useState
} from "react";

import {
    Col,
    Form,
    Layout,
    Row,
    Typography,
    FloatButton,
    Tooltip,
    Button
} from "antd";

import {
    FaRegBuilding,
    FaPlus
} from "react-icons/fa";
import { PlusOutlined } from "@ant-design/icons";

import _service from "@netuno/service-client";

import PositionsCardList from "../../../components/PositionsCardList";
import PositionModalWithForm from "../../../components/Position/Modal/WithForm";
import PositionSelectMultiple from "../../../components/Position/Select/Multiple";

import "./styles.less";

function Positions() {
    const [openPositionModalWithForm, setOpenPositionModalWithForm] = useState(false);
    const [filters, setFilters] = useState({});
    const [formSearch] = Form.useForm();
    const [formFilters] = Form.useForm();

    const onFiltersValuesChange = (values) => {
        formSearch.setFieldsValue(values);
        formFilters.setFieldsValue(values);
    }

    const onSubmitFilters = (values) => {
        setFilters({
            ...filters,
            ...values
        });
    }

    return (
        <Layout.Content className="page-content sec-management-responsive">
            <Row justify="space-between">
                <Col span="12">
                    <Typography.Title level={2}>
                        <FaRegBuilding /> Cargo
                    </Typography.Title>
                </Col>
                <Col span="12" className="sec-management-responsive__container">
                    <Row justify="end">
                        <Col className="sec-management-responsive__btn-new">
                            <Button
                                type="primary"
                                onClick={() => {
                                    setOpenPositionModalWithForm(true);
                                }}
                                icon={<PlusOutlined />}
                            >
                                Novo Cargo
                            </Button>
                        </Col>
                        <Col className="sec-management-responsive__filter">
                            <Form
                                form={formSearch}
                                onValuesChange={onFiltersValuesChange}
                                onFinish={onSubmitFilters}
                            >
                                <Form.Item name="positions_uids">
                                    <PositionSelectMultiple
                                        placeholder="Pesquisar por cargo"
                                        onChange={() => {
                                            formSearch.submit();
                                        }}
                                    />
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <PositionsCardList
                filters={filters}
                pageSize={21}
                maxColumnNumber={3}
                formSearch={formSearch}
            />
            <Tooltip
                title="Adicionar"
            >
                <FloatButton
                    style={{ border: '2px solid #00B298' }}
                    onClick={() => {
                        setOpenPositionModalWithForm(true);
                    }}
                    icon={<FaPlus color="#00B298" />}
                />
            </Tooltip>
            <PositionModalWithForm
                open={openPositionModalWithForm}
                onCancel={() => {
                    setOpenPositionModalWithForm(false);
                }}
                onSubmitFinishSuccess={() => {
                    setOpenPositionModalWithForm(false);

                    formSearch.submit();
                }}
            />
        </Layout.Content >
    );
}

export default Positions;
