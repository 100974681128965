import { useEffect, useState, forwardRef, useImperativeHandle } from "react";

import { Select } from "antd";

import _service from "@netuno/service-client";

import { failToLoadOptionsNotification } from "../../../../utils";

let timerSearch = null;

function ServiceSelectMultiple({
    value,
    onChange,
    ...selectConfig
}, ref) {
    const [services, setServices] = useState([]);
    const [filters, setFilters] = useState({});
    const [loading, setLoading] = useState(false);

    const loadservices = () => {
        setLoading(true);

        _service({
            url: "service/list",
            method: "POST",
            data: {
                filters:{
                    ...filters,
                    active:true
                }
            },
            success: (res) => {
                setServices(res.json.services);

                setLoading(false);
            },
            fail: () => {
                failToLoadOptionsNotification("Serviços");

                setLoading(true);
            }
        });
    };

    useImperativeHandle(ref, () => ({
        loadservices
    }));

    useEffect(() => {
        loadservices();
    }, [filters]);

    return (
        <Select
            allowClear={!loading}
            optionFilterProp="label"
            mode="multiple"
            loading={loading}
            value={value}
            options={services.map((service) => ({
                label: service.name,
                value: service.uid
            }))}
            onChange={(newValue) => {
                onChange(newValue);

                setFilters({
                    name: ""
                });
            }}
            onSearch={(search) => {
                if (timerSearch) {
                    clearTimeout(timerSearch);
                }

                timerSearch = setTimeout(() => {
                    setFilters(() => ({
                        name: search
                    }));
                }, 500);
            }}
            onDropdownVisibleChange={(isOpen) => {
                if (!isOpen && filters.name) {
                    setFilters(() => ({
                        name: ""
                    }));
                    return;
                }
                if (isOpen) {
                    loadservices();
                }
            }}
            style={{
                width: "100%"
            }}
            {...selectConfig}
        />
    );
}

export default forwardRef(ServiceSelectMultiple);