import { notification } from "antd";

const failToLoadOptionsNotification = (field) => {
    notification.error({
        message: `Opições de ${field}`,
        description: `Falha ao tentar carregar opções de ${field.toLowerCase()}.`
    });
};

const failToRegister = (field) => {
    notification.error({
        message: `Cadastro de ${field}`,
        description: `Falha ao tentar cadastrar ${field.toLowerCase()}.`
    });
};

const failToEdit = (field) => {
    notification.error({
        message: `Edição de ${field}`,
        description: `Falha ao tentar editar ${field.toLowerCase()}.`
    });
};

export {
    failToLoadOptionsNotification,
    failToRegister,
    failToEdit
};