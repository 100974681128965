import { useEffect } from "react";

import { Form, Input, notification, Col, Row, InputNumber } from "antd";

import _service from "@netuno/service-client";

import {
    COMPANY_EDITION,
    INSERT_CITY,
    INSERT_EMAIL,
    INSERT_LINKEDIN,
    INSERT_NAME,
    INSERT_PHONE,
    INSERT_SECTOR,
    INSERT_SERVICE,
    INSERT_STATUS,
    INSERT_STATUS_COLOR
} from "../../../consts";
import SectorSelectOne from "../../Sector/Select/One";
import ServiceSelectOne from "../../Service/Select/One";
import StatusSelectOne from "../../Status/Select/One";
import StatusColorSelectOne from "../../Status/Color/Select/One";

function CompanyForm({
    company,
    form,
    onSubmitFinishSuccess,
}) {

    const columnOptions = {
        xs: { span: 24 },
        sm: { span: 11 }
    }
    
    const onFinish = (values) => {
        const selects = [
            "service_uid",
            "sector_uid",
            "status_uid",
            "color_status_uid"
        ];
        const data = {
            ...values,
            phone:`${values.phone}`
        };

        selects.forEach((select) => {
            if (typeof (values[select]) === "object") {
                data[select] = values[select].value;
            }
        });

        if (!company) {
            _service({
                url: "company",
                method: "POST",
                data,
                fail: (err) => {
                    console.error("FormCompanies - create company", err);
                    if (err.status === 409) {
                        notification.warning({
                            message: 'Empresa Existente',
                            description: 'Dados já cadastrado, verifique novamente os dados inseridos.'
                        });
                    } else {
                        notification.error({
                            message: 'Falha ao Criar',
                            description: 'Ocorreu um erro ao criar empresa, por favor tente novamente mais tarde ou entre em contato com o suporte.'
                        });
                    };
                },
                success: () => {
                    form.resetFields();
                    notification.success({
                        message: 'Criado com Sucesso',
                    });
                    onSubmitFinishSuccess();
                },
            });
        }

        if (company) {
            data.uid = company.uid;

            _service({
                url: "company",
                method: "PUT",
                data,
                success: () => {
                    notification.success({
                        message: COMPANY_EDITION,
                        description: "Empresa editada com sucesso."
                    });

                    onSubmitFinishSuccess();
                },
                fail: () => {
                    notification.error({
                        message: COMPANY_EDITION,
                        description: "Falha ao tentar editar empresa."
                    });
                }
            });
        };
    }

    useEffect(() => {
        if (company) {
            form.setFieldsValue({
                ...company,
                sector_uid: {
                    label: company.sector.name,
                    value: company.sector.uid
                },
                service_uid: {
                    label: company.service.name,
                    value: company.service.uid
                },
                status_uid: {
                    label: company.status.name,
                    value: company.status.uid
                },
                color_status_uid: {
                    label: company.color_status.name,
                    value: company.color_status.uid
                }
            });
        }
    }, [company]);

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
        >
            <Row
                justify="space-between"
                style={{ width: "100%" }}
            >
                <Col {...columnOptions}>
                    <Form.Item
                        name="name"
                        label="Nome"
                        rules={[
                            {
                                required: true,
                                message: INSERT_NAME
                            },
                        ]}
                    >
                        <Input type="text" />
                    </Form.Item>
                </Col>

                <Col {...columnOptions}>
                    <Form.Item
                        name="email"
                        label="E-mail"
                        rules={[
                            {
                                required: true,
                                message: INSERT_EMAIL
                            },
                        ]}
                    >
                        <Input type="email" />
                    </Form.Item>
                </Col>

                <Col {...columnOptions}>
                    <Form.Item
                        name="phone"
                        label="Telefone"
                        rules={[
                            {
                                required: true,
                                message: INSERT_PHONE
                            }
                        ]}
                    >
                        <InputNumber
                            controls={false}
                            onKeyPress={(event) => {
                                if (/[a-zA-Z]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            style={{
                                width: "100%"
                            }}
                        />
                    </Form.Item>
                </Col>

                <Col {...columnOptions}>
                    <Form.Item
                        name="linkedin"
                        label="Linkedin"
                        rules={[
                            {
                                required: true,
                                message: INSERT_LINKEDIN
                            },
                        ]}
                    >
                        <Input type="text" />
                    </Form.Item>
                </Col>

                <Col {...columnOptions}>
                    <Form.Item name="website" label="Website">
                        <Input type="text" />
                    </Form.Item>
                </Col>

                <Col {...columnOptions}>
                    <Form.Item
                        name="city"
                        label="Cidade"
                        rules={[
                            {
                                required: true,
                                message: INSERT_CITY
                            },
                        ]}
                    >
                        <Input type="text" />
                    </Form.Item>
                </Col>

                <Col {...columnOptions}>
                    <Form.Item
                        name="country"
                        label="País"
                        rules={[
                            {
                                required: true,
                                message: "Insira o país."
                            },
                        ]}
                    >
                        <Input type="text" />
                    </Form.Item>
                </Col>

                <Col {...columnOptions}>
                    <Form.Item
                        name="sector_uid"
                        label="Setor"
                        rules={[
                            {
                                required: true,
                                message: INSERT_SECTOR
                            },
                        ]}
                    >
                        <SectorSelectOne
                            placement="topLeft"
                        />
                    </Form.Item>
                </Col>

                <Col {...columnOptions}>
                    <Form.Item
                        name="service_uid"
                        label="Serviço"
                        rules={[
                            {
                                required: true,
                                message: INSERT_SERVICE
                            },
                        ]}
                    >
                        <ServiceSelectOne
                            placement="topLeft"
                        />
                    </Form.Item>
                </Col>

                <Col {...columnOptions}>
                    <Form.Item
                        name="status_uid"
                        label="Status"
                        rules={[
                            {
                                required: true,
                                message: INSERT_STATUS
                            },
                        ]}
                    >
                        <StatusSelectOne
                            placement="topLeft"
                        />
                    </Form.Item>
                </Col>

                <Col {...columnOptions}>
                    <Form.Item
                        name="color_status_uid"
                        label="Status Cor Sinalizadora"
                        rules={[
                            {
                                required: true,
                                message: INSERT_STATUS_COLOR
                            },
                        ]}
                    >
                        <StatusColorSelectOne
                            placement="topLeft"
                        />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        name="notes"
                        label="Anotações"
                    >
                        <Input.TextArea rows={5} />
                    </Form.Item>
                </Col>
            </Row>
        </Form >
    )
}

export default CompanyForm;