import { 
    Row,
    Col,
    Button,
    Form
 } from "antd";
import { useState } from "react";
import TechnologyModal from "..";
import TechnologyForm from "../../Form";

function TechnologyModalWithForm ({
    open,
    onCancel,
    technologyUID,
    onSubmitSuccess
}) {

    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [technology, setTechnology] = useState(null);

    const onLoadTechnology = (data) => {
        setTechnology(data);
    }

    const onClickSubmit = async () => {
        try {
            setLoading(true);
            await form.validateFields();
            form.submit();
        } catch (error) {
            setLoading(false);
        }
    }
    
    return (
        <TechnologyModal
            open={open}
            onLoadTechnology={onLoadTechnology}
            technologyUID={technologyUID}
            onCancel={() => {
                onCancel();
                form.resetFields();
            }}
            title={`${technologyUID ? "Edição" : "Cadastro"} de tecnologia`}
            footer={(
                <Row
                    align="middle"
                    justify="end"
                >
                    <Col>
                        <Button
                            type="link"
                            onClick={() => {
                                onCancel();
                                form.resetFields();
                            }}
                        >
                            Cancelar
                        </Button>
                    </Col>

                    <Col>
                        <Button
                            type="primary"
                            loading={loading}
                            onClick={onClickSubmit}
                        >
                            Salvar
                        </Button>
                    </Col>
                </Row>
            )}
        >

            <TechnologyForm
                technology={technology}
                onSubmitSuccess={()=>{
                    setLoading(false);
                    onSubmitSuccess();
                    form.resetFields();
                }}
                onSubmitFail={()=>{
                    setLoading(false);
                }}
                form={form}
            />                

        </TechnologyModal>
    )
}

export default TechnologyModalWithForm;