import {
    Card,
    Col,
    Row,
    Typography,
    Switch,
    Tooltip,
    Button,
    notification
} from "antd";

import { EditOutlined } from "@ant-design/icons";
import "./styles.less"
import { useState } from "react";
import _service from "@netuno/service-client";
import CandidateStatusModalWithForm from "../../Modal/WithForm";

function CandidateStatusCardList({
    status,
    onClickActive,
    onLoadStatus
}) {

    const [loading, setLoading] = useState(false);
    const [openCandidateStatusModalWithForm, setOpenCandidateStatusModalWithForm] = useState(false);

    const onActiveStatus = () => {
        setLoading(true);
        _service({
            url:"candidate/status",
            method:"PUT",
            data:{
                ...status,
                active:!status.active
            },
            success: (res) =>{
                setLoading(false);
                notification.success({
                    message:`${status.active ? "Desativar" : "Ativar"} status`,
                    description:`Status ${status.active ? "desativado" : "ativado"} com sucesso`
                })
                onClickActive(status.uid, !status.active);
            }, fail: (err) => {
                setLoading(false);
                notification.error({
                    message:`${status.active ? "Desativar" : "Ativar"} Status`,
                    description:`Falha ao ${status.active ? "desativar" : "ativar"} status`
                })
            }
        })
    }

    return (
        <Card
            size="small"
            className="status-card"
        >
            <Row justify={"space-between"} align={"middle"}>
                <Col>
                    <Typography.Title
                        level={5}
                        style={{
                            margin: 0
                        }}
                    >
                        {status.name}
                    </Typography.Title>
                </Col>
                <Col className="col__btns">
                    <Tooltip
                        title={`${status.active ? "Desativar" : "Ativar"} status`}
                    >
                        <Switch
                            loading={loading}
                            defaultChecked={status.active}
                            size='small'
                            onClick={onActiveStatus}
                        />
                    </Tooltip>
                    
                    <Button type="link" icon={<EditOutlined />}
                        title="Editar"
                        disabled={!status.active}
                        onClick={()=>{
                            setOpenCandidateStatusModalWithForm(true);
                        }}
                    />
                </Col>
            </Row>
            <CandidateStatusModalWithForm
                open={openCandidateStatusModalWithForm}
                statusUID={status.uid}
                onCancel={()=>{
                    setOpenCandidateStatusModalWithForm(false);
                }}
                onSubmitSuccess={()=>{
                    setOpenCandidateStatusModalWithForm(false);
                    onLoadStatus();
                }}
            />
        </Card>
    )
}
export default CandidateStatusCardList;