import {
    Col,
    Row,
    Form,
    Button,
    Checkbox,
    Switch,
    InputNumber
} from "antd";
import {
    FilterFilled
} from "@ant-design/icons";
import { useState } from "react";

import ModalCentered from "../../Modal/Centered";
import CandidateSelectMult from "../Selects/CandidateSelectMult";
import TechnologySelectMult from "../../Technology/Select/Mult";
import LanguageSelectMult from "../../Language/Select/Mult";
import ExperienceSelectMult from "../../ExperienceTime/Select/Mult";
import CandidateStatusSelectMult from "../../CandidateStatus/Select/Mult";
import ReferenceValueSelectOne from "../../RefenceValue/Select/One";

function CandidateFilter({
    onFilter
}) {

    const columnOptions = {
        xs: { span: 24 },
        sm: { span: 11 }
    }

    const [openAdvancedFilter, setOpenAdvancedFilter] = useState(false);
    const [form] = Form.useForm();
    const active = Form.useWatch("active", form);
    const filterActive = Form.useWatch("filter_active", form);
    const reference_value_cost = Form.useWatch("reference_value_cost_uid", form);
    const reference_value_sell = Form.useWatch("reference_value_sell_uid", form);

    return (
        <Row
            align="middle"
            justify="end"
            wrap={false}
        >
            <Col style={{ width: "100%" }}>
                <Row
                    wrap={false}
                    justify={"space-between"}
                    align={"middle"}
                >
                    <Button
                        type="link"
                        icon={<FilterFilled />}
                        title="Filtro Avançado"
                        onClick={() => {
                            setOpenAdvancedFilter(true);
                        }}
                        style={{ paddingRight: "10px" }}
                    />
                    <CandidateSelectMult
                        onChange={(candidateUIDS) => {
                            onFilter({ candidate_uids: candidateUIDS });
                        }}
                    />
                </Row>
            </Col>

            <ModalCentered
                open={openAdvancedFilter}
                title="Filtro Avançado"
                destroyOnClose={false}
                width="90%"
                onCancel={() => {
                    setOpenAdvancedFilter(false);
                }}
                footer={(
                    <Row
                        align="middle"
                        justify="end"
                    >
                        <Col>
                            <Button
                                type="link"
                                onClick={() => {
                                    setOpenAdvancedFilter(false);
                                }}
                            >
                                Cancelar
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                type="primary"
                                onClick={() => {
                                    setOpenAdvancedFilter(false);
                                    form.submit();
                                }}
                            >
                                Filtrar
                            </Button>
                        </Col>
                    </Row>
                )}
                style={{
                    maxWidth: "600px"
                }}
            >
                <Form
                    className="company-advanced-filter"
                    form={form}
                    layout="vertical"
                    onFinish={onFilter}
                    initialValues={{
                        active: true
                    }}
                >
                    <Row
                        align="middle"
                        justify="space-between"
                        gutter={[12, 0]}
                    >
                        <Col {...columnOptions} >
                            <Form.Item
                                label="Status"
                                name="status_uids"
                            >
                                <CandidateStatusSelectMult
                                    placeholder=""
                                />

                            </Form.Item>
                        </Col>
                        <Col {...columnOptions} >
                            <Form.Item
                                label="Experiência"
                                name="experience_uids"
                            >
                                <ExperienceSelectMult
                                    placeholder=""
                                />

                            </Form.Item>
                        </Col>
                        <Col {...columnOptions} >
                            <Form.Item
                                label="Tecnologias"
                                name="technology_uids"
                            >
                                <TechnologySelectMult />

                            </Form.Item>
                        </Col>
                        <Col {...columnOptions} >
                            <Form.Item
                                label="Idiomas"
                                name="language_uids"
                            >
                                <LanguageSelectMult />

                            </Form.Item>
                        </Col>
                        <Col {...columnOptions}>
                            <Form.Item
                                label="Referência (Venda)"
                                name="reference_value_sell_uid"
                            >
                                <ReferenceValueSelectOne />
                            </Form.Item>
                        </Col>
                        <Col {...columnOptions}>
                            <Form.Item
                                label="Venda (Até:)"
                                name="sell_value"
                            >
                                <InputNumber
                                    defaultValue={0.0}
                                    disabled={!reference_value_sell}
                                    controls={false}
                                    prefix="€"
                                    style={{ width: "100%" }}
                                    formatter={(value) => value.replace(/\B(?=(\d{3})+(?!\d))/g, '')}
                                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                />
                            </Form.Item>
                        </Col>
                        <Col {...columnOptions}>
                            <Form.Item
                                label="Referência (Custo)"
                                name="reference_value_cost_uid"
                            >
                                <ReferenceValueSelectOne />
                            </Form.Item>
                        </Col>
                        <Col {...columnOptions}>
                            <Form.Item
                                label="Custo (Até:)"
                                name="cost_value"
                            >
                                <InputNumber
                                    defaultValue={0.0}
                                    controls={false}
                                    disabled={!reference_value_cost}
                                    prefix="€"
                                    style={{ width: "100%" }}
                                    formatter={(value) => value.replace(/\B(?=(\d{3})+(?!\d))/g, '')}
                                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                />
                            </Form.Item>
                        </Col>
                        <Col {...columnOptions}>
                            <Row align="middle" gutter={[12, 12]}>
                                <Col span={24}>
                                    <Form.Item
                                        name="filter_active"
                                        valuePropName="checked"
                                        noStyle
                                    >
                                        <Checkbox>
                                            Filtrar por ativado/desativado
                                        </Checkbox>
                                    </Form.Item>
                                </Col>

                                <Col>
                                    <Form.Item
                                        name="active"
                                        valuePropName="checked"
                                        noStyle
                                    >
                                        <Switch size="small" disabled={!filterActive} />
                                    </Form.Item>
                                </Col>

                                <Col>
                                    <label
                                        className="company-advanced-filter__label"
                                        htmlFor="active"
                                    >
                                        {active ? "Ativado" : "Desativado"}
                                    </label>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>

            </ModalCentered>
        </Row>
    )
}

export default CandidateFilter;