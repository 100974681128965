import { Typography } from "antd";

function ParagraphWithLabel({
    label,
    labelIcon,
    text
}) {
    return (
        <>
            <Typography.Paragraph type="secondary" style={{ marginBottom: "20px", fontSize:"15px" }}>
                <span>
                    {labelIcon} {label} <span style={{color:"#000"}}>{text}</span>
                </span>
            </Typography.Paragraph>
        </>
    );
}

export default ParagraphWithLabel;