import _service from "@netuno/service-client";
import { Form, Input, notification } from "antd";
import { useEffect } from "react";

function LanguageForm ({
    form,
    onSubmitFormSuccess,
    language,
    onSubmitFormFail
}) {

    const onFinish = (values) => {
        const data ={
            ...values
        }

        if (!language) {
            _service({
                url:"candidate/language",
                method:"POST",
                data:data,
                success:(res) => {
                    onSubmitFormSuccess();
                    notification.success({
                        message:"Cadastrar idioma",
                        description:"Idioma cadastrado com sucesso"
                    })
                },
                fail:(err) => {
                    if (err.json?.error === "language already exists") {
                        notification.error({
                            message:"Cadastrar idioma",
                            description:"Idioma já existente"
                        });
                    } else {
                        notification.error({
                            message:"Cadastrar idioma",
                            description:"Falha ao cadastrar idioma"
                        });
                    }
                    onSubmitFormFail();
                }
            })
        }
        if (language) {
            data.uid = language.uid;
            _service({
                url:"candidate/language",
                method:"PUT",
                data:data,
                success:(res) => {
                    onSubmitFormSuccess();
                    notification.success({
                        message:"Edição de idioma",
                        description:"Idioma editado com sucesso"
                    })
                },
                fail:(err) => {
                    if (err.json?.error === "language already exists") {
                        notification.error({
                            message:"Edição de idioma",
                            description:"Idioma já existente"
                        });
                    } else {
                        notification.error({
                            message:"Edição de idioma",
                            description:"Falha ao editar idioma"
                        });
                    }
                    onSubmitFormFail();
                }
            })
        }
    }

    useEffect(()=>{
        if (language) {
            form.setFieldsValue({
                name:language.name
            })
        }
    },[language])
    
    return (
        <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
        >
            <Form.Item
                label="Nome"
                name="name"
                rules={[{required:true, message:"Insira o nome"}]}
            >
                <Input/>    
            </Form.Item>    

        </Form>
    )
}

export default LanguageForm;