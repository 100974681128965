import {
    Select,
    notification
} from "antd";
import _service from '@netuno/service-client';
import { useEffect, useState } from "react";

let timerSearch = null;

function CandidateSelectMult({
    onChange,
    ...selectOptions
}) {

    const [candidates, setCandidates] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({});

    const onLoadCandidates = () => {
        setLoading(true);
        _service({
            url: "candidate/option/list",
            method: "POST",
            data: {
                filters: {
                    ...filters,
                    active:true
                }
            },
            success: (res) => {
                setLoading(false);
                if (res.json) {
                    setCandidates(res.json.candidates);
                }
            },
            fail: (err) => {
                setLoading(false);
                notification.error({
                    message: "Opções de candidato",
                    description: "Falha ao ler opções de candidato"
                })
            }
        })
    }

    useEffect(() => {
        onLoadCandidates();
    }, [filters])

    return (
        <Select
            style={{ width: "100%" }}
            allowClear={!loading}
            loading={loading}
            mode="multiple"
            optionFilterProp="label"
            onChange={onChange}
            onSearch={(value) => {
                if (timerSearch) {
                    clearTimeout(timerSearch);
                }

                timerSearch = setTimeout(() => {
                    setFilters((prev) => ({
                        ...prev,
                        name: value
                    }))
                }, 500)
            }}
            onDropdownVisibleChange={(isOpen)=>{
                if (!isOpen && filters.name) {
                    setFilters((prev) => ({
                        ...prev,
                        name: ""
                    }))
                    return;
                } 
                if (isOpen) {
                    onLoadCandidates();
                }
            }}
            placeholder="Buscar por candidato"
            options={candidates.map((candidate) => ({
                label: candidate.name,
                value: candidate.uid
            }))}
            {...selectOptions}
        />
    )
}

export default CandidateSelectMult;