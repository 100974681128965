import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

import { Col, Row, Spin, notification, Empty, Pagination } from "antd";

import CardRepresentative from "./CardRepresentative";

import _service from "@netuno/service-client";

function RepresentativeList({
    filters,
    columnConfig,
    inModal
}, ref) {
    const [representatives, setRepresentatives] = useState([]);
    const [representativesTotal, setRepresentativesTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [emptyImage, setEmptyImage] = useState(false);
    const pageSize = 10;

    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    const RepresentativeListPagination = () => (
        <Row justify="center">
            <Col>
                <Pagination
                    current={currentPage}
                    total={representativesTotal}
                    pageSize={pageSize}
                    onChange={onPageChange}
                    style={{
                        margin: "12px 0px"
                    }}
                />
            </Col>
        </Row>
    );

    const loadRepresentatives = () => {
        setLoading(true);
        _service({
            url: "representative/list",
            method: "POST",
            data: {
                filters,
                pagination: {
                    page: currentPage,
                    size: pageSize
                }
            },
            success: (res) => {
                setRepresentatives(res.json.representatives);
                setRepresentativesTotal(res.json.representatives_total);
                setLoading(false);
                setEmptyImage(res.json.representatives_total > 0 ? false : true);
            },
            fail: () => {
                notification.error({
                    message: "Representantes",
                    description: "Falha ao tentar carregar os representantes."
                });
                setLoading(false);
                setEmptyImage(true);
            }
        });
    };

    useImperativeHandle(ref, () => ({
        loadRepresentatives
    }));

    const onClickActive = (active, uid) => {
        setRepresentatives((prev) => prev.map((representative) => {
            if (representative.uid === uid) {
                return ({
                    ...representative,
                    active
                });
            }

            return representative;
        }));
    }

    useEffect(() => {
        if (currentPage === 1) {
            loadRepresentatives();

            return;
        }

        setCurrentPage(1);
    }, [filters]);

    useEffect(() => {
        loadRepresentatives();
    }, [currentPage]);

    if (loading) {
        return (
            <Row
                className="loading"
                style={inModal ? { height: "50vh" } : {}}
            >
                <Spin
                    tip="Aguarde..."
                    size="large"
                >
                    <div className="content" />
                </Spin>
            </Row>
        );
    }

    if (emptyImage) {
        return (
            <Row
                span={24}
                align="middle"
                className="div_empty_result"
                style={inModal ? { height: "50vh" } : {}}
            >
                <Empty
                    description={
                        <span>
                            Pesquisa não encontrada
                        </span>
                    }
                />
            </Row>
        );
    }

    return (
        <>
            {
                representativesTotal > 0 && (
                    <Row>
                        <Col span="24">
                            <RepresentativeListPagination />
                        </Col>
                    </Row>
                )
            }
            <Row
                align="top"
                justify="start"
                style={{ width: "100%" }}
                gutter={[12, 12]}
            >
                    {
                        representatives.map((representative) => (
                            <CardRepresentative
                                onClickActive={onClickActive}
                                representative={representative}
                                onLoadRepresentatives={loadRepresentatives}
                                columnConfig={columnConfig}
                            />
                        ))
                    }
            </Row>
            {
                representativesTotal > 0 && (
                    <Row style={{marginTop:"auto", paddingTop:"12px"}}>
                        <Col span="24">
                            <RepresentativeListPagination />
                        </Col>
                    </Row>
                )
            }
        </>
    );
}

export default forwardRef(RepresentativeList);