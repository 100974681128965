import {
    Row,
    Col,
    Button,
    Upload,
    notification,
    Form,
    Input
} from "antd";
import { UploadOutlined } from '@ant-design/icons';

import _service from "@netuno/service-client";

import "./styles.less"
import { useRef, useState } from "react";
import CandidateModal from "../../..";
import FileList from "./FileList";
import FileTypeSelectOne from "../../../../../FileType/Select/One";

function CandidateModalTabFiles({
    candidate
}) {

    const columnOptions = {
        xs: { span: 24 },
        sm: { span: 11 },
        md: { span: 11 }
    }

    const [loading, setLoading] = useState(false);
    const [openCandidateModalFormFiles, setOpenCandidateModalFormFiles] = useState(false);
    const [form] = Form.useForm();
    const fileListReference = useRef(null);
    const [fileList, setFileList] = useState([]);

    const handleSizeFile = (file) => {
        const maxFileSizeMB = 10;

        if (file.size > maxFileSizeMB * 1024 * 1024) {
            notification.error({
                message: "Falha ao carregar arquivo!",
                description: `O arquivo é muito grande, o tamanho maximo é de ${maxFileSizeMB} MB.`
            });
            setFileList([]);
            return false;
        }
        setFileList([file]);
        return true;
    }

    const props = {
        beforeUpload: handleSizeFile,
        fileList,
        action: "",
        listType: "picture",
        maxCount: 1,
        disabled: loading,
        accept: "image/*,.pdf"
    }

    const onFinish = (values) => {
        const formData = new FormData();

        formData.append("description", values.description);
        formData.append("file", values.files.file.originFileObj);
        formData.append("candidate_uid", candidate.uid);
        formData.append("file_type_uid", values.file_type_uid);

        setLoading(true);
        _service({
            url: "candidate/upload",
            method: "POST",
            data: formData,
            success: (res) => {
                setLoading(false);
                notification.success({
                    message: "Upload de arquivo",
                    description: "Arquivo salvo com sucesso!"
                });
                setOpenCandidateModalFormFiles(false);
                setFileList([]);
                form.resetFields();
                fileListReference.current && fileListReference.current.onLoadFiles();
            },
            fail: (err) => {
                setLoading(true);
                notification.success({
                    message: "Upload de arquivo",
                    description: "Falha ao salvar arquivo!!"
                });
                setOpenCandidateModalFormFiles(false);
                form.resetFields();
                setFileList([]);
            }
        })
    }

    return (
        <Row>
            <Col span={24}>
                <Row>
                    <Col span={24}>
                        <Button
                            type="primary"
                            icon={<UploadOutlined />}
                            onClick={() => { setOpenCandidateModalFormFiles(true) }}
                            disabled={!candidate.active}
                        >
                            Adicionar Arquivo
                        </Button>
                    </Col>
                    <Col span={24} style={{ marginTop: "20px"}}>

                        <FileList
                            ref={fileListReference}
                            candidate={candidate}
                        />

                    </Col>
                </Row>
            </Col>
            <Col>
            </Col>

            <CandidateModal
                open={openCandidateModalFormFiles}
                onCancel={() => {
                    setOpenCandidateModalFormFiles(false);
                    form.resetFields();
                    setFileList([]);
                }}
                title="Adicionar arquivo"
                footer={<Row justify="end">
                    <Col>
                        <Button
                            type="link"
                            onClick={() => {
                                setOpenCandidateModalFormFiles(false);
                                form.resetFields();
                                setFileList([]);
                            }}
                        >
                            Cancelar
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            type="primary"
                            loading={loading}
                            onClick={() => {
                                form.submit();
                            }}
                        >
                            Salvar
                        </Button>
                    </Col>
                </Row>}
            >

                <Form
                    onFinish={onFinish}
                    layout="vertical"
                    form={form}
                >
                    <Row justify={"space-between"} >
                        <Col {...columnOptions}>
                            <Form.Item
                                name="description"
                                label="Descrição:"
                                rules={[{ required: true, message: "Insira a descrição." }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col {...columnOptions}>
                            <Form.Item

                                name="file_type_uid"
                                label="Tipo do arquivo"
                                rules={[{ required: true, message: "Selecione o tipo do Arquivo." }]}
                            >
                                <FileTypeSelectOne />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                name="files"
                                rules={[{ required: true, message: "Selecione o arquivo." }]}
                            >
                                <Upload
                                    {...props}
                                >
                                    <Button icon={<UploadOutlined />}>Carregar</Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>

            </CandidateModal>
        </Row>
    )
}

export default CandidateModalTabFiles;