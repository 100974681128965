import { useRef, useState } from "react";

import { Col, Layout, Row, Typography, FloatButton, Tooltip } from "antd";

import { MdConnectWithoutContact } from "react-icons/md";
import { FaPlus } from "react-icons/fa"

import _service from "@netuno/service-client";

import InteractionPreviewList from "../../components/Interaction/PreviewList";
import InteractionFilter from "../../components/Interaction/Filter";
import InteractionModalWithForm from "../../components/Interaction/Modal/WithForm";

import "./styles.less";

function Interactions() {
    const [openInteractionModalWithForm, setOpenInteractionModalWithForm] = useState(false);
    const [filters, setFilters] = useState({});
    const interactionListRef = useRef(null);

    const onFilter = (values) => {
        setFilters((prev) => ({
            ...prev,
            ...values,
            active: values.filter_active ? values.active : undefined
        }));
    };

    return (
        <Layout.Content className="page-content">
            <Row justify="space-between">
                <Col style={{ marginRight: "20px" }} >
                    <Typography.Title level={2}>
                        <MdConnectWithoutContact /> Interações
                    </Typography.Title>
                </Col>
                <Col style={{ width: "500px" }} >
                    <InteractionFilter
                        onFilter={onFilter}
                    />
                </Col>
            </Row >
            <InteractionPreviewList
                columnProps={{
                    xs: {
                        span: 24
                    },
                    sm: {
                        span: 24
                    },
                    md: {
                        span: 12
                    },
                    lg: {
                        span: 8
                    }
                }}
                ref={interactionListRef}
                filters={filters}
            />
            <Tooltip
                title="Adicionar"
            >
                <FloatButton
                    style={{ border: '2px solid #00B298' }}
                    onClick={() => {
                        setOpenInteractionModalWithForm(true);
                    }}
                    icon={<FaPlus color="#00B298" />}
                />
            </Tooltip>
            <InteractionModalWithForm
                open={openInteractionModalWithForm}
                onCancel={() => {
                    setOpenInteractionModalWithForm(false);
                }}
                onSubmitFinishSuccess={() => {
                    interactionListRef?.current?.loadInteractions();
                    setOpenInteractionModalWithForm(false);
                }}
            />
        </Layout.Content >
    );
}

export default Interactions;