import { useEffect, useState } from "react";
import { Col, Pagination, Row, Spin, Empty, notification } from "antd";
import PositionsCard from "./PositionsCard";
import _service from "@netuno/service-client";
import "./styles.less";

function PositionsCardList({
    filters,
    maxColumnNumber,
    formSearch
}) {
    const [positions, setPositions] = useState([]);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [emptyImage, setEmptyImage] = useState(false);
    const pageSize = 10;
    const columnOptions = [
        {
            span: 24
        },
        {
            sm: { span: 24 },
            md: { span: 12 }
        },
        {   xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 12 },
            lg: { span: 8 }
        },
    ];

    const onPageChange = (page) => {
        setPage(page);
    }

    const onActive = (active,uid) => {
        
        setPositions((prev) => prev.map((position) => {
            if (position.uid === uid) {
                return ({
                    ...position,
                    active
                });
            }

            return position;
        }));
    };

    const loadPositions = () => {
        setLoading(true);
        _service({
            url: "representative/position/list",
            method: "POST",
            data: {
                filters,
                pagination: {
                    page,
                    size: pageSize
                }
            },
            success: (res) => {
                setLoading(false);
                if (res.json) {
                    setPositions(res.json.positions);
                    setTotal(res.json.positions_total);
                    setEmptyImage(res.json.positions_total > 0 ? false : true);
                }
            },
            fail: (err) => {
                setLoading(false);
                console.error(err);
                setEmptyImage(true);
            }
        });

    };

    useEffect(() => {
        if (page !== 1) {
            setPage(1);

            return;
        }

        loadPositions();
    }, [filters]);

    useEffect(() => {
        loadPositions();
    }, [page]);

    if (loading) {
        return (
            <Row
                className="loading"
            >

                <Spin
                    tip="Aguarde..."
                    size="large"
                >
                    <div className="content" />
                </Spin>
            </Row>
        )
    }

    if (emptyImage) {
        return (
            <Row
                span={24}
                align="middle"
                className="div_empty_result"
            >
                <Empty
                    description={
                        <span>
                            Pesquisa não encontrada
                        </span>
                    }
                />
            </Row>
        )
    }

    return (
        <>
            {
                total > 0 && (
                    <Row
                        align="middle"
                        justify="center"
                        className="positions-card-list-pagination-top">
                        <Col>
                            <Pagination
                                total={total}
                                onChange={onPageChange}
                                current={page}
                                pageSize={pageSize}
                            />
                        </Col>
                    </Row>
                )
            }
            <Row
                className="positions-card-list"
                gutter={[12, 12]}
                justify="start"
                align="top"
            >
                {positions.length > 0 && positions.map((position) => (
                    <Col key={position.uid} {...columnOptions[maxColumnNumber - 1]}>
                        <PositionsCard
                            position={position}
                            formSearch={formSearch}
                            onActive={onActive}
                        />
                    </Col>
                ))}
            </Row>
            {
                total > 0 && (
                    <Row
                        align="middle"
                        justify="center"
                        className="positions-card-list-pagination-bottom">
                        <Col>
                            <Pagination
                                total={total}
                                onChange={onPageChange}
                                current={page}
                                pageSize={pageSize}
                            />
                        </Col>
                    </Row>
                )
            }
        </>
    );
}

export default PositionsCardList;
