import {
    Row,
    Col,
    Button,
    Form
} from "antd";
import { useState } from "react";
import FileTypeModal from "..";
import FileTypeForm from "../../Form";

function FileTypeModalWithForm({
    open,
    fileTypeUID,
    onSubmitSuccess,
    onCancel
}) {

    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [fileType, setFileType] = useState(null);

    const onLoadFyleType = (data) => {
        setFileType(data);
    }

    const onClickSubmit = async () => {

        try {
            setLoading(true);
            await form.validateFields();
            form.submit();
        } catch (error) {
            setLoading(false);
        }
    }

    return (
        <FileTypeModal
            title={`${fileTypeUID ? "Edição" : "Cadastro"} de tipo de arquivo`}
            onCancel={() => {
                onCancel();
                form.resetFields();
            }}
           fileTypeUID={fileTypeUID}
            open={open}
            onLoadFileType={onLoadFyleType}
            footer={(
                <Row
                    align="middle"
                    justify="end"
                >
                    <Col>
                        <Button
                            type="link"
                            onClick={() => {
                                onCancel();
                                form.resetFields();
                            }}
                        >
                            Cancelar
                        </Button>
                    </Col>

                    <Col>
                        <Button
                            type="primary"
                            loading={loading}
                            onClick={onClickSubmit}
                        >
                            Salvar
                        </Button>
                    </Col>
                </Row>
            )}
        >
            <FileTypeForm
                form={form}
                onSubmitFail={() => {
                    setLoading(false);
                }}
                onSubmitSuccess={()=>{
                    setLoading(false);
                    form.resetFields();
                    onSubmitSuccess();
                }}
                fileType={fileType}
            />

        </FileTypeModal>
    )
}

export default FileTypeModalWithForm;