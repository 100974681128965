import { useRef, useState } from "react";

import { Button, Col, Row} from "antd";
import { PlusOutlined } from "@ant-design/icons";

import RepresentativeList from "../../../../../Representative/List";
import RepresentativeModalWithForm from "../../../../../Representative/Modal/WithForm";
import RepresentativeFilter from "../../../../../Representative/Filter";
import "./style.less"

function CompanyModalInfoTabRepresentatives({
    company
}) {
    const [openRepresentativeModal, setOpenRepresentativeModal] = useState(false);
    const [filters, setFilters] = useState({
        companies_uids: [company.uid]
    });
    const representativeListRef = useRef(null);
    const representativeFilterRef = useRef(null);

    const onFilter = (values) => {
        console.log(values)
        const newFilters = {
            ...values,
        };

        setFilters(newFilters);
    };

    return (
        <Row gutter={[0, 12]}>
            <Col span="24">
                <Row justify="space-between" className="representative-header">
                    <Col span="12" className="representative-header__btn">
                        <Button
                            type="primary"
                            disabled={!company.active}
                            onClick={() => {
                                setOpenRepresentativeModal(true);
                            }}
                            icon={<PlusOutlined />}
                        >
                            Novo Representante
                        </Button>
                        <RepresentativeModalWithForm
                            open={openRepresentativeModal}
                            formConfig={{
                                readOnly: {
                                    company_uid: true,
                                },
                                valuesDefault: {
                                    company_uid: {
                                        label: company.name,
                                        value: company.uid
                                    }
                                }
                            }}
                            onCancel={() => {
                                setOpenRepresentativeModal(false);
                            }}
                            onSubmitFinishSuccess={() => {
                                setOpenRepresentativeModal(false);

                                representativeListRef.current && representativeListRef.current.loadRepresentatives();
                                representativeFilterRef.current && representativeFilterRef.current.loadRepresentatives();
                            }}
                        />
                    </Col>

                    <Col span="12" className="representative-header__filter">
                        <RepresentativeFilter
                            ref={representativeFilterRef}
                            company={company}
                            onFilter={onFilter}
                        />
                    </Col>
                </Row>
            </Col>

            <Col span="24" style={{padding:"0px 8px"}}>
                <RepresentativeList
                    ref={representativeListRef}
                    filters={filters}
                    columnConfig={{
                        xs: {
                            span: 24
                        },
                        sm: {
                            span: 24
                        },
                        md: {
                            span: 12
                        }
                    }}
                    inModal={true}
                />
            </Col>
        </Row>
    );
}

export default CompanyModalInfoTabRepresentatives;