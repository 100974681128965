import {
    Row,
    notification,
    Empty,
    Spin,
    Pagination,
    Col
} from "antd";
import _service from "@netuno/service-client";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import CandidateStatusCardList from "./CardList";

function CandidateStatusList ({
    maxColumnNumber,
    filters
},ref) {

    const columnOptions = [
        {
            span: 24
        },
        {
            sm: { span: 24 },
            md: { span: 12 }
        },
        {
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 12 },
            lg: { span: 8 }
        },
    ];

    const [status, setStatus] = useState([]);
    const [statusTotal, setStatusTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [emptyImage, setEmptyImage] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10;

    const onChangePagination = (page) => {
        setCurrentPage(page);
    }

    const StatusPagination = () => {
        return (
            <Row justify="center"
            >
                <Col>
                    <Pagination
                        current={currentPage}
                        pageSize={pageSize}
                        total={statusTotal}
                        onChange={onChangePagination}
                        style={{
                            margin: "12px 0px"
                        }}
                    />
                </Col>
            </Row>
        )
    }

    const onLoadStatus = () => {
        setLoading(true);
        _service({
            url: "candidate/status/list",
            method: "POST",
            data: {
                pagination:{pageSize, page:currentPage},
                filters:{
                    ...filters
                }
            },
            success: (res) => {
                setLoading(false);
                if (res.json) {
                    console.log(res.json)
                    setEmptyImage(res.json.status_total > 0 ? false : true);
                    setStatus(res.json.status);
                    setStatusTotal(res.json.status_total);
                }
            },
            fail: (err) => {
                setLoading(false);
                setEmptyImage(true);
                notification.error({
                    message: "Carregar status",
                    description: "falha ao carregar status"
                });
            }
        })
    }

    const onClickActive = (uid, active) => {
        setStatus((prev) => prev.map((stt)=>{
           if (stt.uid === uid) {
               return({
                   ...stt,
                   active
               })
           }
           return stt;
        }))   
   }

    useImperativeHandle(ref, () =>({
        onLoadStatus
    }))

    useEffect(() => {
        onLoadStatus();
    }, [currentPage])

    useEffect(()=>{
        if (currentPage === 1) {
            onLoadStatus();
            return;
        }
        setCurrentPage(1);
    },[filters])


    if (loading) {
        return (
            <Row
                className="loading"
            >

                <Spin
                    tip="Aguarde..."
                    size="large"
                >
                    <div className="content" />
                </Spin>
            </Row>
        )
    }

    if (emptyImage) {
        return (
            <Row
                span={24}
                align="middle"
                className="div_empty_result"
            >
                <Empty
                    description={
                        <span>
                            Pesquisa não encontrada
                        </span>
                    }
                />
            </Row>
        )
    }


    return (
        <>
            {
                statusTotal > 0 && (
                    <Row justify={"center"} align={"middle"}>
                        <Col span={24}>
                            <StatusPagination />
                        </Col>
                    </Row>
                )
            }
            <Row
                gutter={[12, 12]}
                justify="start"
                align="top"
            >
                {
                    status.length > 0 && status.map((stt, index) => (
                        <Col {...columnOptions[maxColumnNumber - 1]} key={index}>
                            <CandidateStatusCardList
                                status={stt}
                                onClickActive={onClickActive}
                                onLoadStatus={onLoadStatus}
                            />
                        </Col>
                    ))
                }
            </Row>
            {
                statusTotal > 0 && (
                    <Row style={{ marginTop: "auto", paddingTop: "12px" }}>
                        <Col span={24}>
                            <StatusPagination />
                        </Col>
                    </Row>
                )
            }

        </>
    )
}

export default forwardRef(CandidateStatusList);