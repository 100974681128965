import {
    useState
} from "react";

import {
    Card,
    Row,
    Col,
    Button,
    Typography,
    Switch,
    Tooltip,
    notification
} from "antd";
import {
    EditOutlined
} from "@ant-design/icons";

import PositionModalWithForm from "../../Position/Modal/WithForm";
import _service from "@netuno/service-client";

import "./styles.less";

function PositionsCard({
    position,
    formSearch,
    onActive,
}) {
    const [openPositionModalWithForm, setOpenPositionModalWithForm] = useState(false);

    const [loading, setLoading] = useState(false);

    const onActivePosition = () => {
        setLoading(true);

        _service({
            url: "representative/position",
            method: "PUT",
            data: {
                ...position,
                active: !position.active
            },
            success: (res) => {
                setLoading(false)
                notification.success({
                    message: `${position.active ? "Desativar" : "Ativar"} cargo`,
                    description: `Cargo ${position.active ? "desativado" : "ativado"} com sucesso.`
                })
                onActive(!position.active, position.uid);
            },
            fail: (err) => {
                setLoading(false)
                notification.error({
                    message: `${position.active ? "Desativar" : "Ativar"} cargo`,
                    description: `Falaha ao ${position.active ? "desativar" : "ativar"} cargo.`
                })
            }
        })
    }

    return (
        <Card
            className="positions-card"
            size="small"
        >
            <Row
                align="middle"
                justify="space-between"
               
            >
                <Col >
                    <Typography.Title
                        level={5}
                        style={{
                            margin: 0
                        }}
                    >
                        {position.name}
                    </Typography.Title>
                </Col>
                <Col className="col__btns" >

                    <Tooltip title={`${position.active ? "Desativar" : "Ativar"} cargo.`} >
                        <Switch
                            defaultChecked={position.active}
                            size='small'
                            onClick={onActivePosition}
                            loading={loading}
                        />
                    </Tooltip>

                    <Button type="link" icon={<EditOutlined />}
                        title="Editar"
                        disabled={!position.active}
                        onClick={() => {
                            setOpenPositionModalWithForm(true);
                        }}
                    />
                    <PositionModalWithForm
                        open={openPositionModalWithForm}
                        positionUID={position.uid}
                        onCancel={() => {
                            setOpenPositionModalWithForm(false);
                        }}
                        onSubmitFinishSuccess={() => {
                            setOpenPositionModalWithForm(false);

                            formSearch.submit();
                        }}
                    />
                </Col>
            </Row>
        </Card >
    );
}

export default PositionsCard;
