import {
    Row,
    notification,
    Empty,
    Spin,
    Pagination,
    Col
} from "antd";
import _service from "@netuno/service-client";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import TechnologyCardList from "./CardList";

function TechnologyList({
    maxColumnNumber,
    filters
}, ref) {

    const columnOptions = [
        {
            span: 24
        },
        {
            sm: { span: 24 },
            md: { span: 12 }
        },
        {
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 12 },
            lg: { span: 8 }
        },
    ];

    const [technologies, setTechnologies] = useState([]);
    const [technologiesTotal, setTechnologiesTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [emptyImage, setEmptyImage] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10;

    const onChangePagination = (page) => {
        setCurrentPage(page);
    }

    const TechnologyPagination = () => {
        return (
            <Row justify="center"
            >
                <Col>
                    <Pagination
                        current={currentPage}
                        pageSize={pageSize}
                        total={technologiesTotal}
                        onChange={onChangePagination}
                        style={{
                            margin: "12px 0px"
                        }}
                    />
                </Col>
            </Row>
        )
    }



    const onLoadTechnologies = () => {
        setLoading(true);
        _service({
            url: "candidate/technology/list",
            method: "POST",
            data: {
                pagination:{pageSize, page:currentPage},
                filters:{
                    ...filters
                }
            },
            success: (res) => {
                setLoading(false);
                if (res.json) {
                    setEmptyImage(res.json.technology_total > 0 ? false : true);
                    setTechnologies(res.json.technologies);
                    console.log(res)
                    setTechnologiesTotal(res.json.technology_total);
                }
            },
            fail: (err) => {
                setLoading(false);
                setEmptyImage(true);
                notification.error({
                    message: "Carregar Technologias",
                    description: "falha ao carregar technologias"
                });
            }
        })
    }

    const onClickActive = (uid, active) => {
         setTechnologies((prev) => prev.map((technology)=>{
            if (technology.uid === uid) {
                return({
                    ...technology,
                    active
                })
            }
            return technology;
         }))   
    }

    useImperativeHandle(ref, () =>({
        onLoadTechnologies
    }))

    useEffect(() => {
        onLoadTechnologies();
    }, [currentPage])

    useEffect(()=>{
        if (currentPage === 1) {
            onLoadTechnologies();
            return;
        }
        setCurrentPage(1);
    },[filters])

    if (loading) {
        return (
            <Row
                className="loading"
            >

                <Spin
                    tip="Aguarde..."
                    size="large"
                >
                    <div className="content" />
                </Spin>
            </Row>
        )
    }

    if (emptyImage) {
        return (
            <Row
                span={24}
                align="middle"
                className="div_empty_result"
            >
                <Empty
                    description={
                        <span>
                            Pesquisa não encontrada
                        </span>
                    }
                />
            </Row>
        )
    }

    return (
        <>
            {
                technologiesTotal > 0 && (
                    <Row justify={"center"} align={"middle"}>
                        <Col span={24}>
                            <TechnologyPagination />
                        </Col>
                    </Row>
                )
            }
            <Row
                gutter={[12, 12]}
                justify="start"
                align="top"
            >
                {
                    technologies.length > 0 && technologies.map((technology, index) => (
                        <Col {...columnOptions[maxColumnNumber - 1]} key={index}>
                            <TechnologyCardList
                                technology={technology}
                                onClickActive={onClickActive}
                                onLoadTechnologies={onLoadTechnologies}
                            />
                        </Col>
                    ))
                }
            </Row>
            {
                technologiesTotal > 0 && (
                    <Row style={{ marginTop: "auto", paddingTop: "12px" }}>
                        <Col span={24}>
                            <TechnologyPagination />
                        </Col>
                    </Row>
                )
            }

        </>
    )

}

export default forwardRef(TechnologyList);