import { useEffect, useState } from "react";

import { connect } from "react-redux";

import { useNavigate, Link } from "react-router-dom";

import { Button, Menu } from "antd";
import { LogoutOutlined, EditOutlined } from '@ant-design/icons';

import _auth from '@netuno/auth-client';

import HeaderUserInfo from '../HeaderUserInfo';

function HeaderMenu({
  loggedUserInfo
}) {
  const [items, setItems] = useState([
    {
      key: "management",
      label: "Gestão",
      hidden: true,
      children: [
        {
          key: "company",
          label: "Empresa",
          children: [
            {
              key: 5,
              label: "Status",
              onClick: () => navigate("/companies/status"),
            },
            {
              key: 6,
              label: "Serviço",
              onClick: () => navigate("/companies/service"),
            },
            {
              key: 7,
              label: "Setor",
              onClick: () => navigate("/companies/sector"),
            },
          ]
        },
        {
          key: "representative",
          label: "Representante",
          children: [
            {
              key: 8,
              label: "Cargo",
              onClick: () => navigate("/representatives/positions"),
            },
          ]
        },
        {
          key: "interaction",
          label: "Interação",
          children: [
            {
              key: 9,
              label: "Meio de Contato",
              onClick: () => navigate("/interactions/meansOfContact"),
            },
            {
              key: 10,
              label: "Tipo de Contato",
              onClick: () => navigate("/interactions/typeOfContact"),
            }
          ]
        },
        {

          key: "candidate",
          label: "Candidato",
          children: [
            {
              key: 11,
              label: "Tecnologia",
              onClick: () => navigate("/candidates/technologies"),
            },
            {
              key: 12,
              label: "Idioma",
              onClick: () => navigate("/candidates/languages"),
            },
            {
              key: 13,
              label: "Status",
              onClick: () => navigate("/candidates/status"),
            },
            {
              key: 14,
              label: "Experiência",
              onClick: () => navigate("/candidates/experience"),
            },
            {
              key: 15,
              label: "Tipo de arquivo",
              onClick: () => navigate("/candidates/fileTypes"),
            },
          ]
        },
        {
          key: "interwiew",
          label: "Agendamentos",
          children: [
            {
              key: 16,
              label: "Horários",
              onClick: () => navigate("/schedule"),
            }
          ]
        },
      ]
    },
    {
      key: "profile",
      label: <HeaderUserInfo />,
      className: "profile-menu",
      popupClassName: "profile-menu-popup",
      children: [
        {
          key: "1",
          label: (
            <Link to="/profile">
              <EditOutlined />&nbsp;&nbsp;&nbsp;Editar Perfil
            </Link>
          )
        },
        {
          key: "2",
          label: (
            <Button
              danger
              type="link"
              onClick={() => {
                _auth.logout();
              }}
            >
              <LogoutOutlined /> Terminar Sessão
            </Button>
          )
        }
      ]
    }
  ]);
  const navigate = useNavigate();

  useEffect(() => {
    if (loggedUserInfo) {
      setItems((prev) => prev.map((item) => {
        if (item.key === "management") {
          return ({
            ...item,
            hidden: !loggedUserInfo.admin
          })
        }
        return item;
      }));
    }
  }, [loggedUserInfo]);

  return (
    <Menu
      mode="horizontal"
      style={{ width: "100%", textAlign: "end", minWidth: "327px" }}
      items={items}
    />
  );
}

const mapStateToProps = (store) => {
  const { loggedUserInfo } = store.loggedUserInfoState;

  return {
    loggedUserInfo
  };
};

export default connect(mapStateToProps, {})(HeaderMenu);
