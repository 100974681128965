import { useState } from "react";

import { Card, Col, Row, Button, Typography, Switch, Tooltip, notification } from "antd";
import { EditOutlined } from "@ant-design/icons";

import _service from "@netuno/service-client";

import StatusModalWithForm from "../../Status/Modal/WithForm";

import "./styles.less";

function StatusCard({
    status,
    formSearch,
    onToggleActive
}) {
    const [openStatusModalWithForm, setOpenStatusModalWithForm] = useState(false);
    const [loadingToggleActive, setLoadingToggleActive] = useState(false);

    const onToggleModalEdit = () => {
        setOpenStatusModalWithForm(!openStatusModalWithForm);
    };

    const toggleActive = () => {
        setLoadingToggleActive(true);

        _service({
            url: "status",
            method: "PUT",
            data: {
                ...status,
                active: !status.active
            },
            success: () => {
                setLoadingToggleActive(false);
                notification.success({
                    message: `${status.active ? "Desativar" : "Ativar"} Status`,
                    description: `Status ${status.active ? "desativado" : "ativado"} com sucesso.`
                });

                onToggleActive(!status.active, status.uid);
            },
            fail: () => {
                setLoadingToggleActive(false);
                notification.error({
                    message: `${status.active ? "Desativar" : "Ativar"} Status`,
                    description: `Falha ao tentar ${status.active ? "desativar" : "ativar"} o status.`
                });
            }
        });
    };

    return (
        <Card
            className="status-card"
            size="small"
        >
            <Row
                align="middle"
                justify="space-between"
                gutter={[4, 0]}
            >
                <Col>
                    <Typography.Title
                        level={5}
                        style={{
                            margin: "0px"
                        }}
                    >
                        {status.name}
                    </Typography.Title>
                </Col>
                <Col>
                    <Row align="middle" gutter={[4, 0]}>
                        <Col>
                            <Tooltip
                                title={`${status.active ? "Desativar" : "Ativar"} status.`}
                            >
                                <Switch
                                    size="small"
                                    loading={loadingToggleActive}
                                    checked={status.active}
                                    onClick={toggleActive}
                                />
                            </Tooltip>
                        </Col>

                        <Col>
                            <Button type="link" icon={<EditOutlined />}
                                title="Editar"
                                onClick={onToggleModalEdit}
                                disabled={!status.active}
                            />
                            <StatusModalWithForm
                                open={openStatusModalWithForm}
                                statusUID={status.uid}
                                onCancel={() => {
                                    setOpenStatusModalWithForm(false);
                                }}
                                onSubmitFinishSuccess={() => {
                                    setOpenStatusModalWithForm(false);

                                    formSearch.submit();
                                }}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Card>
    );
}

export default StatusCard;
