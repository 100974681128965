import { Col, Row, Tag, Typography } from "antd";
import { TagOutlined, AlignLeftOutlined } from '@ant-design/icons';

import { GrStatusGoodSmall } from "react-icons/gr";
import { MdMap, MdCategory } from "react-icons/md";
import { AiOutlineGlobal, AiOutlineLinkedin, AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import ParagraphyWithLabel from "../../../../../Paragraph/WithLabel";
import "./style.less";

function CompanyModalInfoTabGeneral({
    company
}) {
    const NOT_REGISTERED = "Não cadastrado";

    return (
        <Row gutter={20} style={{ width: "100%" }} wrap={true} className="info_tab_general" >
            <Col span="12">
                <Row >
                    <Col span="24">
                        <ParagraphyWithLabel
                            label="Serviço: "
                            labelIcon={<TagOutlined size={20} />}
                            text={company.service.name}
                        />
                    </Col>

                    <Col span="24">
                        <ParagraphyWithLabel
                            label="Setor: "
                            labelIcon={<MdCategory size={20} />}
                            text={company.sector.name}
                        />
                    </Col>

                    <Col span="24">
                        <ParagraphyWithLabel
                            label="País: "
                            labelIcon={<MdMap size={20} />}
                            text={company.country}
                        />
                    </Col>

                    <Col span="24">
                        <ParagraphyWithLabel
                            label="Cidade: "
                            labelIcon={<MdMap size={20} />}
                            text={company.city}
                        />
                    </Col>

                    <Col span="24">
                        <Typography.Paragraph type="secondary" style={{ marginBottom: "20px" }}>
                            <AiOutlineGlobal size={20} /> WebSite : {
                                <>
                                    {
                                        company.website ? (
                                            <Typography.Link target="_blank" href={company.website}>
                                                {company.website}
                                            </Typography.Link>
                                        ) : (
                                            <span style={{ color: "#000" }}>
                                                {NOT_REGISTERED}
                                            </span>
                                        )
                                    }
                                </>
                            }
                        </Typography.Paragraph>
                    </Col>
                </Row>
            </Col>

            <Col span="12">
                <Row>
                    <Col span="24">
                        <ParagraphyWithLabel
                            label="Status: "
                            labelIcon={<GrStatusGoodSmall size={20} />}
                            text={(
                                <Tag color={company.color_status.code_hex} style={{ margin: 0 }}>
                                    {company.status.name}
                                </Tag>
                            )}
                        />
                    </Col>

                    <Col span="24">
                        <ParagraphyWithLabel
                            label="Telefone: "
                            labelIcon={<AiOutlinePhone size={20} />}
                            text={!company.phone ? NOT_REGISTERED : (
                                <Typography.Link
                                    href={`tel://${company.phone}`}
                                >
                                    {company.phone}
                                </Typography.Link>
                            )}
                        />
                    </Col>

                    <Col span="24">
                        <ParagraphyWithLabel
                            label="E-mail: "
                            labelIcon={<AiOutlineMail size={20} />}
                            text={company.email ? (
                                <Typography.Link
                                    href={`mailto:${company.email}`}
                                >
                                    {company.email}
                                </Typography.Link>
                            ) : NOT_REGISTERED}
                        />
                    </Col>
                    <Col span="24">
                        <Typography.Paragraph type="secondary" style={{ marginBottom: "20px" }}>
                            <span><AiOutlineLinkedin size={20} /> Linkedin :</span> {
                                <span>
                                    {
                                        company.linkedin ? (
                                            <Typography.Link target="_blank" href={company.linkedin}>
                                                {company.linkedin}
                                            </Typography.Link>
                                        ) : (
                                            <Typography.Paragraph>
                                                {NOT_REGISTERED}
                                            </Typography.Paragraph>
                                        )
                                    }
                                </span>
                            }
                        </Typography.Paragraph>
                    </Col>
                </Row>
            </Col>

            <Col span={24}>
                <Typography.Paragraph type="secondary">
                    <AlignLeftOutlined /> Anotações
                </Typography.Paragraph>

                <Typography.Paragraph>
                    <div dangerouslySetInnerHTML={{ __html: company.notes }} />
                </Typography.Paragraph>
            </Col>
        </Row>

    );
}

export default CompanyModalInfoTabGeneral;