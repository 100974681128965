import {
    Row,
    Col,
    Card,
    Tooltip,
    Tag,
    Typography
} from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { GrStatusGoodSmall } from "react-icons/gr";
import { MdWork, MdOutlineLanguage } from "react-icons/md";
import { BsStackOverflow } from "react-icons/bs";
import CandidateModalWithInfo from "../../Modal/WithInfo";
import CandidateModalWithForm from "../../Modal/WithForm";

import "./styles.less";
import { useState } from "react";

function CardCandidate({
    candidate,
    columnConfig,
    onLoadCandidate,
    onClickActived
}) {

    const [openCandidateModalWithInfo, setOpenCandidateModalWithInfo] = useState(false);
    const [openCandidateModalWithForm, setOpenCandidateModalWithForm] = useState(false); 

    const rows = [
        {
            key: "status",
            icon: <GrStatusGoodSmall />,
            label: "Status",
            value: (
                <Tag
                    color={candidate.status.color}
                    style={{
                        margin: "0px 0px 0px auto",
                        width: "fit-content"
                    }}
                >
                    {candidate.status.name}
                </Tag>
            )
        },
        {
            key: "experience",
            icon: <MdWork/>,
            label: "Experiência",
            value: candidate.experience.time
        },
        {
            key: "languages",
            icon: <MdOutlineLanguage/>,
            label: "Idiomas",
            value: candidate.languages.map(language => language.name).join(", ")
        },
        {
            key: "stack",
            icon: <BsStackOverflow/>,
            label: "Tecnologias",
            value: candidate.technologies.map(technologie => technologie.name).join(", ")
        }
    ]

    return (
        <Col
            {...columnConfig}
        >
            <Card
                className="candidate-card"
                size="small"
                title={candidate.name}
                onClick={()=>{setOpenCandidateModalWithInfo(true)}}
                extra={
                    <Col>
                        {candidate.active ? (
                            <Tooltip title="Candidato Ativo">
                                <CheckCircleOutlined
                                    style={{ color: "green" }}
                                />
                            </Tooltip>
                        ) : (
                            <Tooltip title="Candidato Desativado">
                                <CloseCircleOutlined
                                    style={{ color: "red" }}
                                />
                            </Tooltip>
                        )}
                    </Col>
                }
            >
                <Row>
                    {
                        rows.map((row) => (
                            <Col span={24}>
                                <Row justify="space-between">
                                    <Col span={12}>
                                        <Typography.Paragraph type="secondary">
                                            <span>
                                                {row.icon} {row.label}
                                            </span>
                                        </Typography.Paragraph>
                                    </Col>
                                    <Col span={12}>
                                        <Typography.Paragraph ellipsis type="secondary" style={{textAlign:"end", color:"rgba(0, 0, 0, 0.88)"}}>
                                            {row.value}
                                        </Typography.Paragraph>
                                    </Col>
                                </Row>
                            </Col>
                        ))
                    }
                </Row>
            </Card>

            <CandidateModalWithInfo
                candidateUID={candidate.uid}
                open={openCandidateModalWithInfo}
                onCancel={()=>{
                    setOpenCandidateModalWithInfo(false);
                }}
                onEdit={() => {
                    setOpenCandidateModalWithForm(true);
                }}
                onClickActived={onClickActived}
            />

            <CandidateModalWithForm
                open={openCandidateModalWithForm}
                onCancel={()=>{
                    setOpenCandidateModalWithForm(false);
                }}
                onSubmitFormSuccess={()=>{
                    onLoadCandidate();
                    setOpenCandidateModalWithForm(false);
                }}
                candidateUUID={candidate.uid}
            />
        </Col>
    )
}
export default CardCandidate;