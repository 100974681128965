import { useEffect, useState, forwardRef, useImperativeHandle } from "react";

import { Select } from "antd";

import _service from "@netuno/service-client";

import { failToLoadOptionsNotification } from "../../../../utils";

let timerSearch = null;

function CompanySelectMultiple({
    value,
    onChange,
    ...selectOptions
}, ref) {
    const [companies, setCompanies] = useState([]);
    const [filters, setFilters] = useState({});
    const [loading, setLoading] = useState(false);

    const loadCompanies = () => {
        setLoading(true);

        _service({
            url: "company/options/list",
            method: "POST",
            data: {
                filters:{
                    ...filters,
                    active:true
                }
            },
            success: (res) => {
                setCompanies(res.json.companies);

                setLoading(false);
            },
            fail: () => {
                failToLoadOptionsNotification("Empresas");

                setLoading(true);
            }
        });
    };

    useImperativeHandle(ref, () => ({
        loadCompanies
    }));

    useEffect(() => {
        if (!selectOptions?.disabled) {
            loadCompanies();
        }
    }, [filters]);

    return (
        <Select
            allowClear={!loading}
            optionFilterProp="label"
            mode="multiple"
            loading={loading}
            value={value}
            options={companies.map((company) => ({
                label: company.name,
                value: company.uid
            }))}
            onChange={(newValue) => {
                onChange(newValue);

                setFilters((prev) => ({
                    ...prev,
                    name: ""
                }));
            }}
            onSearch={(search) => {
                if (timerSearch) {
                    clearTimeout(timerSearch);
                }

                timerSearch = setTimeout(() => {
                    setFilters((prev) => ({
                        ...prev,
                        name: search
                    }));
                }, 500);
            }}
            onDropdownVisibleChange={(isOpen) => {
                if (!isOpen && filters.name) {
                    setFilters(() => ({
                        name: ""
                    }));
                    return;
                }
                if (isOpen) {
                    loadCompanies();
                }
            }}
            style={{
                width: "100%"
            }}
            {...selectOptions}
        />
    );
}

export default forwardRef(CompanySelectMultiple);