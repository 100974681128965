import { useEffect, useState, forwardRef, useImperativeHandle } from "react";

import { Select } from "antd";

import _service from "@netuno/service-client";

import { failToLoadOptionsNotification } from "../../../../utils";

let timerSearch = null;

function RepresentativeSelectMultiple({
    companiesUIDs,
    value,
    onChange,
    ...selectOptions
}, ref) {
    const [representatives, setRepresentatives] = useState([]);
    const [filters, setFilters] = useState({});
    const [loading, setLoading] = useState(false);

    const loadRepresentatives = () => {
        let finalFilters = {
            ...filters,
            active:true
        };
        setLoading(true);

        if (companiesUIDs) {
            finalFilters.companies_uids = companiesUIDs
        }

        _service({
            url: "representative/option/list",
            method: "POST",
            data: {
                filters: finalFilters
            },
            success: (res) => {
                setRepresentatives(res.json.representatives);

                setLoading(false);
            },
            fail: () => {
                failToLoadOptionsNotification("Representantes");

                setLoading(true);
            }
        });
    };

    useImperativeHandle(ref, () => ({
        loadRepresentatives
    }));

    useEffect(() => {
        loadRepresentatives();
    }, [filters]);

    return (
        <Select
            allowClear={!loading}
            optionFilterProp="label"
            mode="multiple"
            loading={loading}
            value={value}
            options={representatives.map((representative) => ({
                label: representative.name,
                value: representative.uid
            }))}
            onChange={(newValue) => {
                onChange(newValue);

                setFilters((prev) => ({
                    ...prev,
                    name: ""
                }));
            }}
            onSearch={(search) => {
                if (timerSearch) {
                    clearTimeout(timerSearch);
                }

                timerSearch = setTimeout(() => {
                    setFilters((prev) => ({
                        ...prev,
                        name: search
                    }));
                }, 500);
            }}
            onDropdownVisibleChange={(isOpen) => {
                if (!isOpen && filters.name) {
                    setFilters((prev) => ({
                        ...prev,
                        name: ""
                    }));
                    return;
                }
                if (isOpen) {
                    loadRepresentatives();
                }
            }}
            style={{
                width: "100%"
            }}
            {...selectOptions}
        />
    );
}

export default forwardRef(RepresentativeSelectMultiple);