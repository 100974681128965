import {
    Card,
    Col,
    Row,
    Typography,
    Switch,
    Tooltip,
    Button,
    notification
} from "antd";

import { EditOutlined } from "@ant-design/icons";
import "./styles.less"
import { useState } from "react";
import _service from "@netuno/service-client";
import TechnologyModalWithForm from "../../Modal/WithForm";

function TechnologyCardList({
    technology,
    onClickActive,
    onLoadTechnologies
}) {

    const [loading, setLoading] = useState(false);
    const [openTechnologyModalWithForm, setOpenTechnologyModalWithForm] = useState(false);

    const onActiveTechnology = () => {
        setLoading(true);
        _service({
            url:"candidate/technology",
            method:"PUT",
            data:{
                ...technology,
                active:!technology.active
            },
            success: (res) =>{
                setLoading(false);
                notification.success({
                    message:`${technology.active ? "Desativar" : "Ativar"} tecnologia`,
                    description:`Tecnologia ${technology.active ? "desativada" : "ativada"} com sucesso`
                })
                onClickActive(technology.uid, !technology.active);
            }, fail: (err) => {
                setLoading(false);
                notification.error({
                    message:`${technology.active ? "Desativar" : "Ativar"} tecnologia`,
                    description:`Falha ao ${technology.active ? "desativar" : "ativar"} tecnologia`
                })
            }
        })
    }

    return (
        <Card
            size="small"
            className="technology-card"
        >
            <Row justify={"space-between"} align={"middle"}>
                <Col>
                    <Typography.Title
                        level={5}
                        style={{
                            margin: 0
                        }}
                    >
                        {technology.name}
                    </Typography.Title>
                </Col>
                <Col className="col__btns">
                    <Tooltip
                        title={`${technology.active ? "Desativar" : "Ativar"} tecnologia`}
                    >
                        <Switch
                            loading={loading}
                            defaultChecked={technology.active}
                            size='small'
                            onClick={onActiveTechnology}
                        />
                    </Tooltip>
                    
                    <Button type="link" icon={<EditOutlined />}
                        title="Editar"
                        disabled={!technology.active}
                        onClick={()=>{
                            setOpenTechnologyModalWithForm(true);
                        }}
                    />
                </Col>
            </Row>
            <TechnologyModalWithForm
                open={openTechnologyModalWithForm}
                technologyUID={technology.uid}
                onCancel={()=>{
                    setOpenTechnologyModalWithForm(false);
                }}
                onSubmitSuccess={()=>{
                    setOpenTechnologyModalWithForm(false);
                    onLoadTechnologies();
                }}
            />
        </Card>
    )
}
export default TechnologyCardList;