import { useState } from "react";

import { Row, Col, Tabs, Typography, Button, Switch, Tooltip, notification} from "antd";
import { EditOutlined } from "@ant-design/icons";

import { MdOutlineConnectWithoutContact } from "react-icons/md";

import RepresentativeModal from "..";
import RepresentativeModalInfoTabGeneral from "./Tab/General";
import RepresentativeModalInfoTabInteractions from "./Tab/Interactions";
import { connect } from "react-redux";
import _service from "@netuno/service-client";

function RepresentativeModalInfo({
  open,
  onCancel,
  loggedUserInfo,
  representativeUID,
  onClickEdit,
  onClickActive,
}) {

  const [representative, setRepresentative] = useState(null);
  const [loading, setLoading] = useState(false);

  const onLoadRepresentative = (loadRepresentative) => {
    setRepresentative(loadRepresentative);
  }

  const onActiveRepresentative = () => {
    setLoading(true);
    _service({
      url: "representative",
      method: "PUT",
      data:{
        ...representative,
        position_uid:representative.position.uid,
        company_uid:representative.company.uid,
        active:!representative.active
      },
      fail: (err) => {
        notification.error({
          message: `${representative.active ? "Desativar" : "Ativar"} Representante`,
          description: `Falha ao tentar ${representative.active ? "desativar" : "ativar"} representante.`
        });
          setLoading(false);
      },
      success: () => {
        notification.success({
          message: `${representative.active ? "Desativar" : "Ativar"} Representante`,
          description: `Representante ${representative.active ? "desativado" : "ativado"} com sucesso.`
        })
          setLoading(false);
          setRepresentative((prev) => ({
            ...prev,
            active: !representative.active
          }));
          onClickActive(!representative.active, representative.uid);
      }
  });
  }

  return (
    <RepresentativeModal
      open={open}
      representativeUID={representativeUID}
      onCancel={onCancel}
      onLoadRepresentative={onLoadRepresentative}
      footer={false}
      width="90%"
      style={{
        maxWidth: "900px",
      }}
      bodyStyle={{
        height: "72vh"
      }}
      title={representative && (
        <Row
          align="middle"
          justify={"space-between"}
          wrap={false}
        >
          <Col>
            {representative.name}
          </Col>
          <Col className="btns_modal">
            <Row
              wrap={false}
              align="middle" gutter={[12, 0]}
            >
                {loggedUserInfo?.admin ? (
                   <Col style={{marginBottom:"2px"}}>
                   <Tooltip 
                   title={`${representative.active ? "Desativar" : "Ativar"} representante`}
                   >
                     <Switch
                       size="small"
                       loading={loading}
                       checked={representative.active}
                       onClick={onActiveRepresentative}
                     />
                   </Tooltip>
                 </Col>
                ) : (<></>)}
               
              <Col>
                <Button
                  type="link"
                  disabled={!representative.active}
                  icon={<EditOutlined />}
                  title="Editar"
                  onClick={onClickEdit}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    >
      {representative && (
        <Tabs
          tabBarStyle={{
            position: "sticky",
            top: 0,
            zIndex: 1,
            background: "#FFF"
          }}
          items={[
            {
              key: "general",
              label: (
                <Typography>
                  <span>
                    Informações Gerais
                  </span>
                </Typography>
              ),
              children: (<RepresentativeModalInfoTabGeneral representative={representative} />)
            },
            {
              key: "interations",
              label: (
                <Typography>
                  <span>
                    <MdOutlineConnectWithoutContact size={16} /> Interações
                  </span>
                </Typography>
              ),
              children: (<RepresentativeModalInfoTabInteractions representative={representative} />)
            }
          ]}
        />
      )}
    </RepresentativeModal>
  )
}

const mapStateToProps = store => {
  const { loggedUserInfo } = store.loggedUserInfoState;

  return {
    loggedUserInfo
  };
};

export default connect(mapStateToProps, {})(RepresentativeModalInfo);