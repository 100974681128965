import { useEffect, useState, forwardRef, useImperativeHandle, useRef } from "react";

import { Col, Form, Row, Button, Tooltip, Input, Checkbox, Switch, } from "antd";

import { FilterFilled } from "@ant-design/icons";

import CompanySelectMultiple from "../Select/Multiple";
import ModalCenterd from "../../Modal/Centered";
import RepresentativeSelectMultiple from '../../Representative/Select/Multiple';
import SectorSelectMultiple from "../../Sector/Select/Multiple";
import ServiceSelectMultiple from "../../Service/Select/Multiple";
import StatusSelectMultiple from "../../Status/Select/Multiple";
import StatusColorSelectMultiple from "../../Status/Color/Select/Multiple";

import "./styles.less";

function CompanyFilter({
    onFilter,
}, ref) {
    const [openAdvancedFilter, setOpenAdvancedFilter] = useState(false);
    const [form] = Form.useForm();
    const companiesUIDs = Form.useWatch("companies_uids", form);
    const active = Form.useWatch("active", form);
    const filterActive = Form.useWatch("filter_active", form);
    const companySelectRef = useRef(null);
    const representativeSelectRef = useRef(null);

    const columnOptions = {
        xs: { span: 24 },
        sm: { span: 11 }
    }

    useImperativeHandle(ref, () => ({
        loadCompanies: () => {
            companySelectRef.current && companySelectRef.current.loadCompanies();
        }
    }));

    useEffect(() => {
        representativeSelectRef?.current?.loadRepresentatives();
        form.setFieldValue("representatives_uids", []);
    }, [companiesUIDs]);

    return (
        <Row
            align="middle"
            justify="end"
            gutter={[12, 0]}
        >
            <Col>
                <Tooltip
                    title="Filtro avançado"
                >
                    <Button
                        type="link"
                        onClick={() => {
                            setOpenAdvancedFilter(true);
                        }}
                        icon={<FilterFilled />}
                        style={{
                            textAlign: "end"
                        }}
                    />
                </Tooltip>
                <ModalCenterd
                    open={openAdvancedFilter}
                    title="Filtro Avançado"
                    destroyOnClose={false}
                    width="90%"
                    onCancel={() => {
                        setOpenAdvancedFilter(false);
                    }}
                    footer={(
                        <Row
                            align="middle"
                            justify="end"
                        >
                            <Col>
                                <Button
                                    type="link"
                                    onClick={() => {
                                        setOpenAdvancedFilter(false);
                                    }}
                                >
                                    Cancelar
                                </Button>
                            </Col>
                            <Col>
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        setOpenAdvancedFilter(false);

                                        form.submit();
                                    }}
                                >
                                    Filtrar
                                </Button>
                            </Col>
                        </Row>
                    )}
                    style={{
                        maxWidth: "600px"
                    }}
                >
                    <Form
                        className="company-advanced-filter"
                        form={form}
                        layout="vertical"
                        onFinish={onFilter}
                        initialValues={{
                            active: true
                        }}
                    >
                        <Row
                            justify="space-between"
                            style={{ width: "100%" }}
                        >
                            <Col {...columnOptions}>
                                <Form.Item
                                    label="Empresas"
                                    name="companies_uids"
                                >
                                    <CompanySelectMultiple
                                        ref={companySelectRef}
                                    />
                                </Form.Item>
                            </Col>

                            <Col {...columnOptions}>
                                <Form.Item
                                    label="Representantes"
                                    name="representatives_uids"
                                >
                                    <RepresentativeSelectMultiple
                                        ref={representativeSelectRef}
                                        companiesUIDs={companiesUIDs}
                                    />
                                </Form.Item>
                            </Col>

                            <Col {...columnOptions}>
                                <Form.Item
                                    label="Setores"
                                    name="sectors_uids"
                                >
                                    <SectorSelectMultiple />
                                </Form.Item>
                            </Col>

                            <Col {...columnOptions}>
                                <Form.Item
                                    label="Serviços"
                                    name="services_uids"
                                >
                                    <ServiceSelectMultiple />
                                </Form.Item>
                            </Col>

                            <Col {...columnOptions}>
                                <Form.Item
                                    label="Status"
                                    name="status_uids"
                                >
                                    <StatusSelectMultiple
                                        placement="topLeft"
                                    />
                                </Form.Item>
                            </Col>

                            <Col {...columnOptions}>
                                <Form.Item
                                    label="Cores"
                                    name="color_status_uids"
                                >
                                    <StatusColorSelectMultiple
                                        placement="topLeft"
                                    />
                                </Form.Item>
                            </Col>

                            <Col {...columnOptions}>
                                <Row align="middle" gutter={[12, 12]}>
                                    <Col span={24}>
                                        <Form.Item
                                            name="filter_active"
                                            valuePropName="checked"
                                            noStyle
                                        >
                                            <Checkbox>
                                                Filtrar por ativado/desativado
                                            </Checkbox>
                                        </Form.Item>
                                    </Col>

                                    <Col>
                                        <Form.Item
                                            name="active"
                                            valuePropName="checked"
                                            noStyle
                                        >
                                            <Switch size="small" disabled={!filterActive} />
                                        </Form.Item>
                                    </Col>

                                    <Col>
                                        <label
                                            className="company-advanced-filter__label"
                                            htmlFor="active"
                                        >
                                            {active ? "Ativado" : "Desativado"}
                                        </label>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </ModalCenterd>
            </Col>

            <Col
                style={{
                    flexGrow: 1
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFilter}
                    initialValues={{
                        active: true
                    }}
                >
                    <Form.Item
                        name="companies_uids"
                        style={{
                            margin: "0px"
                        }}
                    >
                        <CompanySelectMultiple
                            ref={companySelectRef}
                            placeholder="Pesquisar por empresas"
                            onChange={() => {
                                form.submit();
                            }}
                        />
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    );
}

export default forwardRef(CompanyFilter);