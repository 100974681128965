import {
    useEffect,
    useState
} from "react";

import {
    Col,
    notification,
    Row,
    Spin
} from "antd";

import _service from "@netuno/service-client";

import ModalCentered from "../../Modal/Centered";

function PositionModal({
    open,
    positionUID,
    title,
    footer,
    onLoadPosition,
    children,
    ...modalProps
}) {
    const [loading, setLoading] = useState(false);

    const loadPosition = () => {
        setLoading(true);

        _service({
            url: "representative/position",
            data: {
                uid: positionUID
            },
            success: (res) => {
                onLoadPosition(res.json.position);
                setTimeout(() => setLoading(false), 250);
            },
            fail: () => {
                notification.error({
                    message: "Cargo",
                    description: "Falha ao carregar dados do cargo."
                });
            }
        });
    };

    useEffect(() => {
        if (open && positionUID) {
            loadPosition();
        }
    }, [open]);

    return (
        <ModalCentered
            open={open}
            title={!loading && title}
            footer={!loading && footer}
            {...modalProps}
        >
            {
                loading ? (
                    <Row
                        align="middle"
                        justify="center"
                        className="loading"
                    >
                        <Col span="24">
                            <Spin
                                tip="Aguarde..."
                                size="large"
                            >
                                <div className="content" />
                            </Spin>
                        </Col>
                    </Row>
                ) : children
            }
        </ModalCentered>
    );
}

export default PositionModal;