import {
    Row,
    Col,
    Typography,
    Divider
} from "antd";
import {
    FaBirthdayCake,
    FaTransgender,
    FaMoneyBillWave
} from "react-icons/fa";
import { BsStackOverflow } from "react-icons/bs";
import {
    AiOutlineMail,
    AiOutlinePhone,
    AiFillGithub,
    AiFillLinkedin,
    AiOutlineUser
} from "react-icons/ai"
import { GrStatusGoodSmall } from "react-icons/gr";

import { MdNotes, MdWork, MdOutlineLanguage } from "react-icons/md";
import { NOT_REGISTERED } from "../../../../../../consts";
import ParagraphyWithLabel from "../../../../../Paragraph/WithLabel"
import moment from 'moment';

function CandidateModalTabGeneral({
    candidate
}) {

    const columnOptions = {
        xs: { span: 24 },
        sm: { span: 12 },
        md: { span: 12 }
    }

    const getYearsOfBirthDate = (birthDateString) => {

        const birthDate = moment(birthDateString, "YYYY-MM-DD");
        const currentDate = moment();

        return currentDate.diff(birthDate, "years");
    }

    const CalcReferenceValue = ({ value, reference }) => {

        if (reference === "Hora") {
            return (
                <span>
                    <Typography.Paragraph>
                        {
                            `Hora: ${value.toLocaleString('pt-PT', {
                                style: 'currency',
                                currency: 'EUR',
                            })}`
                        }
                    </Typography.Paragraph>
                    
                    <Typography.Paragraph>
                        {
                            `Dia: ± ${(value * 8).toLocaleString('pt-PT', {
                                style: 'currency',
                                currency: 'EUR',
                            })}`
                        }
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        {
                            `Mês: ± ${(value * 8 * 22).toLocaleString('pt-PT', {
                                style: 'currency',
                                currency: 'EUR',
                            })}`
                        }
                    </Typography.Paragraph>
                </span>
            )
        }
        else if (reference === "Dia") {
            return (
                <span>
                    <Typography.Paragraph>
                        {
                            `Dia: ${value.toLocaleString('pt-PT', {
                                style: 'currency',
                                currency: 'EUR',
                            })}`
                        }
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        {
                            `Mês: ± ${(value * 22).toLocaleString('pt-PT', {
                                style: 'currency',
                                currency: 'EUR',
                            })}`
                        }
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        {
                            `Hora: ± ${(value / 8).toLocaleString('pt-PT', {
                                style: 'currency',
                                currency: 'EUR',
                            })}`
                        }
                    </Typography.Paragraph>
                </span>
            )

        } else if (reference === "Mês") {
            return (
                <span>
                    <Typography.Paragraph>
                        {
                            `Mês: ${value.toLocaleString('pt-PT', {
                                style: 'currency',
                                currency: 'EUR',
                            })}`
                        }
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        {
                            `Dia: ± ${(value / 22).toLocaleString('pt-PT', {
                                style: 'currency',
                                currency: 'EUR',
                            })}`
                        }
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        {
                            `Hora: ± ${((value / 22) / 8).toLocaleString('pt-PT', {
                                style: 'currency',
                                currency: 'EUR',
                            })}`
                        }
                    </Typography.Paragraph>
                </span>
            )
        }
    }

    const convertReferenceValueToHour = () => {
        const sellReference = candidate.reference_value_sell.name;
        const sellValue = candidate.sell_value;
        const costReference = candidate.reference_value_cost.name;
        const costValue = candidate.cost_value

        let sellValueToHour = 0;
        let costValueToHour = 0;

        if (sellReference === "Hora") {
            sellValueToHour = sellValue;
        } else if (sellReference === "Dia") {
            sellValueToHour = sellValue / 8;
        } else if (sellReference === "Mês") {
            sellValueToHour = (sellValue / 22) / 8;
        }

        if (costReference === "Hora") {
            costValueToHour = costValue;
        } else if (costReference === "Dia") {
            costValueToHour = costValue / 8;
        } else if (costReference === "Mês") {
            costValueToHour = (costValue / 22) / 8;
        }

        return sellValueToHour - costValueToHour;
    }

    return (
        <Row
            style={{ width: "100%" }}
            className="info_tab_general"
            wrap={true}
            gutter={[24, 8]}
        >
            <Divider>Dados Pessoais</Divider>
            <Col {...columnOptions}>
                <Row>
                    <Col span="24">
                        <ParagraphyWithLabel
                            labelIcon={<AiOutlineUser size={20} />}
                            label={"Nome: "}
                            text={candidate.name}
                        />
                    </Col>
                    <Col span="24" >
                        <Typography.Paragraph type="secondary" style={{ marginBottom: "20px" }}>
                            <span> <FaBirthdayCake size={20} /> Data de Nascimento: </span>
                            <span>
                                {
                                    candidate.date_of_birth ? (
                                        <Typography.Text style={{ whiteSpace: "nowrap" }}>
                                            {`${candidate.date_of_birth} ( ${getYearsOfBirthDate(candidate.date_of_birth)} anos )`}
                                        </Typography.Text>
                                    ) : (
                                        <Typography.Text >
                                            {NOT_REGISTERED}
                                        </Typography.Text>
                                    )
                                }
                            </span>
                        </Typography.Paragraph>
                    </Col>
                    <Col span="24">
                        <ParagraphyWithLabel
                            labelIcon={<MdOutlineLanguage size={20} />}
                            label={"Idiomas: "}
                            text={candidate.languages.map(language => language.name).join(", ")}
                        />
                    </Col>
                    <Col span="24">
                        <ParagraphyWithLabel
                            labelIcon={<FaTransgender size={20} />}
                            label={"Sexo: "}
                            text={candidate.sex.name}
                        />
                    </Col>
                    <Col span="24">
                        <ParagraphyWithLabel
                            labelIcon={<MdWork size={20} />}
                            label={"Experiência: "}
                            text={candidate.experience.name}
                        />
                    </Col>
                    <Col span="24">
                        <ParagraphyWithLabel
                            labelIcon={<FaMoneyBillWave size={20} />}
                            label={"Salario atual: "}
                            text={candidate.current_salary ?
                                `${candidate.current_salary.toLocaleString('pt-PT', {
                                    style: 'currency',
                                    currency: 'EUR',
                                })}`
                                : NOT_REGISTERED}
                        />
                    </Col>
                    <Col span="24">
                        <ParagraphyWithLabel
                            labelIcon={<FaMoneyBillWave size={20} />}
                            label={"Pretenção salarial: "}
                            text={candidate.expectative_salary ?
                                `${candidate.expectative_salary.toLocaleString('pt-PT', {
                                    style: 'currency',
                                    currency: 'EUR',
                                })}`
                                : NOT_REGISTERED}
                        />
                    </Col>
                </Row>
            </Col>
            <Col {...columnOptions}>
                <Row>
                    <Col span="24">
                        <ParagraphyWithLabel
                            labelIcon={<GrStatusGoodSmall size={20} color={candidate.status_color.code_hex} />}
                            label={"Status geral: "}
                            text={candidate.status.name}
                        />
                    </Col>
                    <Col span="24">
                        <Typography.Paragraph type="secondary" style={{ marginBottom: "20px" }}>
                            <span> <AiOutlineMail size={20} /> E-mail: </span>
                            <span>
                                {
                                    candidate.mail ? (
                                        <Typography.Link target="_blank" href={`mailto:${candidate.mail}`}>
                                            {candidate.mail}
                                        </Typography.Link>
                                    ) : (
                                        <Typography.Text >
                                            {NOT_REGISTERED}
                                        </Typography.Text>
                                    )
                                }
                            </span>
                        </Typography.Paragraph>
                    </Col>
                    <Col span="24">
                        <Typography.Paragraph type="secondary" style={{ marginBottom: "20px" }} >
                            <span> <AiFillGithub size={20} /> GitHub: </span>
                            <span>
                                {
                                    candidate.github ? (
                                        <Typography.Link target="_blank" href={`${candidate.github}`}>
                                            {candidate.github}
                                        </Typography.Link>
                                    ) : (
                                        <Typography.Text >
                                            {NOT_REGISTERED}
                                        </Typography.Text>
                                    )
                                }
                            </span>
                        </Typography.Paragraph>
                    </Col>
                    <Col span="24">
                        <Typography.Paragraph type="secondary" style={{ marginBottom: "20px" }} >
                            <span> <AiFillLinkedin size={20} /> Linkedin: </span>{
                                <span>
                                    {
                                        candidate.linkedin ? (
                                            <Typography.Link target="_blank" href={`${candidate.linkedin}`}>
                                                {candidate.linkedin}
                                            </Typography.Link>
                                        ) : (
                                            <Typography.Text >
                                                {NOT_REGISTERED}
                                            </Typography.Text>
                                        )
                                    }
                                </span>
                            }
                        </Typography.Paragraph>
                    </Col>
                    <Col span="24">
                        <Typography.Paragraph type="secondary" style={{ marginBottom: "20px" }} >
                            <span> <AiOutlinePhone size={20} /> Telefone: </span>
                            <span>
                                {
                                    candidate.phone ? (
                                        <Typography.Link href={`tel://${candidate.phone}`}>
                                            {candidate.phone}
                                        </Typography.Link>
                                    ) : (
                                        <Typography.Text >
                                            {NOT_REGISTERED}
                                        </Typography.Text>
                                    )
                                }
                            </span>
                        </Typography.Paragraph>
                    </Col>
                    <Col span="24">
                        <ParagraphyWithLabel
                            labelIcon={<BsStackOverflow size={20} />}
                            label={"Tecnologias: "}
                            text={candidate.technologies.map(tec => tec.name).join(", ")}
                        />
                    </Col>
                </Row>
            </Col>
            <Divider>Financeiro</Divider>
            <Col>
                <Row justify={"space-between"} align={"middle"}>
                <Col>
                        <ParagraphyWithLabel
                            labelIcon={<FaMoneyBillWave size={20} />}
                            label={"Venda: "}
                            text={
                                candidate.reference_value_sell && (
                                    <CalcReferenceValue
                                    value={candidate.sell_value}
                                    reference={candidate.reference_value_sell.name}
                                />
                                )
                            }
                        />
                    </Col>
                    <Col>
                        <ParagraphyWithLabel
                            labelIcon={<FaMoneyBillWave size={20} />}
                            label={"Custo: "}
                            text={
                                candidate.reference_value_cost && (
                                    <CalcReferenceValue
                                    value={candidate.cost_value}
                                    reference={candidate.reference_value_cost.name}
                                />
                                )
                            }
                        />
                    </Col>
                    <Col>
                        <ParagraphyWithLabel
                            labelIcon={<FaMoneyBillWave size={20} />}
                            label={"Margem: "}
                            text={
                                (candidate.reference_value_cost && candidate.reference_value_sell) && (
                                    <span>
                                        <Typography.Paragraph>
                                            {
                                                `Hora: ± ${convertReferenceValueToHour().toLocaleString('pt-PT', {
                                                    style: 'currency',
                                                    currency: 'EUR',
                                                })}`
                                            }
                                        </Typography.Paragraph>
                                        <Typography.Paragraph>
                                            {
                                                `Dia: ± ${(convertReferenceValueToHour() * 8).toLocaleString('pt-PT', {
                                                    style: 'currency',
                                                    currency: 'EUR',
                                                })}`
                                            }
                                        </Typography.Paragraph>
                                        <Typography.Paragraph>
                                            {
                                                `Mês: ± ${(convertReferenceValueToHour() * 8 * 22).toLocaleString('pt-PT', {
                                                    style: 'currency',
                                                    currency: 'EUR',
                                                })}`
                                            }
                                        </Typography.Paragraph>
                                    </span>
                                )
                            }
                        />
                    </Col>
                </Row>
            </Col>
            <Divider>Anotações</Divider>
            <Col span={24}>
                <Typography.Paragraph type="secondary">
                    <MdNotes size={20} /> Anotações:
                </Typography.Paragraph>

                <Typography.Paragraph>
                    <div dangerouslySetInnerHTML={{ __html: candidate.notes }} />
                </Typography.Paragraph>
            </Col>
        </Row>
    )
}

export default CandidateModalTabGeneral;