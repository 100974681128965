import { useEffect, useState, forwardRef, useImperativeHandle } from "react";

import { Select } from "antd";

import _service from "@netuno/service-client";

import { failToLoadOptionsNotification } from "../../../../utils";

let timerSearch = null;

function PositionSelectMultiple({
    value,
    onChange,
    ...selectOptions
}, ref) {
    const [positions, setPositions] = useState([]);
    const [filters, setFilters] = useState({});
    const [loading, setLoading] = useState(false);

    const loadPositions = () => {
        setLoading(true);

        _service({
            url: "representative/position/list",
            method: "POST",
            data: {
                filters:{
                    ...filters,
                    active:true
                }
            },
            success: (res) => {
                setPositions(res.json.positions);

                setLoading(false);
            },
            fail: () => {
                failToLoadOptionsNotification("Cargos");

                setLoading(true);
            }
        });
    };

    useImperativeHandle(ref, () => ({
        loadPositions
    }));

    useEffect(() => {
        loadPositions();
    }, [filters]);

    return (
        <Select
            allowClear={!loading}
            optionFilterProp="label"
            mode="multiple"
            loading={loading}
            value={value}
            options={positions.map((position) => ({
                label: position.name,
                value: position.uid
            }))}
            onChange={(newValue) => {
                onChange(newValue);

                setFilters({
                    name: ""
                });
            }}
            onSearch={(search) => {
                if (timerSearch) {
                    clearTimeout(timerSearch);
                }

                timerSearch = setTimeout(() => {
                    setFilters(() => ({
                        name: search
                    }));
                }, 500);
            }}
            onDropdownVisibleChange={(isOpen) => {
                if (!isOpen && filters.name) {
                    setFilters(() => ({
                        name: ""
                    }));
                    return;
                }
                if (isOpen) {
                    loadPositions();
                }
            }}
            style={{
                width: "100%"
            }}
            {...selectOptions}
        />
    );
}

export default forwardRef(PositionSelectMultiple);