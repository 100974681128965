import React, { useEffect, useState } from "react";
import { Routes as Switch, Route, useLocation, useNavigate, Link, Navigate } from "react-router-dom";

import { ConfigProvider, Layout, Button } from 'antd';

import antLocale_ptPT from 'antd/lib/locale/pt_PT';

import { Provider } from 'react-redux';
import { Store } from './redux/store';

import classNames from 'classnames';

import _auth from '@netuno/auth-client';
import './common/Config';

import LoginPage from './pages/Login';
import RegisterPage from './pages/Register';
import RecoveryPage from './pages/Recovery';
import Profile from './pages/Profile';
import Companies from "./pages/Companies";
import Status from "./pages/Companies/Status";
import Service from "./pages/Companies/Service";
import Sector from "./pages/Companies/Sector";
import Representatives from "./pages/Representatives";
import Positions from "./pages/Representatives/Positions";
import Interactions from "./pages/Interactions";
import MeansOfContact from "./pages/Interactions/MeansOfContact";
import TypeOfContact from "./pages/Interactions/TypeOfContact";
import HeaderMenu from "./components/HeaderMenu";
import Candidates from "./pages/Candidates";
import Technologies from "./pages/Candidates/Technologies";
import Languages from "./pages/Candidates/Languages";
import CandidateStatus from "./pages/Candidates/CandidateStatus";
import ExperienceTime from "./pages/Candidates/ExperienceTime";
import FileTypes from "./pages/Candidates/FileTypes";
import Appointment from "./pages/Appointment";
import Schedule from "./pages/Schedule";
import NotFound from "./pages/NotFound";
import Schedulings from "./pages/Schedulings";
import SideMenu from "./components/SideMenu";

import './styles/App.less';

const { Header, Content, Footer } = Layout;

const NavWithAuthCheck = () => {
  if (_auth.isLogged()) {
    return (
      <Navigate to="/interactions" />
    );
  }
  return (
    <Navigate to="/login" />
  );
};

export default function App(props) {
  const [headerButtonMode, setHeaderButtonMode] = useState('login');
  const [collapsed, setCollapsed] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    _auth.config({
      onLogout: () => {
        navigate('/login');
      }
    });
  }, []);

  useEffect(() => {
    setHeaderButtonMode(location.pathname);
  }, [location]);

  function onCollapse() {
    setCollapsed(!collapsed);
  }

  return (
    <ConfigProvider
      locale={antLocale_ptPT}
      theme={{
        token: {
          colorPrimary: '#00B298',
          colorLink: '#00B298',
        }
      }}
    >
      <Provider store={Store}>
        <Layout className={'page ' + classNames({ 'auth ': _auth.isLogged() }) + classNames({ 'collapsed ': collapsed })}>
          {_auth.isLogged() &&
          <SideMenu collapsed={collapsed} onCollapse={onCollapse}/>}
          <Layout>
            <Header className={classNames({ 'auth ': _auth.isLogged() }) + classNames({ 'collapsed ': collapsed })}>
              {!_auth.isLogged() &&
                <Link to="/" className="logo-container"><img alt="logo" src="/images/logo.png" /></Link>
              }
              {headerButtonMode === '/login' ?
                <Link to="/register">
                  <Button type="primary">Criar conta</Button>
                </Link>
                : headerButtonMode === '/register' ?
                  <Link to="/login">
                    <Button type="primary">Iniciar sessão</Button>
                  </Link>
                : headerButtonMode.split("/")[1] === 'appointment' ?
                <a href="#form_schedulling">
                  <Button type="primary" >Agendar</Button>
                </a>
                  : _auth.isLogged() &&
                  <HeaderMenu />
              }
            </Header>
            <Content className={classNames({ 'auth ': _auth.isLogged() })}>
              <Switch>
                <Route exact path="/" element={<NavWithAuthCheck />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/register" element={<RegisterPage />} />
                <Route path="/recovery" element={<RecoveryPage />} />
                <Route path="/companies" element={<Companies />} />
                <Route path="/companies/status" element={<Status />} />
                <Route path="/companies/service" element={<Service />} />
                <Route path="/companies/sector" element={<Sector />} />
                <Route path="/representatives" element={<Representatives />} />
                <Route path="/representatives/positions" element={<Positions />} />
                <Route path="/interactions" element={<Interactions />} />
                <Route path="/interactions/meansOfContact" element={<MeansOfContact />} />
                <Route path="/interactions/typeOfContact" element={<TypeOfContact />} />
                <Route path="/candidates" element={<Candidates/>}/>
                <Route path="/candidates/technologies" element={<Technologies/>}/>
                <Route path="/candidates/languages" element={<Languages/>}/>
                <Route path="/candidates/status" element={<CandidateStatus/>}/>
                <Route path="/candidates/experience" element={<ExperienceTime/>}/>
                <Route path="/candidates/fileTypes" element={<FileTypes/>}/>
                <Route path="/" element={<Navigate to="/companies" />} />
                <Route path="/appointment/:code" element={<Appointment/>} />
                <Route path="/schedule" element={<Schedule/>} />
                <Route path="/Schedulings" element={<Schedulings/>} />
                <Route path="*" element={<NotFound/>} />
              </Switch>
            </Content>
            {!_auth.isLogged() &&
              <Footer>© sitana.pt {new Date().getFullYear()}</Footer>
            }
          </Layout>
        </Layout>
      </Provider>
    </ConfigProvider>
  );
}
