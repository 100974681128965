import { Select, notification } from "antd";
import { useEffect, useState } from "react";
import _service from "@netuno/service-client";

function ReferenceValueSelectOne({
    value,
    ...selectOptions
}) {
    
    const [referenceValues, setReferenceValues] = useState([]);
    const [loading, setLoading] = useState(false);

    const onLoadReferenceValues = () => {
        setLoading(true);
        _service({
            url:"candidate/reference_value/list",
            method:"POST",
            success:(res) => {
                setLoading(false);
                setReferenceValues(res.json.reference_values);
            },
            fail:(err) => {
                setLoading(false);
                notification.error({
                    message:"Carregar valores de referência ",
                    description:"Falha ao carregar valores de referência"
                })
            }
        })
    }

    useEffect(() => {
        onLoadReferenceValues();
    },[])

    return (
        <Select
            loading={loading}
            value={value}
            allowClear={!loading}    
            options={referenceValues.map((referenceValue)=>({
                label:referenceValue.name,
                value:referenceValue.uid
            }))}
            {...selectOptions}
        />
    )
    
}

export default ReferenceValueSelectOne;