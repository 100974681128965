import { useEffect, useState, useImperativeHandle, forwardRef } from "react";

import { Col, Row, Spin, notification, Empty, Pagination } from "antd";

import _service from "@netuno/service-client";

import InteractionPreviewListItem from "./Item";
import InteractionModalInfo from "../Modal/Info";

function InteractionPreviewList({
  filters,
  columnProps
}, ref) {
  const [interactionUID, setInteractionUID] = useState("");
  const [interactions, setInteractions] = useState([]);
  const [interactionsTotal, setInteractionsTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [emptyImage, setEmptyImage] = useState(false);
  const pageSize = 12;

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const InteractionListPagination = () => (
    <Row justify="center">
      <Col>
        <Pagination
          current={currentPage}
          total={interactionsTotal}
          pageSize={pageSize}
          onChange={onPageChange}
          style={{
            margin: "12px 0px"
          }}
        />
      </Col>
    </Row>
  );

  const loadInteractions = () => {
    setLoading(true);

    _service({
      url: "interaction/list",
      method: "POST",
      data: {
        filters,
        pagination: {
          page: currentPage,
          size: pageSize
        }
      },
      success: (res) => {
        setInteractions(res.json.interactions);
        setInteractionsTotal(res.json.interactions_total);
        setLoading(false);
        setEmptyImage(res.json.interactions_total > 0 ? false : true);
      },
      fail: () => {
        notification.error({
          message: "Interações",
          description: "Falha ao tentar carregar as interações."
        });
        setLoading(false);
        setEmptyImage(true);
      }
    });
  };

  useImperativeHandle(ref, () => ({
    loadInteractions
  }));

  useEffect(() => {
    if (currentPage === 1) {
      loadInteractions();

      return;
    }

    setCurrentPage(1);
  }, [filters]);

  useEffect(() => {
    loadInteractions();
  }, [currentPage]);

  if (loading) {
    return (
      <Row
        className="loading"
      >
        <Spin
          tip="Aguarde..."
          size="large"
        >
          <div className="content" />
        </Spin>
      </Row>
    );
  }

  if (emptyImage) {
    return (
      <Row
        span={24}
        align="middle"
        className="div_empty_result"
      >
        <Empty
          description={
            <span>
              Pesquisa não encontrada
            </span>
          }
        />
      </Row>
    );
  }

  return (
    <>
      <Row>
        <Col span="24">
          <InteractionListPagination />

          <InteractionModalInfo
            open={!!interactionUID}
            interactionUID={interactionUID}
            onCancel={() => {
              setInteractionUID("");
            }}
            onEditInteraction={() => {
              setInteractionUID("");

              loadInteractions();
            }}
            onToggleActive={(active, uid) => {
              setInteractions((prev) => {
                return prev.map((interaction) => {
                  if (interaction.uid === uid) {
                    return ({
                      ...interaction,
                      active
                    });
                  }

                  return interaction;
                });
              });
            }}
          />
        </Col>
      </Row>

      <Row
        align="top"
        justify="start"
        gutter={[4, 4]}
        style={{
          flexGrow: 1,
          alignContent: "baseline"
        }}
      >
        {
          interactions.map((interaction) => {
            return (
              <Col {...columnProps}>
                <InteractionPreviewListItem
                  interaction={interaction}
                  onClick={() => {
                    setInteractionUID(interaction.uid);
                  }}
                />
              </Col>
            );
          })
        }
      </Row>

      <Row>
        <Col span="24">
          <InteractionListPagination />
        </Col>
      </Row>
    </>
  );
}

export default forwardRef(InteractionPreviewList);
