import { Select, notification } from "antd";
import { useEffect, useState } from "react";
import _service from "@netuno/service-client";

let timerSearch = null;

function FileTypeSelectMult ({
    disabled,
    onChange,
    ...selectOptions
}) {
    
    const [fileTypes, setFileTypes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({});

    const onLoadFileTypes = () => {
        setLoading(true);
        _service({
            url:"candidate/file_type/list",
            method:"POST",
            data:{
                filters:{
                    ...filters,
                    active:true
                }
            },
            success: (res) => {
                setLoading(false);
                if (res.json) {
                    setFileTypes(res.json.file_types);
                }
            },
            fail: () => {
                setLoading(false);
                notification.error({
                    message:"Carregar tipo de arquivo",
                    description:"Falha ao carregar tipo de arquivo"
                })
            }
        })
    }

    useEffect(() => {
            onLoadFileTypes();
    },[filters]);

    return (
        <Select
            mode="multiple"
            optionFilterProp="label"
            placeholder={"Buscar por tipo de arquivo"}
            placement={"bottomRight"}
            onChange={onChange}
            loading={loading}
            allowClear={!loading}
            style={{width:"100%"}}
            disabled={disabled}
            onSearch={(value) => {
                if (timerSearch) {
                    clearTimeout(timerSearch);
                }

                timerSearch = setTimeout(() => {
                    setFilters((prev) => ({
                        ...prev,
                        name: value
                    }))
                }, 500)
            }}
            onDropdownVisibleChange={(isOpen)=>{
                if (!isOpen && filters.name) {
                    setFilters((prev) => ({
                        ...prev,
                        name: ""
                    }))
                    return;
                }
                if (isOpen) {
                    onLoadFileTypes(); 
                }
            }}
            options={fileTypes.map((fielType)=>({
                label: fielType.name,
                value: fielType.uid
            }))}
            {...selectOptions}
        />
    )
}

export default FileTypeSelectMult;