import {
    Col,
    Form,
    InputNumber,
    notification,
    Row,
    TimePicker,
    Select
} from "antd";
import _service from "@netuno/service-client";
import { useEffect } from "react";
import dayjs from "dayjs";

function ScheduleForm({
    form,
    schedule,
    onSubmitSuccess,
    onSubmitFail
}) {

    const daysOfWek = [
        {label:"Segunda", value:"monday"},
        {label:"Terça", value:"tuesday"},
        {label:"Quarta", value:"wednesday"},
        {label:"Quinta", value:"thursday"},
        {label:"Sexta", value:"friday"},
        {label:"Sábado", value:"saturday"},
        {label:"Domingo", value:"sunday"},
    ]

    const onFinish = (values) => {

        if (values.days_of_week[0] instanceof Object) {
            const days = values.days_of_week.map((d) => {
                return d.value
            })
            values = {
                ...values,
                days_of_week:days
            }
        }

        const [start, end] = values.times;

        const daysActive = {
            monday:false,
            tuesday:false,
            wednesday:false,
            thursday:false,
            friday:false,
            saturday:false,
            sunday:false
        };

        for (const day of daysOfWek) {
            daysActive[day.value] = values.days_of_week.includes(day.value);
        }

        const data = {
            start: start.format("HH:mm:ss"),
            end: end.format("HH:mm:ss"),
            interval_min: values.interval_min,
            ...daysActive
        };

        if (!schedule) {
            _service({
                url: "interview/schedule",
                method: "POST",
                data: data,
                success: (res) => {
                    notification.success({
                        message: "Cadastrar horários",
                        description: "Horários cadastrados com sucesso."
                    })
                    onSubmitSuccess();
                },
                fail: (err) => {
                        console.log(err)
                        if (err.status == 409) {
                            notification.error({
                                message: "Cadastrar horário",
                                description: "Os horários coincidem com horarios existentes."
                            })
                        } else {
                            notification.error({
                                message: "Cadastrar horários",
                                description: "Falha ao cadastrar horarios tente novamente mais tarde"
                            })
                        }
                        onSubmitFail();
                    }
                })
            }
        

        if (schedule) {
            data.uid = schedule.uid;
            console.log(schedule)
            _service({
                url: "interview/schedule",
                method: "PUT",
                data: data,
                success: (res) => {
                    notification.success({
                        message: "Edição de horário",
                        description: "Horário editado com sucesso"
                    })
                    onSubmitSuccess();
                },
                fail: (err) => {
                    onSubmitFail();
                    if (err.status == 409) {
                        notification.error({
                            message: "Editar horário",
                            description: "Os horários coincidem com horarios existentes."
                        })
                    } else {
                        notification.error({
                            message: "Editar horários",
                            description: "Falha ao editar horarios tente novamente mais tarde"
                        })
                    }
                }
            })
        }
    }

    useEffect(() => {
        if (schedule) {
            form.setFieldsValue({
                interval_min:schedule.interval_min,
                days_of_week: daysOfWek.filter((day) => (schedule[day.value] == true)),
                times:[
                    dayjs(schedule.start, "HH:mm:ss"),
                    dayjs(schedule.end, "HH:mm:ss")
                ]
            })
        }
    }, [schedule]);

    return (
        <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
        >
            <Row>
                <Col span={24}>
                    <Form.Item
                        label="Horário"
                        name="times"
                        rules={[{ required: true, message: "Informe um intervalo de horário" }]}
                    >
                        <TimePicker.RangePicker
                            style={{ width: "100%" }}
                            allowClear
                            format={"HH:mm"}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        label="Duração (minutos)"
                        name="interval_min"
                        rules={[{ required: true, message: "Informe uma duração em minutos" },
                        {
                            validator: (rule, value) => {
                                if (Number(value) > 60) {
                                    return Promise.reject("Duração maxima de 1h.");
                                } 
                                if (Number(value) % 10 > 0) {
                                    return Promise.reject("Valor inválido.");
                                }
                                return Promise.resolve();
                            }
                        }
                        ]}

                    >
                        <InputNumber
                            controls={false}
                            style={{ width: "100%" }}
                            formatter={(value) => value.replace(/0*(\d+)/, '$1')}
                            parser={(value) => value.replace(/0*(\d+)/, '$1')}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        label="Dias da semana"
                        name="days_of_week"
                        rules={[{ required: true, message: "Informe pelo menos um dia da semana"}]}
                    >
                        <Select
                            mode="multiple"
                            style={{ width: '100%' }}
                            placement="topLeft"
                            options={daysOfWek}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
}

export default ScheduleForm;