import { useState, useRef } from "react";

import { Button, Col, Row } from "antd";

import { PlusOutlined } from "@ant-design/icons";

import InteractionModalWithForm from "../../../../../Interaction/Modal/WithForm";
import InteractionFilter from "../../../../../Interaction/Filter";
import InteractionPreviewList from "../../../../../Interaction/PreviewList";
import "./style.less";

function CompanyModalInfoTabInteractions({
    company
}) {
    const [openInteractionModal, setOpenInteractionModal] = useState(false);
    const [filters, setFilters] = useState({
            companies_uids: [company.uid]
    });
    const interactionListRef = useRef(null);

    const onFilter = (values) => {
        const newFilters = {
            ...values,
            companies_uids: [company.uid]
        };

        setFilters(newFilters);
    };

    return (
        <Row gutter={[0, 12]}>
            <Col span="24">
                <Row justify="space-between" className="interactions-header">
                    <Col className="interactions-header__btn">
                        <Button
                            type="primary"
                            disabled={!company.active}
                            onClick={() => {
                                setOpenInteractionModal(true);
                            }}
                            icon={<PlusOutlined />}
                        >
                            Nova Interação
                        </Button>
                        <InteractionModalWithForm
                            open={openInteractionModal}
                            formConfig={{
                                readOnly: {
                                    company_uid: true
                                },
                                valuesDefault: {
                                    company_uid: {
                                        label: company.name,
                                        value: company.uid
                                    }
                                }
                            }}
                            onCancel={() => {
                                setOpenInteractionModal(false);
                            }}
                            onSubmitFinishSuccess={() => {
                                setOpenInteractionModal(false);
                                interactionListRef.current && interactionListRef.current.loadInteractions();
                            }}
                        />
                    </Col>
                    <Col span="12"  className="interactions-header__filter" >
                        <InteractionFilter
                            onFilter={onFilter}
                            formConfig={{
                                initialValues: {
                                    companies_uids: [{
                                        label: company.name,
                                        value: company.uid
                                    }]
                                },
                                disabled: {
                                    companies_uids: true
                                }
                            }}
                        />
                    </Col>
                </Row>
            </Col>
            <Col span="24" style={{padding:"0px 8px", height:"52vh"}}>
                <InteractionPreviewList
                    ref={interactionListRef}
                    filters={filters}
                    columnProps={{
                        xs: {
                            span: 24
                        },
                        sm: {
                            span: 24
                        },
                        md: {
                            span: 12
                        }
                    }}
                />
            </Col>
        </Row>
    );
}

export default CompanyModalInfoTabInteractions;
