import { useState } from "react";

import { Button, Col, Form, Row } from "antd";

import CompanyModal from "..";
import CompanyForm from "../../Form";

function CompanyModalWithForm({
    open,
    companyUID,
    onCancel,
    onSubmitFinishSuccess
}) {
    const [loading, setLoading] = useState(false);
    const [company, setCompany] = useState(null);

    const [form] = Form.useForm();
    const title = companyUID ?
        "Edição de Empresa" :
        "Cadastro de Empresa";

    const onLoadCompany = (companyData) => {
        setCompany(companyData);
    };

    const onClickSave = async () => {
        try {
            await form.validateFields();
            form.submit();
            setLoading(true);
        } catch (_err) {
            setLoading(false);
        }
    };

    return (
        <CompanyModal
            open={open}
            title={title}
            companyUID={companyUID}
            onCancel={() => {
                onCancel();
                form.resetFields();
            }}
            onLoadCompany={onLoadCompany}
            style={{
                maxWidth: "600px"
            }}
            width="90%"
            footer={(
                <Row justify="end">
                    <Col>
                        <Button
                            type="link"
                            onClick={() => {
                                onCancel();
                                form.resetFields();
                            }}
                        >
                            Cancelar
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            type="primary"
                            loading={loading}
                            onClick={onClickSave}
                        >
                            Salvar
                        </Button>
                    </Col>
                </Row>
            )}
        >
            <CompanyForm
                form={form}
                company={company}
                onCancel={onCancel}
                onSubmitFinishSuccess={() => {
                    onSubmitFinishSuccess();
                    setLoading(false);
                }}
            />
        </CompanyModal>
    );
}

export default CompanyModalWithForm;