import {
    Col,
    Row,
    Layout,
    Typography,
    Calendar,
    notification,
    Empty,
    Spin,
    Divider,
    Badge
} from "antd";
import { FaRegBuilding } from "react-icons/fa";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import _service from "@netuno/service-client";
import "./styles.less";

function Schedulings() {
    const [calendarInteview, setCalendarInteview] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dateSelected, setDateSelected] = useState(null);
    const [dateSchedulings, setDateSchedulings] = useState([]);

    const configColumn = {
         xs:{
            span: 24
          },
          sm:{
            span: 24
          },
          md:{
            span: 24
          },
          lg:{
            span: 12
          }
    }

    const loadCalendar = () => {
        const day = dateSelected || dayjs(new Date);

        _service({
            url: "interview/calendar",
            method: "GET",
            data: {
                start: day.startOf('month').format('YYYY-MM-DD HH:mm:ss'),
                end: day.endOf('month').format('YYYY-MM-DD HH:mm:ss')
            },
            success: ({ json }) => {
                setCalendarInteview(json.calendar);
            },
            fail: (err) => {
                notification.error({
                    message: "Carregar calendário",
                    description: "Falha ao carregar calendario"
                });
            }
        });
    }

    const loadDateSchedulings = () => {
        setLoading(true);
        _service({
            url: "interview/list",
            method: "POST",
            data: {
                start: dateSelected.startOf("day").format("YYYY-MM-DD HH:mm:ss"),
                end: dateSelected.endOf("day").format("YYYY-MM-DD HH:mm:ss")
            },
            success: ({ json }) => {
                setLoading(false);
                setDateSchedulings(json.schedullings);
            },
            fail: (err) => {
                setLoading(false);
                notification.error({
                    message: "Carregar agendamentos",
                    description: "Falha ao carregar agendamentos"
                })
            }
        })
    }

    useEffect(() => {
        loadCalendar();
    }, []);

    useEffect(() => {
        if (dateSelected) {
            loadDateSchedulings();
        }
        loadCalendar();
    }, [dateSelected])

    return (
        <Layout.Content className="page-content">
            <Row justify={"space-between"} align={"middle"}>
                <Col>
                    <Typography.Title
                        level={2}
                    >
                        <FaRegBuilding /> Agendamentos
                    </Typography.Title>
                </Col>
            </Row>
            <Row gutter={[12, 12]}>
                <Col {...configColumn} >
                    <Calendar className="schedule-calendar"
                        mode="month"
                        onSelect={(date) => {
                            setDateSelected(date);
                        }}
                        cellRender={(current, info) => {
                            const daySchedulings = [];
                            for (const date of calendarInteview) {
                                if (dayjs(date.moment, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD") === current.format("YYYY-MM-DD")) {
                                    daySchedulings.push(dayjs(date.moment, "YYYY-MM-DD HH:mm:ss").format("HH:mm"));
                                }
                            }
                    
                            if (daySchedulings.length > 0) {
                                return (<>
                                    {daySchedulings.map((time) => (
                                        <Typography.Paragraph style={{ marginBottom: '2px' }}>
                                            <Badge status="error" /> {time}
                                        </Typography.Paragraph>
                                    ))}
                                </>)
                            }
                        }}
                    />
                </Col>
                <Col {...configColumn} >
                     <Row  
                        className="schedule-info"
                        align="middle"
                            justify="center"
                        >
                     {(loading || !dateSelected || dateSchedulings.length == 0) && (
                        <Row
                            align="middle"
                            justify="center"
                            style={{
                                height: '100%'
                            }}
                        >
                            <Col>
                                {
                                    loading ? (
                                        <Spin
                                            size="large"
                                            style={{
                                                margin: 'auto 0px'
                                            }}
                                        />
                                    ) : (
                                        <Empty
                                            description={
                                                !dateSelected
                                                    ? 'Os horários do dia selecionado aparecerão aqui.'
                                                    : 'Você ainda não possui agendamentos para esse dia.'
                                            }
                                        />
                                    )
                                }
                            </Col>
                        </Row>
                    )}
                    {(dateSelected && dateSchedulings.length > 0 && !loading) && dateSchedulings.map((schedulling, index) => (
                        <Row justify={"center"} align={"top"} style={{width:"100%"}} gutter={[12,0]}>
                            <Col span={12}>
                                <Typography.Text className="schedule-info__label">
                                    Candidato
                                </Typography.Text>
                                <Typography.Paragraph>
                                    {schedulling.name}
                                </Typography.Paragraph>
                            </Col>
                            <Col span={12}>
                                <Typography.Text className="schedule-info__label">
                                    Horário
                                </Typography.Text>
                                <Typography.Paragraph>
                                    {dayjs(schedulling.moment, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm")}
                                </Typography.Paragraph>
                            </Col>
                            <Col span={12}>
                                <Typography.Text className="schedule-info__label">
                                    E-mail
                                </Typography.Text>
                                <Typography.Paragraph>
                                    {schedulling.email}
                                </Typography.Paragraph>
                            </Col>
                            <Col span={12}>
                                <Typography.Text className="schedule-info__label">
                                    Telefone
                                </Typography.Text>
                                <Typography.Paragraph>
                                    {schedulling.phone}
                                </Typography.Paragraph>
                            </Col>
                            {
                                index !== dateSchedulings.length - 1 && (
                                    <Divider />
                                )
                            }

                        </Row>
                    ))}    
                    </Row>   
                </Col>
            </Row>
        </Layout.Content>
    )

}
export default Schedulings;