import {
    useState,
    useEffect,
    forwardRef,
    useRef,
    useImperativeHandle
} from "react";

import {
    Col,
    Form,
    Row,
    Button,
    Tooltip,
    Checkbox,
    Switch
} from "antd";
import {
    FilterFilled
} from "@ant-design/icons";

import _service from "@netuno/service-client";

import CompanySelectMultiple from "../../Company/Select/Multiple";
import ModalCenterd from "../../Modal/Centered";
import RepresentativeSelectMultiple from "../Select/Multiple";
import PositionSelectMultiple from "../../Position/Select/Multiple";

function RepresentativeFilter({
    company,
    onFilter
}, ref) {
    const [openAdvancedFilter, setOpenAdvancedFilter] = useState(false);
    const [form] = Form.useForm();
    const companiesUIDs = Form.useWatch("companies_uids", form);
    const companySelectRef = useRef(null);
    const simpleRepresentativeSelectRef = useRef(null);
    const advancedRepresentativeSelectRef = useRef(null);
    const active = Form.useWatch("active", form);
    const filterActive = Form.useWatch("filter_active", form);

    const columnOptions = {
        xs: { span: 24 },
        sm: { span: 11 }
    }

    const onFinish = (values) => {
        Object.keys(values).forEach((key) => {
            if (Array.isArray(values[key])) {
                values[key] = values[key].map((value) => {
                    if (typeof (value) === "object") {
                        return value.value;
                    }

                    return value;
                });
            }
        });

        if (company) {
            values.companies_uids = [company.uid];
        }

        onFilter(values);
    };

    useImperativeHandle(ref, () => ({
        loadRepresentatives: () => {
            simpleRepresentativeSelectRef.current && simpleRepresentativeSelectRef.current.loadRepresentatives();
            advancedRepresentativeSelectRef.current && advancedRepresentativeSelectRef.current.loadRepresentatives();
        }
    }));

    useEffect(() => {
        simpleRepresentativeSelectRef.current && simpleRepresentativeSelectRef.current.loadRepresentatives();
        advancedRepresentativeSelectRef.current && advancedRepresentativeSelectRef.current.loadRepresentatives();
        form.setFieldValue("representatives_uids", []);
    }, [companiesUIDs]);

    return (
        <Row
            align="middle"
            justify="end"
            wrap={false}
            gutter={[12, 0]}
            style={{ width: "100%" }}
        >
            <Col>
                <Tooltip
                    title="Filtro avançado"
                >
                    <Button
                        type="link"
                        onClick={() => {
                            setOpenAdvancedFilter(true);
                        }}
                        icon={<FilterFilled />}
                        style={{
                            textAlign: "end"
                        }}
                    />
                </Tooltip>
                <ModalCenterd
                    open={openAdvancedFilter}
                    title="Filtro Avançado"
                    width="90%"
                    destroyOnClose={false}
                    onCancel={() => {
                        setOpenAdvancedFilter(false);
                    }}
                    footer={(
                        <Row
                            align="middle"
                            justify="end"
                        >
                            <Col>
                                <Button
                                    type="link"
                                    onClick={() => {
                                        setOpenAdvancedFilter(false);
                                    }}
                                >
                                    Cancelar
                                </Button>
                            </Col>
                            <Col>
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        setOpenAdvancedFilter(false);

                                        form.submit();
                                    }}
                                >
                                    Filtrar
                                </Button>
                            </Col>
                        </Row>
                    )}
                    style={{
                        maxWidth: "600px"
                    }}
                >
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={onFinish}
                        initialValues={{
                            active:true,
                            companies_uids: company ?
                                [{
                                    label: company.name,
                                    value: company.uid
                                }] :
                                []
                        }}
                    >
                        <Row
                            justify="space-between"
                            style={{ width: "100%" }}
                        >
                            <Col {...columnOptions} >
                                <Form.Item
                                    label="Empresas"
                                    name="companies_uids"
                                >
                                    <CompanySelectMultiple
                                        disabled={!!company}
                                        ref={companySelectRef}
                                    />
                                </Form.Item>
                            </Col>

                            <Col {...columnOptions} >
                                <Form.Item
                                    label="Representantes"
                                    name="representatives_uids"
                                >
                                    <RepresentativeSelectMultiple
                                        ref={advancedRepresentativeSelectRef}
                                        companiesUIDs={
                                            company ?
                                                [company.uid] :
                                                (
                                                    companiesUIDs ?
                                                        companiesUIDs :
                                                        []
                                                )
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24} >
                                <Form.Item
                                    label="Cargos"
                                    name="positions_uids"
                                >
                                    <PositionSelectMultiple
                                        placement="topLeft"
                                    />
                                </Form.Item>
                            </Col>
                            <Col {...columnOptions} >
                                <Row align="middle" gutter={[12, 12]}>
                                    <Col span={24}>
                                        <Form.Item
                                            name="filter_active"
                                            valuePropName="checked"
                                            noStyle
                                        >
                                            <Checkbox>
                                                Filtrar por ativado/desativado
                                            </Checkbox>
                                        </Form.Item>
                                    </Col>

                                    <Col>
                                        <Form.Item
                                            name="active"
                                            valuePropName="checked"
                                            noStyle
                                        >
                                            <Switch size="small" disabled={!filterActive} />
                                        </Form.Item>
                                    </Col>

                                    <Col >
                                        <label
                                            htmlFor="active"
                                        >
                                            {active ? "Ativado" : "Desativado"}
                                        </label>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </ModalCenterd>
            </Col>

            <Col
                style={{
                    flexGrow: 1
                }}
            >
                <Form
                    form={form}
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="representatives_uids"
                        style={{
                            margin: 0
                        }}
                    >
                        <RepresentativeSelectMultiple
                            ref={simpleRepresentativeSelectRef}
                            placeholder="Pesquisar por representantes"
                            companiesUIDs={
                                company ?
                                    [company.uid] :
                                    (
                                        companiesUIDs ?
                                            companiesUIDs :
                                            []
                                    )
                            }
                            onChange={() => {
                                form.submit();
                            }}
                        />
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    );
}

export default forwardRef(RepresentativeFilter);