import { Select, notification } from "antd";
import { useEffect, useState } from "react";
import _service from "@netuno/service-client";

let timerSearch = null;

function  LanguageSelectMult({
    disabled,
    onChange,
    placeholder,
    placement,
    ...selectOptions
}) {

    const [languages, setLanguages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({});

    const onLoadLanguages = () => {
        setLoading(true);
        _service({
            url:"candidate/language/list",
            method:"POST",
            data:{
                filters:{
                    ...filters,
                    active:true
                }   
            },
            success:(res)=>{
                setLoading(false);
                console.log(res.json)
               if (res.json) {
                    setLanguages(res.json.languages);
               }
            }, fail:(err)=>{
                setLoading(false);
                notification.error({
                    message:"Carregar idioma",
                    description:"Falha ao carregar idioma"
                })
            }
        })
    }

    useEffect(()=>{
            onLoadLanguages();
    },[filters])

     return (
        <Select
           mode="multiple"
           placement={placement}
           placeholder={placeholder}
           optionFilterProp="label"
           onChange={onChange}
           style={{width:"100%"}}
           allowClear={!loading}
           disabled={disabled}
           loading={loading}
           onSearch={(value) => {
            if (timerSearch) {
                clearTimeout(timerSearch);
            }

            timerSearch = setTimeout(() => {
                setFilters((prev) => ({
                    ...prev,
                    name: value
                }))
            }, 500)
        }}
        onDropdownVisibleChange={(isOpen)=>{
            if (!isOpen && filters.name) {
                setFilters((prev) => ({
                    ...prev,
                    name: ""
                }))
                return;
            } 
            if (isOpen) {
                onLoadLanguages();
            }
        }}
           options={languages.map((language)=>({
            label:language.name,
            value: language.uid
           }))}
           {...selectOptions}     
        />
        
     )
}

export default LanguageSelectMult;