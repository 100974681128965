import {
    Row,
    Col,
    Spin,
    Empty,
    notification,
    Typography,
    Button,
    Card,
    Popconfirm,
    Pagination
} from "antd";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import _service from "@netuno/service-client";
import {
    DeleteOutlined,
    EyeOutlined,
    FilePdfOutlined,
    FileImageOutlined,
    FileOutlined
} from "@ant-design/icons";
import { MdNotes, MdOutlineDateRange } from "react-icons/md"
import "./styles.less"
import config from "../../../../../../../config/config.json";

//const candidateUploads = `${config.api.uploads}candidate_upload/file`;
const candidateUploads = "https://crm.sitana.pt/admin/Download.netuno?type=storage-database&path=candidate_upload/file";

function FileList({
    candidate
}, ref) {

    const columnConfig = {
        xs: {
            span: 24
        },
        sm: {
            span: 24
        },
        md: {
            span: 12
        }
    }

    const [files, setFiles] = useState([]);
    const [filesTotal, setFilesTotal] = useState(0);
    const [emptyImage, setEmptyImage] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currentPage, serCurrentPage] = useState(1);
    const pageSize = 5;

    const onChangePage = (page) => {
        serCurrentPage(page);
    }

    const FilePagination = () => {
        return (
            <Row justify={"center"}>
                <Col>
                    <Pagination
                        total={filesTotal}
                        current={currentPage}
                        onChange={onChangePage}
                        pageSize={pageSize}
                        style={{
                            margin: "12px 0px"
                        }}
                    />
                </Col>
            </Row>
        )
    }

    const onLoadFiles = () => {
        setLoading(true);
        _service({
            url: "candidate/upload/list",
            method: "POST",
            data: { 
                candidateUID: candidate.uid,
                pagination:{pageSize:pageSize, page:currentPage} 
            },
            success: (res) => {
                if (res.json) {
                    console.log(res.json);
                    setFiles(res.json.files);
                    setFilesTotal(res.json.files_total);
                    setEmptyImage(res.json.files_total > 0 ? false : true);
                }
                setLoading(false);
            },
            fail: (err) => {
                setEmptyImage(true);
                setLoading(false);
                notification.error({
                    message: "Carregar arquivos",
                    description: "Falha ao carregar arquivos"
                });
            }
        })
    }

    const onDeleteFile = (uid) => {
        setLoading(true);
        _service({
            url: "candidate/upload",
            method: "DELETE",
            data: { uid },
            success: (res) => {
                setLoading(false);
                notification.success({
                    message: "Eliminar arquivo!",
                    description: "Arquivo eliminado com sucesso"
                })
                setFiles(files.filter((file) => file.uid != uid));
                setFilesTotal((prev)=>{
                    if (prev === 1) {
                        setEmptyImage(true);
                    }
                    return (prev - 1);
                })
            },
            fail: (err) => {
                setLoading(false);
                notification.error({
                    message: "Eliminar arquivo!",
                    description: "Falha ao eliminar arquivo"
                })
            }
        })
    }

    useEffect(() => {
        if (candidate.uid) {
            onLoadFiles();
        }
    }, [currentPage]);

    useImperativeHandle(ref, () => ({
        onLoadFiles
    }))

    if (loading) {
        return (
            <Row
                align="middle"
                justify="center"
                className="loading"
                style={{ height: "50vh" }}
            >
                <Col span="24">
                    <Spin
                        tip="Aguarde..."
                        size="large"
                    >
                        <div className="content" />
                    </Spin>
                </Col>
            </Row>
        )
    }

    if (emptyImage) {
        return (
            <Row
                span={24}
                align="middle"
                className="div_empty_result"
                style={{ height: "50vh" }}
            >
                <Empty
                    description={
                        <span>
                            Nenhum arquivo encontrado
                        </span>
                    }
                />
            </Row>
        );
    }

    return (
        <>
            {
                filesTotal > 0 && (
                    <Row>
                       <Col span={24}>
                            <FilePagination/>
                       </Col>
                    </Row>
                )
            }

            <Row gutter={[12, 12]} justify={"start"}>
                {files.length > 0 && files.map((file, index) => (
                    <Col {...columnConfig} key={index}>

                        <Card size="small"
                            title={
                                <Col>
                                    {file.extension === "pdf" ? (
                                        <FilePdfOutlined className="icons" />
                                    ) : (
                                        <FileImageOutlined className="icons" />
                                    )}
                                </Col>
                            }
                            extra={
                                <Row justify={"end"} wrap={false}>
                                    <Col>
                                        <Button
                                            type="link"
                                            target="_blank"
                                            href={`${candidateUploads}/${file.file}`}
                                            icon={<EyeOutlined />}
                                            title="Visualizar"
                                        />
                                    </Col>
                                    <Col>
                                        <Popconfirm
                                            title="Eliminar arquivo"
                                            disabled={!candidate.active}
                                            description="Confirma a ação de eliminar ?"
                                            onConfirm={() => {
                                                onDeleteFile(file.uid);
                                            }}
                                            okText="Sim"
                                            cancelText="Não"
                                        >
                                            <Button
                                                type="link"
                                                disabled={!candidate.active}
                                                icon={<DeleteOutlined />}
                                                title="Eliminar arquivo"
                                            />
                                        </Popconfirm>
                                    </Col>
                                </Row>
                            }
                        >
                            <Row align={"middle"} justify={"space-between"} wrap={true}>
                                <Col span={24}>
                                    <Row justify={"space-between"} align={"middle"}>
                                        <Col>
                                            <Typography.Paragraph type="secondary">
                                                <FileOutlined /> Tipo
                                            </Typography.Paragraph>
                                        </Col>
                                        <Col>
                                            <Typography.Paragraph> {file.file_type.name} </Typography.Paragraph>
                                        </Col>
                                    </Row>
                                    <Row justify={"space-between"} align={"middle"}>
                                        <Col>
                                            <Typography.Paragraph type="secondary">
                                                <MdNotes /> Descrição
                                            </Typography.Paragraph>
                                        </Col>
                                        <Col>
                                            <Typography.Paragraph> {file.description} </Typography.Paragraph>
                                        </Col>
                                    </Row>
                                    <Row justify={"space-between"} align={"middle"}>
                                        <Col>
                                            <Typography.Paragraph type="secondary">
                                                <MdOutlineDateRange /> Modificado em
                                            </Typography.Paragraph>
                                        </Col>
                                        <Col>
                                            <Typography.Paragraph> {file.modification_date} </Typography.Paragraph>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                ))

                }
            </Row>

            {
                filesTotal > 0 && (
                    <Row  style={{ marginTop: "auto", paddingTop: "12px" }} >
                       <Col span={24}>
                            <FilePagination/>
                       </Col>
                    </Row>
                )
            }

        </>
    )
}

export default forwardRef(FileList);