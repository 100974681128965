import { useState } from "react";

import { Card, Row, Col, Button, Switch, Tooltip, notification, Typography } from "antd";
import { EditOutlined } from "@ant-design/icons";

import _service from "@netuno/service-client";

import SectorModalWithForm from "../../../components/Sector/Modal/WithForm";

import "./styles.less";

function SectorCard({
    sector,
    formSearch,
    onToggleActive
}) {
    const [modalOpenEdit, setModalOpenEdit] = useState(false);
    const [loadingToggleActive, setLoadingToggleActive] = useState(false);

    const toggleActive = () => {
        setLoadingToggleActive(true);

        _service({
            url: "sector",
            method: "PUT",
            data: {
                ...sector,
                active: !sector.active
            },
            success: () => {
                setLoadingToggleActive(false);
                notification.success({
                    message: `${sector.active ? "Desativar" : "Ativar"} Setor`,
                    description: `Setor ${sector.active ? "desativado" : "ativado"} com sucesso.`
                });

                onToggleActive(!sector.active, sector.uid);
            },
            fail: () => {
                setLoadingToggleActive(false);
                notification.error({
                    message: `${sector.active ? "Desativar" : "Ativar"} Setor`,
                    description: `Falha ao tentar ${sector.active ? "desativar" : "ativar"} o setor.`
                });
            }
        });
    };

    return (
        <Card
            className="sector-card"
            size="small"
        >
            <Row
                align="middle"
                justify="space-between"
                gutter={[4, 0]}
            >
                <Col >
                    <Typography.Title
                        level={5}
                        style={{
                            margin: 0
                        }}
                    >
                        {sector.name}
                    </Typography.Title>
                </Col>
                <Col>
                    <Row align="middle" gutter={[12, 0]}>
                        <Col>
                            <Tooltip
                                title={`${sector.active ? "Desativar" : "Ativar"} setor.`}
                            >
                                <Switch
                                    size="small"
                                    loading={loadingToggleActive}
                                    checked={sector.active}
                                    onClick={toggleActive}
                                />
                            </Tooltip>
                        </Col>

                        <Col>
                            <Button type="link" icon={<EditOutlined />}
                                title="Editar"
                                disabled={!sector.active}
                                onClick={() => {
                                    setModalOpenEdit(true);
                                }}
                            />
                            <SectorModalWithForm
                                open={modalOpenEdit}
                                sectorUID={sector.uid}
                                onCancel={() => {
                                    setModalOpenEdit(false);
                                }}
                                onSubmitFinishSuccess={() => {
                                    setModalOpenEdit(false);

                                    formSearch.submit();
                                }}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Card >
    );
}

export default SectorCard;
