import { Layout, Row, Col, Typography, FloatButton } from "antd"
import { UserOutlined } from "@ant-design/icons";
import { FaPlus } from "react-icons/fa";

import CandidateModalWithForm from "../../components/Candidate/Modal/WithForm";
import CandidateList from "../../components/Candidate/List";
import CandidateFilter from "../../components/Candidate/Filter";
import { useState, useRef } from "react";

function Candidates() {

    const [openCandidateModalWithForm, setOpenCandidateModalWithForm] = useState(false);
    const candidateListRef = useRef(null);

    const [filters, setFilters] = useState({});

    return (
        <Layout.Content className="page-content">
            <Row justify="space-between">
                <Col style={{ marginRight: "20px" }}>
                    <Typography.Title level={2}>
                        <UserOutlined/> Candidatos
                    </Typography.Title>
                </Col>
                <Col style={{ width: "500px", marginRight: "11px" }}>
                    <CandidateFilter
                        onFilter={(values) => {
                            setFilters((prev)=>({
                                ...prev,
                                ...values,
                                active: values.filter_active ? values.active : undefined
                            }))
                        }}
                    />
                </Col>
            </Row>
                <CandidateList
                    ref={candidateListRef}
                    filters={filters}
                    columnConfig={{
                        xs: {
                            span: 24
                        },
                        sm: {
                            span: 24
                        },
                        md: {
                            span: 12
                        },
                        lg: {
                            span: 8
                        }
                    }}
                />
            <FloatButton
                    style={{ border: '2px solid #00B298' }}
                    icon={<FaPlus color="#00B298" />}
                    onClick={()=>{setOpenCandidateModalWithForm(true)}}
                />

         <CandidateModalWithForm
            open={openCandidateModalWithForm}
            onCancel={()=>{setOpenCandidateModalWithForm(false)}}
            onSubmitFormSuccess={() => {
                setOpenCandidateModalWithForm(false);
                candidateListRef.current && candidateListRef.current.onLoadCandidates();
            }}
         />       
        </Layout.Content>
    )
    
}

export default Candidates;