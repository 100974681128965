import { Layout, Menu } from 'antd';
import { MenuOutlined, UserOutlined, CalendarOutlined } from '@ant-design/icons';
import { FaRegBuilding } from "react-icons/fa";
import { MdConnectWithoutContact } from "react-icons/md";
import { useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';

const { Sider } = Layout;

function SideMenu({
    loggedUserInfo,
    onCollapse,
    collapsed
}) {

    const [sideMenuMobileMode, setSideMenuMobileMode] = useState(false);
    const navigate = useNavigate();

    const [itemsMenu, setItemsMenu] = useState([
        {
            key: 1,
            label: "Empresas",
            icon: <FaRegBuilding />,
            onClick: () => navigate("/companies")
        },
        {
            key: 2,
            label: "Representantes",
            icon: <UserOutlined />,
            onClick: () => navigate("/representatives")
        },
        {
            key: 3,
            label: "Interações",
            icon: <MdConnectWithoutContact size={20} />,
            onClick: () => navigate("/interactions")
        },
        {
            key: 4,
            label: "Candidatos",
            icon: <UserOutlined size={20} />,
            onClick: () => navigate("/candidates")
        },
        {
            key: "schedulings",
            label: "Agendamentos",
            icon: <CalendarOutlined size={20} />,
            onClick: () => navigate("/schedulings")
        },])

    useEffect(() => {
        if (loggedUserInfo) {
            setItemsMenu((prev) => prev.map((item) => {
                if (item.key === "schedulings") {
                    return ({
                        ...item,
                        hidden: !loggedUserInfo.admin
                    })
                }
                return item
            }))
        }
    }, [loggedUserInfo])

    return (
        <>
            <Sider
                onBreakpoint={mobile => {
                    setSideMenuMobileMode(mobile);
                }}
                collapsedWidth={sideMenuMobileMode ? '0' : '80'}
                breakpoint={"md"}
                collapsible
                collapsed={collapsed}
                onCollapse={onCollapse}
                trigger={<MenuOutlined />}
                theme="light"
            >
                <div className="logo-container"><img alt="logo" src="/images/logo.png" /></div>
                <Menu
                    defaultSelectedKeys={['1']}
                    mode="inline"
                    items={itemsMenu}
                />
            </Sider>
        </>
    )
}

const mapStateToProps = (store) => {
    const { loggedUserInfo } = store.loggedUserInfoState;

    return {
        loggedUserInfo
    };
};

export default connect(mapStateToProps, {})(SideMenu);