import {
    useState,
    useEffect,
    useRef
} from "react";

import {
    Col,
    DatePicker,
    Form,
    Input,
    notification,
    Row,
    Select
} from "antd";

import _service from "@netuno/service-client";

import moment from 'moment';

import dayjs from 'dayjs';

import {
    INSERT_TITLE,
    SELECT_COMPANY,
    SELECT_CONTACT_MEAN,
    SELECT_CONTACT_TYPE,
    SELECT_DATE,
    SELECT_REPRESENTATIVE
} from "../../../consts";
import {
    failToLoadOptionsNotification
} from "../../../utils";
import CompanySelectOne from "../../Company/Select/One";
import RepresentativeSelectOne from "../../Representative/Select/One";

function InteractionForm({
    form,
    onSubmitFinishSuccess,
    onSubmitFinishFail,
    ...formConfig
}) {
    const [contactMeans, setContactMeans] = useState([]);
    const [contactTypes, setContactTypes] = useState([]);
    const [fieldsState, setFieldsState] = useState({
        contact_mean_uid: {
            loading: false
        },
        contact_type_uid: {
            loading: false
        }
    });
    const representativeSelecOneRef = useRef(0);
    const companyUID = Form.useWatch("company_uid", form);

    
    const columnOptions = {
        xs: { span: 24 },
        sm: { span: 11 }
    }

    const getSelectOptionValue = (value) => {
        if (typeof (value) === "object") {
            return value.value;
        }

        return value;
    };

    const loadContactMeans = () => {
        setFieldsState((prev) => ({
            ...prev,
            contact_mean_uid: {
                loading: true
            }
        }));

        _service({
            url: "interaction/contact_mean/list",
            method: "POST",
            data:{
                filters:{active:true}
            },
            success: (res) => {
                setFieldsState((prev) => ({
                    ...prev,
                    contact_mean_uid: {
                        loading: false
                    }
                }));
                setContactMeans(res.json.contact_means);
            },
            fail: () => {
                setFieldsState((prev) => ({
                    ...prev,
                    contact_mean_uid: {
                        loading: false
                    }
                }));
                failToLoadOptionsNotification("Meios de Contato");
            }
        });
    };

    const loadContactTypes = () => {
        setFieldsState((prev) => ({
            ...prev,
            contact_type_uid: {
                loading: true
            }
        }));

        _service({
            url: "interaction/contact_type/list",
            method: "POST",
            data:{filters:{active:true}},
            success: (res) => {
                setFieldsState((prev) => ({
                    ...prev,
                    contact_type_uid: {
                        loading: false
                    }
                }));
                setContactTypes(res.json.contact_types);
            },
            fail: () => {
                setFieldsState((prev) => ({
                    ...prev,
                    contact_type_uid: {
                        loading: false
                    }
                }));
                failToLoadOptionsNotification("Tipos de Contato");
            }
        });
    };

    const onFinish = (values) => {
        const selects = [
            "company_uid",
            "representative_uid",
            "means_of_contact_uid",
            "type_of_contact_uid"
        ]
        const data = {
            ...values,
            company_uid: companyUID,
            moment: moment(values.moment.toString()).format("YYYY-MM-DD HH:mm:00")
        };

        selects.forEach((select) => {
            data[select] = getSelectOptionValue(data[select]);
        });

        if (!formConfig?.valuesDefault?.uid) {
            _service({
                url: "interaction",
                method: "POST",
                data,
                success: () => {
                    notification.success({
                        message: "Cadastro de Interação",
                        description: "Interação cadastrada com sucesso."
                    });
                    form.resetFields();
                    onSubmitFinishSuccess();
                },
                fail: () => {
                    notification.error({
                        message: "Cadastro de Interação",
                        description: "Falha ao tentar cadastrar uma nova interação."
                    });
                    onSubmitFinishFail();
                }
            });
        }

        if (formConfig?.valuesDefault?.uid) {
            data.uid = formConfig.valuesDefault.uid;

            _service({
                url: "interaction",
                method: "PUT",
                data,
                fail: (err) => {
                    console.error("FormInteraction - create interaction", err);
                    notification.error({
                        message: 'Falha ao Editar',
                        description: 'Ocorreu um erro ao editar a interação, por favor tente novamente mais tarde ou entre em contato com o suporte.'
                    });
                    onSubmitFinishFail();
                },
                success: () => {
                    notification.success({
                        message: 'Editado com Sucesso',
                    });
                    form.resetFields();
                    onSubmitFinishSuccess();
                }
            });
        }
    };

    useEffect(() => {
        loadContactMeans();
        loadContactTypes();
    }, []);

    useEffect(() => {
        representativeSelecOneRef?.current?.loadRepresentatives();
    }, [companyUID]);

    return (
        <Form
            form={form}
            onFinish={onFinish}
            initialValues={{
                ...formConfig?.valuesDefault,
                moment: formConfig?.valuesDefault?.moment ? dayjs(formConfig?.valuesDefault?.moment) : "",
                means_of_contact_uid: formConfig?.valuesDefault?.means_of_contact ? formConfig?.valuesDefault?.means_of_contact?.uid : "",
                type_of_contact_uid: formConfig?.valuesDefault?.type_of_contact ? formConfig?.valuesDefault?.type_of_contact?.uid : ""
            }}
            layout="vertical"
        >
            <Row 
                justify="space-between"
            >
                <Col span="24">
                    <Form.Item
                        name="title"
                        label="Título"
                        rules={[
                            {
                                required: true,
                                message: INSERT_TITLE
                            },
                        ]}
                    >
                        <Input type="text" />
                    </Form.Item>
                </Col>

            </Row>

            <Row 
                justify="space-between"
            >
                <Col {...columnOptions}>
                    <Form.Item
                        name="company_uid"
                        label="Empresa"
                        rules={[
                            {
                                required: true,
                                message: SELECT_COMPANY
                            },
                        ]}
                    >
                        <CompanySelectOne
                            disabled={formConfig?.readOnly?.company_uid ? true : undefined}
                        />
                    </Form.Item>
                </Col>

                <Col {...columnOptions}>
                    <Form.Item
                        name="representative_uid"
                        label="Representante"
                        rules={[
                            {
                                required: true,
                                message: SELECT_REPRESENTATIVE
                            },
                        ]}
                        {...(!companyUID ? { help: "Selecione uma empresa para poder selecionar um representante." } : {})}
                    >
                        <RepresentativeSelectOne
                            ref={representativeSelecOneRef}
                            companiesUIDs={companyUID ? [getSelectOptionValue(companyUID)] : []}
                            disabled={!companyUID || formConfig?.readOnly?.representative_uid}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row 
                justify="space-between"
            
            >
                <Col {...columnOptions}>
                    <Form.Item
                        name="means_of_contact_uid"
                        label="Meio de contato"
                        rules={[
                            {
                                required: true,
                                message: SELECT_CONTACT_MEAN
                            },
                        ]}
                    >
                        <Select
                            loading={fieldsState.contact_mean_uid.loading}
                            options={contactMeans.map((meansOfContact) => ({
                                label: meansOfContact.name,
                                value: meansOfContact.uid
                            }))}
                        />
                    </Form.Item>
                </Col>

                <Col {...columnOptions}>
                    <Form.Item
                        name="type_of_contact_uid"
                        label="Tipo de contato"
                        rules={[
                            {
                                required: true,
                                message: SELECT_CONTACT_TYPE
                            },
                        ]}
                    >
                        <Select
                            loading={fieldsState.contact_type_uid.loading}
                            options={contactTypes.map((typeOfContact) => ({
                                label: typeOfContact.name,
                                value: typeOfContact.uid
                            }))}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row justify="space-between">
                <Col span="24">
                    <Form.Item
                        name="moment"
                        label="Data"
                        rules={[
                            {
                                required: true,
                                message: SELECT_DATE
                            },
                        ]}
                    >
                        <DatePicker
                            placeholder=""
                            style={{
                                width: "100%"
                            }}
                            showTime={{
                                format: 'HH:mm',
                            }}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row justify="space-between" >
                <Col span="24">
                    <Form.Item
                        name="content"
                        label="Anotações"
                    >
                        <Input.TextArea />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
}

export default InteractionForm;