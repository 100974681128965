import { useState, useEffect, forwardRef, useImperativeHandle } from "react";

import { notification, Row, Col, Button, Divider, Spin, Empty, Typography, Popconfirm, Pagination, Tag } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

import { connect } from "react-redux";

import _service from "@netuno/service-client";

import moment from "moment";

import InteractionCommentModalWithForm from "../Modal/WithForm";

function InteractionCommentList({
    interaction,
    loggedUserInfo
}, ref) {
    const [comments, setComments] = useState([]);
    const [commentsTotal, setCommentsTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [commentUID, setCommentUID] = useState(null);
    const [loading, setLoading] = useState(false);
    const [emptyImage, setEmptyImage] = useState(false);
    const pageSize = 5;

    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    const InteractionCommentListPagination = () => (
        <Row justify="center">
            <Col>
                <Pagination
                    current={currentPage}
                    total={commentsTotal}
                    pageSize={pageSize}
                    onChange={onPageChange}
                    style={{
                        margin: "12px 0px"
                    }}
                />
            </Col>
        </Row>
    );

    const loadComments = (config) => {
        if (config) {
            if (config.page && config.page !== currentPage) {
                setCurrentPage(config.page);

                return;
            }
        }

        setLoading(true);

        _service({
            url: "interaction/comment/list",
            method: "POST",
            data: {
                interaction_uid: interaction.uid,
                pagination: {
                    page: currentPage,
                    size: pageSize
                }
            },
            success: (res) => {
                setComments(res.json.comments);
                setCommentsTotal(res.json.comments_total);
                setLoading(false);
                setEmptyImage(res.json.comments_total > 0 ? false : true);
            },
            fail: () => {
                notification.error({
                    message: "Comentários",
                    description: "Falha ao tentar carregar comentários."
                });
                setLoading(false);
                setEmptyImage(true);
            }
        });
    };

    const deleteComment = (uid) => {
        const config = {};

        if (comments.length === 1) {
            if (currentPage !== 1) {
                config.page = currentPage - 1;
            }
        }

        _service({
            url: "interaction/comment",
            method: "DELETE",
            data: {
                uid
            },
            success: () => {
                loadComments(config);
                notification.success({
                    message: "Comentário",
                    description: "Comentário apagado com sucesso."
                });
            },
            fail: () => {
                notification.error({
                    message: "Comentários",
                    description: "Falha ao tentar carregar comentários."
                });
                setLoading(false);
            }
        });
    };

    useImperativeHandle(ref, () => ({
        loadComments
    }));

    useEffect(() => {
        loadComments();
    }, [currentPage]);

    if (loading) {
        return (
            <Row
            className="loading"
            style={{height:"50vh"}}
        >
            <Spin
                tip="Aguarde..."
                size="large"
            >
                <div className="content" />
            </Spin>
        </Row>
        );
    }

    if (emptyImage) {
        return (
            <Row justify="center" align="middle">
                <Col className="div_empty_result" style={{height:"50vh"}}>
                    <Empty
                        description={(
                            <Row align="middle" justify="middle">
                                <Col span="24">
                                    Nenhum comentário encontrado.
                                </Col>
                            </Row>
                        )}
                    />
                </Col>
            </Row>
        );
    }

    return (
        <>
            <Row>
                {
                    comments.map((comment, index) => (
                        <>
                            <Col span="24">
                                <Row>
                                    <Col span="24">
                                        <Row
                                            align="center"
                                            justify="space-between"
                                        >
                                            <Col>
                                                <Typography.Paragraph style={{ marginBottom: "2px" }}>
                                                    {comment.author.name}
                                                </Typography.Paragraph>
                                            </Col>
                                            <Col>
                                                {
                                                    comment.author.uid === loggedUserInfo.uid && (
                                                        <>
                                                            <Button
                                                                type="link"
                                                                disabled={!interaction.active}
                                                                onClick={() => {
                                                                    setCommentUID(comment.uid);
                                                                }}
                                                                icon={<EditOutlined />}
                                                            />
                                                            <Popconfirm
                                                                title="Deseja eliminar o comentário?"
                                                                okText="Sim"
                                                                disabled={!interaction.active}
                                                                cancelText="Não"
                                                                onConfirm={() => {
                                                                    deleteComment(comment.uid);
                                                                }}
                                                            >
                                                                <Button
                                                                    type="link"
                                                                    disabled={!interaction.active}
                                                                    icon={<DeleteOutlined />}
                                                                />
                                                            </Popconfirm>
                                                        </>
                                                    )
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span="24">
                                        <Typography.Paragraph type="secondary">
                                            <Tag>
                                                {moment(comment.moment).format("DD/MM/YYYY")}
                                            </Tag>
                                            <Tag>
                                                {moment(comment.moment).format("hh:mm")}
                                            </Tag>
                                        </Typography.Paragraph>
                                    </Col>
                                    <Col span="24">
                                        <div dangerouslySetInnerHTML={{ __html: comment.message }} />
                                    </Col>
                                </Row>
                            </Col>
                            {
                                index !== comments.length - 1 && (
                                    <Col span="24">
                                        <Divider />
                                    </Col>
                                )
                            }
                        </>
                    ))
                }
            </Row>
            <InteractionCommentModalWithForm
                open={commentUID}
                commentUID={commentUID}
                formConfig={{
                    valuesDefault: {
                        interaction_uid: interaction.uid
                    }
                }}
                onCancel={() => {
                    setCommentUID("");
                }}
                onSubmitFinishSuccess={() => {
                    setCommentUID("");

                    loadComments();
                }}
            />
            <InteractionCommentListPagination />
        </>
    );
}

const mapStateToProps = store => {
    const { loggedUserInfo } = store.loggedUserInfoState;
    return {
        loggedUserInfo
    };
};


export default connect(mapStateToProps, {}, null, { forwardRef: true })(forwardRef(InteractionCommentList));