import {
    useState
} from "react";

import {
    Card,
    Col,
    Row,
    Typography,
    Tooltip
} from "antd";

import {
    FaRegBuilding,
    FaRegIdBadge
} from "react-icons/fa";
import { CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';

import RepresentativeModalWithForm from "../../Modal/WithForm";

import _service from "@netuno/service-client";
import RepresentativeModalInfo from "../../Modal/info";
import "./style.less"

function CardRepresentative({
    representative,
    columnConfig,
    onLoadRepresentatives,
    onClickActive,
}) {
    const [openRepresentativeModalEdit, setOpenRepresentativeModalEdit] = useState(false);
    const [openRepresentativeModalInfo, setOpenInteractionModalInfo] = useState(false);

    return (
        <Col {...columnConfig}>
            <Card
                className="representative-card"
                title={representative.name}
                size="small"
                onClick={() => {
                    setOpenInteractionModalInfo(true)
                }}
                extra={
                    <Col>
                    {representative.active ? (
                        <Tooltip title="Representante Ativo">
                            <CheckCircleOutlined
                                style={{ color: "green" }}
                            />
                        </Tooltip>
                    ) : (
                        <Tooltip title="Representante Desativado">
                            <CloseCircleOutlined
                                style={{ color: "red" }}
                            />
                        </Tooltip>
                    )}
                </Col>
                }
                
            >
                <Row gutter={[24, 12]}>
                    <Col span={24}>
                        <Row justify="space-between" wrap={false} className="content-cards_responsive">
                            <Col className="label">
                                <Typography.Paragraph type="secondary" style={{ marginBottom: "4px" }}>
                                    <span>
                                        <FaRegBuilding size={16} /> Empresa
                                    </span>
                                </Typography.Paragraph>
                            </Col>
                            <Col className="info">
                                <Typography.Paragraph style={{ marginBottom: "4px" }}>
                                    {representative.company.name}
                                </Typography.Paragraph>
                            </Col>
                        </Row>
                    </Col>

                    <Col span={24}>
                        <Row justify="space-between" wrap={false} className="content-cards_responsive">
                            <Col className="label">
                                <Typography.Paragraph type="secondary" style={{ marginBottom: "4px" }}>
                                    <span>
                                        <FaRegIdBadge size={16} /> Cargo
                                    </span>
                                </Typography.Paragraph>
                            </Col>
                            <Col className="info">
                                <Typography.Paragraph style={{ marginBottom: "4px" }}>
                                    {representative.position.name}
                                </Typography.Paragraph>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>
            <RepresentativeModalWithForm
                open={openRepresentativeModalEdit}
                formConfig={{
                    readOnly: {
                        company_uid: true,
                    },
                    valuesDefault: {
                        ...representative,
                        company_uid: {
                            label: representative.company.name,
                            value: representative.company.uid
                        },
                        position_uid: {
                            label: representative.position.name,
                            value: representative.position.uid
                        }
                    }
                }}
                onCancel={() => {
                    setOpenRepresentativeModalEdit(false);
                }}
                onSubmitFinishSuccess={() => {
                    setOpenRepresentativeModalEdit(false);
                    onLoadRepresentatives();
                }}
            />
            <RepresentativeModalInfo
                open={openRepresentativeModalInfo}
                onCancel={() => {
                    setOpenInteractionModalInfo(false)
                }}
                onClickEdit={() => { setOpenRepresentativeModalEdit(true) }}
                onClickActive={onClickActive}
                representativeUID={representative.uid}
            />
        </Col>
    );
}

export default CardRepresentative;