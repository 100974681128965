const NOT_REGISTERED = "Não cadastrado";
const COMPANY_EDITION = "Edição de Empresa";
const INSERT_NAME = "Insira o nome.";
const INSERT_EMAIL = "Insira o e-mail.";
const INSERT_LINKEDIN = "Insira o linkedin.";
const INSERT_PHONE = "Insira o telefone.";
const INSERT_CITY = "Insira a cidade.";
const INSERT_COMMENT = "Insira o comentário.";
const INSERT_TITLE = "Insira o título.";
const INSERT_NOTES = "Insira as anotações.";
const INSERT_SECTOR = "Selecione o setor.";
const INSERT_SERVICE = "Selecione o serviço.";
const INSERT_STATUS = "Selecione o status.";
const INSERT_STATUS_COLOR = "Selecione a cor.";
const SELECT_COMPANY = "Selecione a empresa.";
const SELECT_DATE = "Selecione a data e hora.";
const SELECT_CONTACT_TYPE = "Selecione o tipo de contato.";
const SELECT_CONTACT_MEAN = "Selecione o meio de contato.";
const SELECT_REPRESENTATIVE = "Selecione o representante."
const SELECT_POSITION = "Selecione o cargo."

export {
    NOT_REGISTERED,
    COMPANY_EDITION,
    INSERT_NAME,
    INSERT_EMAIL,
    INSERT_LINKEDIN,
    INSERT_PHONE,
    INSERT_NOTES,
    INSERT_CITY,
    INSERT_TITLE,
    INSERT_SECTOR,
    INSERT_COMMENT,
    INSERT_SERVICE,
    INSERT_STATUS,
    INSERT_STATUS_COLOR,
    SELECT_COMPANY,
    SELECT_DATE,
    SELECT_CONTACT_TYPE,
    SELECT_CONTACT_MEAN,
    SELECT_REPRESENTATIVE,
    SELECT_POSITION
};