import { Form, Input, notification } from "antd";

import _service from "@netuno/service-client";

import { INSERT_COMMENT } from "../../../../consts";

function InteractionCommentForm({
    form,
    commentUID,
    onSubmitFinishSuccess,
    onSubmitFinishFail,
    ...formConfig
}) {
    const onFinish = (values) => {
        const data = {
            ...values,
        };

        if (!commentUID) {
            _service({
                url: "interaction/comment",
                method: "POST",
                data,
                success: () => {
                    notification.success({
                        message: "Cadastro de Comentário",
                        description: "Comentário cadastrado com sucesso."
                    });
                    onSubmitFinishSuccess();
                },
                fail: () => {
                    notification.error({
                        message: "Cadastro de Comentário",
                        description: "Falaha ao tentar cadastrar um novo comentário."
                    });
                    onSubmitFinishFail();
                }
            });
        }

        if (commentUID) {
            data.uid = commentUID;

            _service({
                url: "interaction/comment",
                method: "PUT",
                data,
                success: () => {
                    notification.success({
                        message: "Edição de Comentário",
                        description: "Comentário editado com sucesso."
                    });
                    onSubmitFinishSuccess();
                },
                fail: () => {
                    notification.error({
                        message: "Edição de Comentário",
                        description: "Falaha ao tentar editar o comentário."
                    });
                    onSubmitFinishFail();
                }
            });
        }
    };

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={formConfig?.valuesDefault}
        >
            <Form.Item
                hidden
                name="interaction_uid"
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Comentário"
                name="message"
                rules={[
                    {
                        required: true,
                        message: INSERT_COMMENT
                    }
                ]}
            >
                <Input.TextArea rows={5} />
            </Form.Item>
        </Form>
    );
}

export default InteractionCommentForm;