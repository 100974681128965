import CandidateModal from "..";
import {
    Col,
    Row,
    Tabs,
    Switch,
    Tooltip,
    Button,
    notification
} from "antd"
import { EditOutlined, FileOutlined } from "@ant-design/icons";
import { useState } from "react";
import _service from "@netuno/service-client";
import { connect } from "react-redux";

import CandidateModalTabGeneral from "./Tab/General";
import CandidateModalTabFiles from "./Tab/Files";

function CandidateModalWithInfo({
    open,
    onCancel,
    loggedUserInfo,
    onEdit,
    onClickActived,
    candidateUID
}) {

    const [candidate, setCandidate] = useState({});
    const [loading, setLoading] = useState(false);

    const onActiveCandidate = () => {
        onClickActived(candidate.uid, !candidate.active);
        setLoading(true);
        _service({
            url: "candidate/active",
            method: "PUT",
            data: {
                uid: candidate.uid,
                active: !candidate.active
            },
            success: (res) => {
                setLoading(false);
                notification.success({
                    message: `${candidate.active ? "Desativar" : "Ativar"} Candidato`,
                    description: `Candidato ${candidate.active ? "Desativado" : "Ativado"} com sucesso!`
                });
                onClickActived(candidate.uid, !candidate.active);
                setCandidate((prev) => ({
                    ...prev,
                    active: !candidate.active
                }))
            },
            fail: (err) => {
                setLoading(false);
                notification.error({
                    message: `${candidate.active ? "Desativar" : "Ativar"} Candidato`,
                    description: `Falha ao ${candidate.active ? "Desativar" : "Ativar"} Candidato!`
                });
            }
        })
    }

    return (

        <CandidateModal
            open={open}
            footer={false}
            candidateUID={candidateUID}
            onLoadCandidate={(candidate) => {
                setCandidate(candidate);
            }}
            onCancel={() => {
                onCancel();
            }}
            width="90%"
            style={{
                maxWidth: "900px",
            }}
            bodyStyle={{
                height: "72vh"
            }}
            title={candidate && (
                <Row
                    align="middle"
                    justify="space-between"
                    wrap={false}
                >
                    <Col>
                        {candidate.name}
                    </Col>
                    <Col className="btns_modal">
                        <Row
                            wrap={false}
                            align="middle" gutter={[12, 0]}
                        >
                            {loggedUserInfo?.admin ? (
                                <Col style={{ marginBottom: "2px" }}>
                                    <Tooltip
                                        title={`${candidate.active ? "Desativar" : "Ativar"} candidato`}
                                    >
                                        <Switch
                                            size="small"
                                            loading={loading}
                                            checked={candidate.active}
                                            onClick={onActiveCandidate}
                                        />
                                    </Tooltip>
                                </Col>
                            ) : (<></>)}
                            <Col>
                                <Button
                                    type="link"
                                    disabled={!candidate.active}
                                    icon={<EditOutlined />}
                                    title="Editar"
                                    onClick={onEdit}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )}
        >

            <Tabs
                tabBarStyle={{
                    position: "sticky",
                    top: 0,
                    zIndex: 1,
                    background: "#FFF"
                }}
                items={[
                    {
                        key: "1",
                        label: "Informações Gerais",
                        children: <CandidateModalTabGeneral candidate={candidate} />
                    },
                    {
                        key: "2",
                        label: (
                            <span>
                                <FileOutlined size={20} /> Arquivos
                            </span>),
                        children: <CandidateModalTabFiles candidate={candidate} />
                    }
                ]}
            />

        </CandidateModal>
    )
}


const mapStateToProps = store => {
    const { loggedUserInfo } = store.loggedUserInfoState;

    return {
        loggedUserInfo
    };
};


export default connect(mapStateToProps, {})(CandidateModalWithInfo);;