import { Select } from "antd"
import { useEffect, useState } from "react"
import _service from "@netuno/service-client";

let timerSearch = null;

function ExperienceTimeSelectOne ({
    disabled,
    ...selectOptions
}) {
    
    const [loading, setLoading] = useState(false);
    const [experienceBases, setExperienceBases] = useState([]);
    const [filters, setFilters] = useState({});

    const onLoadExprienceTimeBases = () => {
        setLoading(true);
        _service({
            url:"candidate/experience_time/list",
            method:"POST",
            data:{
                filters:{
                    ...filters,
                    active: true
                }
            },
            success: (res) =>{
                setLoading(false);
                if (res.json) {
                    setExperienceBases(res.json.experiences_time);
                }
            },
            fail: () => {
                setLoading(false);
            }   
        })
    }

    useEffect(()=>{
        if (!disabled) {
            onLoadExprienceTimeBases();
        } 
    },[filters]);

    return (
      
      <Select
        showSearch
        options={experienceBases.map((experienceBase)=>({
            label:experienceBase.name,
            value:experienceBase.uid
        }))}
        loading={loading}
        allowClear={!loading}
        disabled={disabled}
        onSearch={(search) => {
            if (timerSearch) {
                clearTimeout(timerSearch);
            }

            timerSearch = setTimeout(() => {
                setFilters((prev) => ({
                    ...prev,
                    name: search
                }));
            }, 500);
        }}
        onDropdownVisibleChange={(isOpen) => {
            if (!isOpen && filters.name) {
                setFilters(() => ({
                    name: ""
                }));
            }
        }}
        {...selectOptions}
      />
    )
}

export default ExperienceTimeSelectOne;