import {
    useState
} from "react";

import {
    Button,
    Col,
    Form,
    Row
} from "antd";

import ModalCentered from "../../../Modal/Centered";
import InteractionForm from "../../Form";

function InteractionModalWithForm({
    open,
    formConfig,
    onCancel,
    onSubmitFinishSuccess
}) {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const onClickSave = async () => {
        try {
            await form.validateFields();
            form.submit();
            setLoading(true);
        } catch (_err) {
            setLoading(false);
        }
    };

    return (
        <ModalCentered
            open={open}
            title="Cadastro de Interação"
            onCancel={() => {
                onCancel();

                form.resetFields();
            }}
            width="90%"
            style={{
                maxWidth: "600px"
            }}
            footer={(
                <Row justify="end">
                    <Col>
                        <Button
                            type="link"
                            onClick={() => {
                                onCancel();

                                form.resetFields();
                            }}
                        >
                            Cancelar
                        </Button>
                    </Col>

                    <Col>
                        <Button
                            type="primary"
                            loading={loading}
                            onClick={onClickSave}
                        >
                            Salvar
                        </Button>
                    </Col>
                </Row>
            )}
        >
            <InteractionForm
                form={form}
                onSubmitFinishSuccess={() => {
                    setLoading(false);
                    onSubmitFinishSuccess();
                }}
                onSubmitFinishFail={() => {
                    setLoading(false);
                }}
                {...formConfig}
            />
        </ModalCentered>
    );
}

export default InteractionModalWithForm;
